/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import { Enum } from '@pageproof/sdk';
import { InlineSVG } from '../InlineSVG';
import Popover from '../Popover';
import { Option, OptionList, Separator } from '../PopupMenu';
import Translation from '../Text/Translation';
import IconButton from '../Button/IconButton';
import HorizontalDelimiter from '../HorizontalDelimiter';
import CheckForOverflow from '../CheckForOverflow';
import DownloadIcon from '../DownloadIcon';
import Tooltip from '../Tooltip';
import useIsMobile from '../../hooks/useIsMobile';
import { useLocalStorage } from '../LocalStorage';

import css from './CommentListOptions.scss';

const getLatestReplyDate = replies => replies.length > 0 && Math.max(...replies.map(reply => reply.createdAt));

const ORDERS = {
  commentedBy: {
    orderKey: 'commentedBy',
    orderLabel: 'commented-by',
    orderFlag: 'ownerEmail',
  },
  commentNumber: {
    orderKey: 'commentNumber',
    orderLabel: 'comment-number',
    orderFlag: 'number',
  },
  date: {
    orderKey: 'date',
    orderLabel: 'date',
    orderFlag: '-createdAt',
  },
  latestReply: {
    orderKey: 'latestReply',
    orderLabel: 'latest-reply',
    orderFlag({ replies }) {
      const latestReplyDate = getLatestReplyDate(replies);
      return -latestReplyDate;
    },
    reversedOrderFlag({ replies }) {
      const latestReplyDate = getLatestReplyDate(replies);
      return latestReplyDate && -latestReplyDate;
    },
  },
  position: {
    orderKey: 'position',
    orderLabel: 'position',
    orderFlag(comment) {
      // eslint-disable-next-line
      const topPinY = Math.min(...comment.pins.map((pin) => {
        return pin.y2 ? Math.min(pin.y, pin.y2) : pin.y;
      }));
      return topPinY;
    },
    onlyShowIn: [Enum.FileCategory.STATIC],
  },
  timecode: {
    orderKey: 'timecode',
    orderLabel: 'timecode',
    orderFlag: ({ pins }) => (pins.length === 0 ? -1 : pins[0].time),
    onlyVideoOrAudio: true,
  },
};

function CommentListOptions({
  commentOrder,
  isFiltered,
  onClearFilter,
  isVideoOrAudio,
  proofFileCategory,
  onChangeCommentOrder,
  delimiterSize = 20,
  isCompareMode,
  onDownloadComments,
}) {
  const [showCommentPageDivider, setShowPageCommentPaneDivider] = useLocalStorage('pageproof.app.showCommentPageDivider', 'true');
  const isShowCommentPageDivider = showCommentPageDivider === 'true';
  const isMobile = useIsMobile();
  const delimiter = <div style={{ minWidth: delimiterSize * 0.7 }} />;

  /* get any existing ordering from URL search params */
  const { isReversedCommentOrder, searchParam, orderCommentsBy } = commentOrder;
  const defaultOrderKey = isVideoOrAudio ? 'timecode' : 'date';

  let orderKey;
  let isCustomOrdered;

  if (searchParam && (searchParam !== defaultOrderKey)) {
    orderKey = searchParam; // searchParam is the same as ORDERS[searchParam].orderKey
    isCustomOrdered = true;
  } else {
    orderKey = defaultOrderKey;
    isCustomOrdered = isReversedCommentOrder;
  }

  // Prevent adding the url search params on default ordering (ie, don't change the url on page load)
  const getSearchParam = (updatedKey, isReversed) => {
    if (isReversed) {
      return updatedKey;
    }
    if (updatedKey === defaultOrderKey) {
      return '';
    }

    return updatedKey;
  };

  // Sorting logic needs to be provided to BaseProofController
  useEffect(() => {
    onChangeCommentOrder({
      searchParam: getSearchParam(orderKey, isReversedCommentOrder),
      orderCommentsBy: ORDERS[orderKey].orderFlag,
      isReversedCommentOrder,
    });
  }, []);

  const setOrderKey = (updatedKey) => {
    onChangeCommentOrder({
      searchParam: getSearchParam(updatedKey, isReversedCommentOrder),
      orderCommentsBy: ORDERS[updatedKey].orderFlag,
      isReversedCommentOrder,
    });
  };

  const toggleReverseOrder = () => {
    onChangeCommentOrder({
      searchParam: getSearchParam(orderKey, !isReversedCommentOrder),
      orderCommentsBy,
      isReversedCommentOrder: !isReversedCommentOrder,
    });
  };

  return (
    <CheckForOverflow>
      {wouldOverflow => (
        <HorizontalDelimiter
          delimiter={delimiter}
        >
          {!isCompareMode && !wouldOverflow && !isMobile &&
            <Tooltip
              title={<Translation value="comments-header.download-comments.tooltip" />}
              down
              center
              offset={15}
            >
              <DownloadIcon
                active
                activeColor="#aaa"
                activeHoverColor="#6a6a6a"
                size={20}
                onClick={onDownloadComments}
              />
            </Tooltip>
          }
          <Popover
            content={
              <div>
                <OptionList wrap={false}>
                  <Option
                    label={<Translation value="dashboard.order-by" />}
                    disabled
                  />
                  { proofFileCategory === Enum.FileCategory.STATIC && !isCompareMode && (
                    <Fragment>
                      <Separator />
                      <Option
                        label={<Translation value="comment.orders.per-page" />}
                        checked={isShowCommentPageDivider}
                        onClick={() => setShowPageCommentPaneDivider(value => (value === 'true' ? 'false' : 'true'))}
                      />
                    </Fragment>
                  )}
                  <Separator />
                  {
                    Object.keys(ORDERS).map(key => ORDERS[key])
                      .filter(order => isVideoOrAudio || !order.onlyVideoOrAudio)
                      .filter(order => (order.onlyShowIn ? order.onlyShowIn.includes(proofFileCategory) : true))
                      .map(option => (
                        <Option
                          key={option.orderKey}
                          label={<Translation value={`comment.orders.${option.orderLabel}`} />}
                          checked={option.orderKey === orderKey}
                          onClick={() => {
                            setOrderKey(option.orderKey);
                            if (option.orderKey === 'position') {
                              setShowPageCommentPaneDivider(() => 'true');
                            }
                          }}
                        />
                      ))
                  }
                  <Separator />
                  <Option
                    label={<Translation value="dashboard.reverse-order" />}
                    checked={isReversedCommentOrder}
                    onClick={toggleReverseOrder}
                  />
                  {!isCompareMode && wouldOverflow && (
                    <Fragment>
                      <Separator />
                      <Option
                        label={<Translation value="comments-header.download-comments" />}
                        onClick={onDownloadComments}
                      />
                    </Fragment>
                  )}
                  {isFiltered && (
                    <Fragment>
                      <Separator />
                      <Option
                        label={<Translation value="comments-header.filter.clear-filter" />}
                        onClick={onClearFilter}
                      />
                    </Fragment>
                  )}
                </OptionList>
              </div>
            }
            down
            center
            arrow
            offset={15}
          >
            {popover => (
              wouldOverflow
                ? (
                  <IconButton
                    src="/img/icons/options.svg"
                    className={css.CommentListOptions__optionsButton}
                  />
                ) : (
                  <div
                    className={classname(css.CommentListOptions, {
                      [css['CommentListOptions--active']]: isFiltered,
                    })}
                  >
                    <Tooltip
                      title={<Translation value="comments-header.filter.tooltip" />}
                      down
                      center
                      offset={15}
                      disabled={popover.isVisible}
                    >
                      {tooltip => (
                        <div
                          className={css.CommentListOptions__button}
                          onClick={() => tooltip.hide()}
                        >
                          <div className={isFiltered ? css['CommentListOptions__button--active'] : css['CommentListOptions__button--sort-filter']}>
                            <InlineSVG
                              src="img/interface/sort-filter.svg"
                              width="13"
                              height="15"
                            />
                          </div>
                          <div className={isCustomOrdered ? css['CommentListOptions__button--active'] : null}>
                            <InlineSVG
                              src="img/interface/sort-arrow.svg"
                              width="7"
                              height="18"
                            />
                          </div>
                        </div>
                      )}
                    </Tooltip>
                  </div>
                )
            )}
          </Popover>
        </HorizontalDelimiter>
      )}
    </CheckForOverflow>
  );
}

if (process.env.NODE_ENV === 'development') {
  CommentListOptions.propTypes = {
    commentOrder: PropTypes.objectOf(PropTypes.oneOf(PropTypes.string, PropTypes.function)),
    isFiltered: PropTypes.bool,
    onClearFilter: PropTypes.func,
    isVideoOrAudio: PropTypes.bool,
    proofFileCategory: PropTypes.oneOf(Object.values(Enum.FileCategory)),
    onChangeCommentOrder: PropTypes.func,
    delimiterSize: PropTypes.number,
    isCompareMode: PropTypes.bool,
    onDownloadComments: PropTypes.func,
  };
}

export default CommentListOptions;
