import classname from 'classname';
import React, { Fragment, useState } from 'react';
import { InlineSVG } from '../InlineSVG';
import css from './BookmarkButton.scss';
import Tooltip from '../Tooltip/Tooltip';
import { Translation } from '../Text';
import { PopupMenu, Option } from '../PopupMenu';


const BookmarkButton = ({ getUrl, isProofBookmarked, pageNumber, removeBookmarkUrl, goToPage }) => {
  const [isPopupMenuOpened, setIsPopupMenuOpened] = useState(false);

  return isProofBookmarked ? (
    <PopupMenu
      up
      onShow={() => setIsPopupMenuOpened(true)}
      onHide={() => setIsPopupMenuOpened(false)}
      options={() => (
        <Fragment>
          <Option
            label={<Translation
              value="proof.utilities.bookmark.tooltip"
            />}
            disabled
          />
          <Option
            label={
              <div className={css.labelIcon}>
                <Translation value="proof.utilities.bookmark.go-to-bookmark" />
                <InlineSVG
                  src="img/icons/material/bookmark_add.svg"
                  className={css.labelIcon__activeIcon}
                />
              </div>
            }
            onClick={() => goToPage()}
          />
          <Option
            label={
              <div className={css.labelIcon}>
                <Translation value="proof.utilities.bookmark.unset" />
                <InlineSVG
                  src="img/icons/material/bookmark_add.svg"
                  className={css.labelIcon__unsetIcon}
                />
              </div>
            }
            onClick={() => removeBookmarkUrl()}
          />
        </Fragment>
      )}
      offset={10}
    >
      <Bookmark
        showTooltip={!isPopupMenuOpened}
        isProofBookmarked={isProofBookmarked}
        onClick={getUrl}
        pageNumber={pageNumber}
      />
    </PopupMenu>
  ) : (
    <Bookmark
      showTooltip
      isProofBookmarked={isProofBookmarked}
      onClick={getUrl}
      pageNumber={pageNumber}
    />
  );
};

const Bookmark = ({ onClick, isProofBookmarked, pageNumber }) => (
  <div className={classname(css.customButtonContainer, {
    [css.customButtonContainer__active]: isProofBookmarked,
  })}
  >
    <Tooltip
      up
      center
      title={<Translation
        value="proof.utilities.bookmark.tooltip"
        params={{ pageNumber }}
      />}
      maxWidth={150}
      disabled={isProofBookmarked}
    >
      <button
        className={classname('page__proof__toolbar__button', css.customButton, {
          [css.Icon_active]: isProofBookmarked,
        })}
        onClick={onClick}
        aria-label="Bookmark"
      >
        <InlineSVG src="img/icons/material/bookmark_add.svg" />
        <div className={css.pageNumber}>{pageNumber}</div>
      </button>
    </Tooltip>
  </div>
);

export default BookmarkButton;

export {
  Bookmark,
};
