/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import classname from 'classname';
import { InlineSVG } from '../../InlineSVG';
import css from './Label.scss';

function Label({
  type,
}) {
  return (
    <div
      className={classname(css.Label, css['Label--' + type])}
    >
      <InlineSVG
        src="img/icons/comment-label.svg"
      />
    </div>
  );
}

export default Label;
