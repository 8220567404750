import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ShyTextPill from '../ShyTextPill';
import css from './CombinedProofInfo.scss'; // Adjust the path as necessary

// To-do: Compare-mode / enable pill only for combined proofs
function CombinedProofInfo({
  proof,
  isFooterMouseover,
  currentPage,
  compareMode,
}) {
  const {
    pagesMetadata,
  } = proof;

  const currentFileName = pagesMetadata[currentPage - 1].fileName;

  return (
    <ShyTextPill
      delay={6000}
      className={classnames(css.CombinedProofInfo__pill, {
        [css.CombinedProofInfo__compare]: !!compareMode,
      })}
      show={isFooterMouseover}
    >
      <React.Fragment>
        { currentFileName }
      </React.Fragment>
    </ShyTextPill>
  );
}

if (process.env.NODE_ENV !== 'production') {
  CombinedProofInfo.propTypes = {
    proof: PropTypes.shape({
      pagesMetadata: PropTypes.array,
    }),
    currentPage: PropTypes.number,
    isFooterMouseover: PropTypes.bool,
    compareMode: PropTypes.bool,
  };
}

export default CombinedProofInfo;
