import { gql } from './tag';
import { sdk } from '../sdk';

export const getSharedCollectionsByUserIdQuery = gql`
  query ppxapp_getSharedCollectionByUserId($userId: ID!) {
    sharedCollections(userId: $userId) {
      id
    }
  }
`;

export const loadSharedCollections = userId => sdk.graphql(getSharedCollectionsByUserIdQuery.toString(), { userId }, { throwOnError: false })
  .then(result => result.data.sharedCollections);
