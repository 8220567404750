/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import { InlineSVG } from '../InlineSVG';
import Translation from '../Text/Translation';
import MarkDone from '../Comment/Icon/MarkDone';
import css from './ModalMessage.scss';

const MarkCommentsUploadProofModalMessage = ({ isBrief }) => (
  <Fragment>
    <h1>
      <Translation
        value={isBrief ? 'proof.dialog.proof-required-for-brief.title' : 'proof.dialog.brief-sent-to-editor.title'}
      />
    </h1>
    <p className={classname(css.ModalMessage__message)}>
      <Translation
        value="proof.dialog.mark-todo-upload-proof.message"
        params={{
          doneIcon: <MarkDone active readOnly />,
          uploadIcon: (
            <div className={css.ModalMessage__uploadIcon}>
              <InlineSVG
                aria-label="File dropper icon"
                src="/img/icons/file-dropper.svg"
              />
            </div>
          ),
        }}
      />
    </p>
  </Fragment>
);

MarkCommentsUploadProofModalMessage.defaultProps = {
  isBrief: false,
};

if (process.env.NODE_ENV !== 'production') {
  MarkCommentsUploadProofModalMessage.propTypes = {
    isBrief: PropTypes.bool,
  };
}

export default MarkCommentsUploadProofModalMessage;
