/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import { InlineSVG } from '../InlineSVG';
import Translation from '../Text/Translation';
import useDebounce from '../../hooks/useDebounce';
import RevealTextHorizontal from '../ProofSetup/components/RevealTextHorizontal';
import css from './RevealingPlus';

const RevealingPlus = ({
  onClick,
  textToReveal,
  theme,
}) => {
  const [isHovered, _setIsHovered] = useState(false);
  const setIsHovered = useDebounce(_setIsHovered, 50);

  return (
    <div className={classname(css.RevealingPlus, css['RevealingPlus--' + theme])}>
      <button
        type="button"
        onClick={() => {
          onClick();
          setIsHovered(hovered => !hovered);
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onFocus={() => setIsHovered(true)}
        onBlur={() => setIsHovered(false)}
        className={classname(css.RevealingPlus__button, {
          [css['RevealingPlus__button--active']]: isHovered,
        })}
      >
        <InlineSVG
          src="/img/icons/plus-3.svg"
          className={css.RevealingPlus__button__plus}
        />
        <RevealTextHorizontal
          show={isHovered}
          duration={200}
        >
          <div className={css.RevealingPlus__button__text}>
            <Translation value={textToReveal} />
          </div>
        </RevealTextHorizontal>
      </button>
    </div>
  );
};

RevealingPlus.defaultProps = {
  theme: 'grey',
};

if (process.env.NODE_ENV !== 'production') {
  RevealingPlus.propTypes = {
    textToReveal: PropTypes.string,
    onClick: PropTypes.func,
    theme: PropTypes.string,
  };
}

export default RevealingPlus;
