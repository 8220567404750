/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import { InlineSVG } from '../InlineSVG';
import Popover from '../Popover';
import Tooltip from '../Tooltip';
import ColorDot from '../ColorDot';
import { Separator } from '../PopupMenu';
import css from './SmartCompareDiffSettings.scss';
import { RangeInput } from '../RangeInput';
import UnstyledButton from '../Button/UnstyledButton';

const COLORS = [
  '#138b3b',
  '#e51c23',
  '#fe8125',
  '#ffeb3b',
  '#000000',
  '#ffffff',
  'rainbow',
];

export function SmartCompareDiffSettings({ settings, onChange }) {
  return (
    <Popover
      up
      center
      arrow
      offset={8}
      variant="light"
      content={
        <div>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: 184 }}>
            {COLORS.map(color => (
              <ColorDot.Dot
                key={color}
                color={color}
                selected={settings.diffColor === color}
                selectedRing
                onClick={() => onChange({ ...settings, diffColor: color })}
              />
            ))}
            <div style={{ position: 'relative' }}>
              <span className={css.SmartCompareDiffSettings__colorSeparator} />
              {(!settings.customDiffColor || settings.diffColor === settings.customDiffColor) && (
                <React.Fragment>
                  <InlineSVG
                    src="img/icons/solid-red-pen.svg"
                    className={css.SmartCompareDiffSettings__editColorIcon}
                  />
                  <input
                    type="color"
                    value={settings.customDiffColor}
                    onClick={event => event.stopPropagation()}
                    onChange={event => onChange({ ...settings, diffColor: event.target.value, customDiffColor: event.target.value })}
                    style={{
                      position: 'absolute',
                      inset: 0,
                      height: '100%',
                      opacity: 0,
                      cursor: 'pointer',
                    }}
                  />
                </React.Fragment>
              )}
              <ColorDot.Dot
                selected={settings.diffColor === settings.customDiffColor}
                color={settings.customDiffColor || '#757575'}
                selectedRing
                onClick={() => onChange({ ...settings, diffColor: settings.customDiffColor })}
              />
            </div>
          </div>
          <Separator variant="light" />
          <div style={{ padding: '10px', marginBottom: 10 }}>
            <label>Fog</label>
            <div style={{ marginTop: 8, marginInline: '-9px' }}>
              <RangeInput
                min={0}
                max={100}
                value={settings.ghostOpacity * 100}
                onChange={value => onChange({ ...settings, ghostOpacity: Math.round(value) / 100 })}
                tooltip={value => (value ? `${Math.round(value)}%` : 'Off')}
              />
            </div>
            <br />
            <label>Strobe</label>
            <div style={{ marginTop: 8, marginInline: '-9px' }}>
              <RangeInput
                min={0}
                max={5}
                step={1}
                value={settings.strobeSpeed}
                onChange={value => onChange({ ...settings, strobeSpeed: value })}
                tooltip={value => ['Off', 'Gentle', 'Slow', 'Fast', 'Rapid', 'Extreme'][value]}
              />
            </div>
            <br />
            <label>Jiggle</label>
            <div style={{ marginTop: 8, marginInline: '-9px' }}>
              <RangeInput
                min={0}
                max={10}
                step={2}
                value={settings.jiggleDistance}
                onChange={value => onChange({ ...settings, jiggleDistance: value })}
                tooltip={value => ['Off', 'Minimal', 'Subtle', 'Moderate', 'High', 'Extreme'][value / 2]}
              />
            </div>
          </div>
        </div>
      }
    >
      {popover => (
        <div style={{ marginLeft: 22 }}>
          <Tooltip
            up
            center
            title="Advanced settings"
            disabled={popover.isVisible}
            offset={-6}
          >
            <UnstyledButton
              className={css.SmartCompareDiffSettings__settingsButton}
              ariaLabel="Advanced settings"
            >
              <InlineSVG
                src="img/interface/settings-no-padding.svg"
                className={css.SmartCompareDiffSettings__settingsButton__icon}
              />
            </UnstyledButton>
          </Tooltip>
        </div>
      )}
    </Popover>
  );
}
