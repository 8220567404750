/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import Media from 'react-media';
import { InlineSVG } from '../../components/InlineSVG';
import css from './ResumeProofSetupButton.scss';
import { useLocalStorage } from '../../components/LocalStorage';
import Button from '../../components/Button/Button';
import HeaderPortal from '../../components/HeaderPortal';
import URLSubscription from '../../components/URLSubscription';
import { Translation } from '../../components/Text/index';
import { proofSetupKey } from '../../components/ProofSetup/utils/proofSetup';
import { goToUrl } from '../../util/location';
import DiscardButtonOnHover from '../../components/DiscardButtonOnHover';

const ResumeProofSetupButton = () => {
  const [state, , reset] = useLocalStorage(proofSetupKey);
  return (
    <URLSubscription>
      {({ path }) => (
        (path !== '/create' && !!state) && (
          <Media query="(max-width: 750px)">
            {matches => (
              <HeaderPortal position="left-middle">
                <DiscardButtonOnHover onDiscard={reset}>
                  <Button
                    href="/create"
                    component="a"
                    autoWidth
                    variant={null}
                    className={css.ResumeProofSetupButtons__resumeDraft}
                    label={matches
                      ? <InlineSVG src="img/content/proof/icons/metadata/more.svg" />
                      : <Translation value="dashboard.resume-draft" />
                    }
                    onClick={(event) => {
                      goToUrl('/create', event);
                    }}
                  />
                </DiscardButtonOnHover>
              </HeaderPortal>
            )}
          </Media>
        )
      )}
    </URLSubscription>
  );
};

export default ResumeProofSetupButton;
