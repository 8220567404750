/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import classname from 'classname';
import PropTypes from 'prop-types';
import { InlineSVG } from '../InlineSVG';
import css from './CollectionMenu.scss';
import { Translation } from '../Text';
import Folder from '../Folder';
import useFolderList from '../../hooks/useFolderList';
import CollectionOption from './CollectionOption';

const CollectionMenu = ({
  menu,
  from,
  onRemove,
  onManage,
  selectedCollection,
  onDrop,
  color,
  collectionSearchText,
  onSelect,
}) => {
  const collectionsAndFoldersArr = useFolderList(menu.tabs);
  const collectionOptionProps = {
    selectedCollection,
    onSelect,
    onDrop,
    onRemove,
    onManage,
    from,
    color,
  };

  return (
    <div className={css.CollectionMenu}>
      <span
        className={classname(css.CollectionMenu__header, {
          [css['CollectionMenu__header--active']]: menu.active,
          [css[`CollectionMenu__header--${color}`]]: color,
        })}
        onClick={menu.onClick}
      >
        <Translation value={`collection.tab.${menu.id}`} />
        <InlineSVG
          src="img/interface/arrow-down.svg"
          className={css.CollectionMenu__header__arrow}
        />
      </span>
      {menu.tabs && !menu.hide && (
        <div
          className={classname(css.CollectionMenu__tabs, {
            [css['CollectionMenu__tabs--hidden']]: menu.hide,
          })}
        >
          {collectionsAndFoldersArr
            .filter(folder => folder.name.toLowerCase().includes(collectionSearchText.toLowerCase()))
            .map(folder => (
              <div
                className={css.CollectionMenu__tabs__tab}
                key={folder.name}
              >
                {folder.collections
                  ? (
                    <Folder
                      color={color}
                      title={folder.name}
                    >
                      {folder.collections.map(collection => (
                        <div key={collection.id}>
                          <CollectionOption
                            collection={collection}
                            {...collectionOptionProps}
                          />
                        </div>
                      ))}
                    </Folder>
                  )
                  : (
                    <CollectionOption
                      collection={folder.collection}
                      {...collectionOptionProps}
                    />
                  )
                }
              </div>
            ))}
          {menu.onceLoaded && menu.tabs && !menu.tabs.length && (
            <div className={css.CollectionMenu__tip}>
              <Translation value="collection.tab.tip.no-collection" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

if (process.env.NODE_ENV !== 'production') {
  CollectionMenu.propTypes = {
    menu: PropTypes.shape({
      active: PropTypes.bool.isRequired,
      hide: PropTypes.bool.isRequired,
      id: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      onceLoaded: PropTypes.bool,
      tabs: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    selectedCollection: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    collectionSearchText: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    onManage: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    from: PropTypes.string,
    color: PropTypes.string.isRequired,
    onDrop: PropTypes.func.isRequired,
  };
}

export default CollectionMenu;
