/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React from 'react';
import classname from 'classname';
import { InlineSVG } from '../InlineSVG';
import css from './CRIcon.scss';


function CRIcon() {
  const className = classname(
    css.CRIcon_icon,
  );

  return (

    <div className={className}>
      <InlineSVG
        src="img/icons/content-credentials-white.svg"
      />
    </div>
  );
}

export default CRIcon;
