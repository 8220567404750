import React, { useRef } from 'react';
import classnames from 'classnames';
import css from './ResizeHandle.scss';
import { useLocalStorage } from '../../components/LocalStorage';

const MIN_WIDTH = 350;
const DEFAULT_WIDTH = 500;
const PRESET_WIDTHS = [500, 420];
const SNAP_TO_WIDTH_THRESHOLD = 15;

/**
 * Accessibility notes:
 * Make tabbable and focusable
 * Register keyboard events (shift + left & shift + right) to add/remove ~50px per event
 * Or have a key to toggle between the widths
 * Plus needs a way to open and close the pane
 */

export default function ResizeHandle(props) {
  const { width, setWidth, isDarkMode, whenResized } = props;
  const [, saveWidth] = useLocalStorage('pageproof.app.commentPaneWidth', DEFAULT_WIDTH);

  const widthRef = useRef(width);

  function updateWidth(newWidth) {
    widthRef.current = newWidth;
    setWidth(newWidth);
  }

  function mouseDownHandler(event) {
    event.preventDefault();
    window.addEventListener('mousemove', mousemoveHandler);
    window.addEventListener('mouseup', mouseupHandler);
  }

  function mouseupHandler() {
    window.removeEventListener('mousemove', mousemoveHandler);
    window.removeEventListener('mouseup', mouseupHandler);
    saveWidth(widthRef.current);

    if (whenResized) {
      whenResized(widthRef.current);
    }
  }

  function mousemoveHandler(event) {
    let newWidth = Math.max(MIN_WIDTH, window.innerWidth - event.x);
    const closestStep = getClosestStep(newWidth);
    if (closestStep && Math.abs(closestStep - newWidth) < SNAP_TO_WIDTH_THRESHOLD) {
      newWidth = closestStep;
    }

    updateWidth(newWidth);
  }

  function doubleClickHandler() {
    let nextWidth = getNextStep(widthRef.current);

    if (!nextWidth) {
      nextWidth = getClosestStep(widthRef.current);
    }
    updateWidth(nextWidth);
    saveWidth(nextWidth);

    if (whenResized) {
      whenResized(nextWidth);
    }
  }

  return (
    <div
      onDoubleClick={() => doubleClickHandler()}
      role="separator"
      aria-orientation="vertical"
      aria-label="Resize comments pane"
      onMouseDown={mouseDownHandler}
      className={classnames(css.ResizeHandle, {
        [css['ResizeHandle--dark-mode']]: isDarkMode,
      })}
    />
  );
}

function getClosestStep(width) {
  let shortestDistance = Infinity;
  let step;

  PRESET_WIDTHS.forEach((stepWidth) => {
    const distance = Math.abs(stepWidth - width);
    if (distance < shortestDistance) {
      shortestDistance = distance;
      step = stepWidth;
    }
  });

  return step;
}

function getNextStep(width) {
  const currentIndex = PRESET_WIDTHS.indexOf(width);
  if (currentIndex !== -1) {
    return PRESET_WIDTHS[(currentIndex + 1) % PRESET_WIDTHS.length];
  }
  return null;
}

/**
 * Testing notes:
 * Handle is hoverable when then pane is open, and displays a bold green line when hovered
 * Handle displays a light green line when hovered in dark mode
 * Handle is draggable and resizes the pane when dragged
 * Handle snaps to the closest preset width when dragged close enough (500px & 420px)
 * Handle snaps to the closest preset width when double clicked
 * Handle saves the width to local storage when resized (when mouse button is lifted)
 * Handle calls a callback when resized (can see the proof canvas offset change when the pane is resized)
 */
