/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Component, Fragment } from 'react';
import {unstable_batchedUpdates} from 'react-dom'; // eslint-disable-line
import UnifiedLogin from '../../components/UnifiedLogin';
import { sdk } from '../../util/sdk';
import { saveSession, getSavedSession, removeSavedSession } from '../../../../shared/session';
import { watch, unwatch } from '../../components/LocalStorage/watcher';
import { withTimers } from '../../hoc/Timers';

/* eslint-disable indent */

class UnifiedLoginContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      mode: props.mode || 'prompt-email',
      email: props.email || window.localStorage.getItem('pageproof.app.email') || '',
      password: '',
      logo: null,
      options: null,
      error: null,
      code: props.code,
      referrer: props.referrer,
    };

    this._cleanup = [];

    const session = getSavedSession();
    if (session) {
      if (!session.user.hasActivated) {
        this.state.mode = 'prompt-activate';
        this.state.email = session.user.email;
        this.initiateActivationWatchers();
      } else {
        this.state.mode = ':before';
        this.state.height = 250;
        this.state.loading = 'login.validating-session';
        this.validateExistingSession();
      }
    }

    if (props.mode === 'setup') {
      this.onSetupPassword();
    }

    if (props.mode === 'reset-password') {
      this.onResetPassword();
    }

    if (this.shouldRedirectToSSO() && this.state.email) {
      console.log('Redirecting to SSO...');
      this.onEmail(this.state.email);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.logo !== prevState.logo) {
      if (window.PageProof) {
        window.__pageproof_setLogo(this.state.logo, 'login');
      }
    }
    // if (process.env.NODE_ENV === 'development') {
    //   console.log(' ');
    //   console.log('cDU start');
    //   console.log('props:');
    //   console.log(window.generalfunctions_diff(prevProps, this.props));
    //   console.log('state:');
    //   console.log(window.generalfunctions_diff(prevState, this.state));
    //   console.log('cDU end');
    //   console.log(' ');
    // }
  }

  componentWillUnmount() {
    if (window.PageProof) {
      window.__pageproof_setLogo(null, 'login');
    }
    this._cleanup.forEach((callback) => {
      callback();
    });
  }

  _userDataDidChange = () => {
    const newSession = getSavedSession();
    if (newSession && newSession.user && newSession.user.hasActivated) {
      unwatch('pageproof.app.userData', this._userDataDidChange);
      this.didReceiveCredentials(newSession);
    }
  }

  initiateActivationWatchers = () => {
    watch('pageproof.app.userData', this._userDataDidChange);
    this._cleanup.push(() => {
      unwatch('pageproof.app.userData', this._userDataDidChange);
    });

    this.props.setInterval(() => {
      const {user: {id, hasActivated}} = sdk.session;
      if (!hasActivated) {
        sdk.users.byId(id).then((user) => {
          if (user.hasActivated) {
            unwatch('pageproof.app.userData', this._userDataDidChange);
            sdk.session.user.hasActivated = true;
            this.didReceiveCredentials(sdk.session);
          }
        });
      }
    }, 5000);
  }

  validateExistingSession = () => {
    sdk.sessions.check()
      .then(() => {
        this.didReceiveCredentials(sdk.session);
      })
      .catch((err) => {
        console.error(err);
        removeSavedSession();
        sdk.setSession(null);

        this.setState({
          mode: 'prompt-email',
          loading: false,
        });
      });
  }

  onEmail = (email) => {
    this.setState({
      email: email.toLowerCase(),
      loading: true,
      message: null,
    }, () => {
      window.localStorage.setItem('pageproof.app.email', this.state.email);
    });
    this.updateHistoryUrl('/login');
    sdk.accounts.sso.initiateLogin(email)
      .then(
        (response) => {
          // unstable API required to prevent the inner component from recieving multiple renders
          // due to the fact that React isn't aware of the response event.
          // see https://github.com/facebook/react/issues/10231#issuecomment-316644950
          unstable_batchedUpdates(() => {
            this.onInitiateLogin(response);
          });
        },
        (err) => {
          this.setState({
            loading: false,
            message: window.navigator.onLine
              ? 'login.message.unhandled-error'
              : 'login.message.offline',
          });
          this.handleError(err);
        }
      );
  }

  onInitiateLogin = (response) => {
    if (response.error) {
      switch (response.error) {
        case 'user-disabled': {
          this.setState({
            mode: 'account-disabled',
          });
          break;
        }
        case 'invalid-email': {
          this.setState({
            message: 'login.message.invalid-email',
          });
          break;
        }
        default: {
          this.setState({
            message: 'login.message.unhandled-error',
          });
          break;
        }
      }
    } else if (response.options) {
      this.setState({
        mode: 'multiple-options',
        options: response.options,
      });
    } else if (response.option) {
      this.onOption(response.option);
    } else {
      this.setState({
        message: 'login.message.unhandled-error',
      });
    }
    this.setState({
      logo: response.logo,
      avatar: response.avatar,
      loading: false,
    });
  }

  // Enhanced method to ensure session is properly set up
onOpenSSOPopup = (url) => {
  const windowFeatures = 'width=480,height=640,resizable=yes,scrollbars=yes';
  const popup = window.open(url, 'SSO Login', windowFeatures);
  const parentThis = this;
  let sessionCheckInterval;

  // Function to properly initialize a session
  const initializeSession = (session) => {
    // First save the session to storage
    saveSession(session);

    // Then set it in the SDK explicitly
    sdk.setSession(session);

    // Verify the session was properly set by performing a test call
    return sdk.sessions.check()
      .then(() => {
        console.log('Session successfully verified with SDK');
        // Perform the standard credential processing
        parentThis.didReceiveCredentials(session);
        return true;
      })
      .catch((err) => {
        console.error('Session verification failed after SSO:', err);

        // Try a more aggressive approach - clear and reset
        console.log('Attempting session recovery...');
        // Clear any stale data first
        removeSavedSession();
        sdk.setSession(null);

        // Re-save the session
        saveSession(session);
        sdk.setSession(session);

        // Try again
        return sdk.sessions.check()
          .then(() => {
            console.log('Session recovered successfully');
            parentThis.didReceiveCredentials(session);
            return true;
          })
          .catch((recoverErr) => {
            console.error('Session recovery failed:', recoverErr);
            return false;
          });
      });
  };

  function sessionObtainedListener(event) {
    if (!event || !event.data || typeof event.data !== 'string') {
      return;
    }

    try {
      const eventData = JSON.parse(event.data);
      if (eventData.type && eventData.type === 'pageproof.login.session-obtained') {
        popup.close();
        window.parent.postMessage(event.data, '*');

        // Try to extract session from event data
        let session;
        if (eventData.data) {
          try {
            const parsedData = JSON.parse(eventData.data);
            session = parsedData.session;
          } catch (parseErr) {
            console.error('Error parsing session data:', parseErr);
          }
        }

        // If we got a valid session, use it
        if (session) {
          initializeSession(session)
            .then((success) => {
              if (!success) {
                console.log('Using fallback session recovery method');
                // If we couldn't initialize with the received session,
                // try checking for one in storage or via SDK
                const savedSession = getSavedSession();
                if (savedSession) {
                  initializeSession(savedSession);
                }
              }
            });
        } else {
          // Start polling for session changes
          console.log('No session in message, starting session check polling...');
          clearInterval(sessionCheckInterval);
          let attempts = 0;
          const maxAttempts = 15; // Increase max attempts

          sessionCheckInterval = setInterval(() => {
            attempts += 1;
            // Check if session exists in storage
            const savedSession = getSavedSession();
            if (savedSession) {
              clearInterval(sessionCheckInterval);
              console.log('Found session in storage');
              initializeSession(savedSession);
            } else {
              // Try to validate session via SDK
              sdk.sessions.check()
                .then(() => {
                  clearInterval(sessionCheckInterval);
                  console.log('Session validated via SDK');
                  initializeSession(sdk.session);
                })
                .catch((err) => {
                  console.log(`Session check attempt ${attempts}/${maxAttempts} failed:`, err);
                  if (attempts >= maxAttempts) {
                    clearInterval(sessionCheckInterval);
                    console.error('Failed to find valid session after multiple attempts');
                    parentThis.setState({
                      mode: 'prompt-email',
                      loading: false,
                      message: 'login.message.sso-session-error'
                    });
                  }
                });
            }
          }, 1000); // Check every second
        }

        window.removeEventListener('message', sessionObtainedListener);
      }
    } catch (err) {
      console.error('Error processing SSO login message:', err);
    }
  }

  window.addEventListener('message', sessionObtainedListener);

  // Add cleanup function
  this._cleanup.push(() => {
    window.removeEventListener('message', sessionObtainedListener);
    if (sessionCheckInterval) {
      clearInterval(sessionCheckInterval);
    }
    if (popup && !popup.closed) {
      popup.close();
    }
  });

  // Also add a check for when the popup closes
  if (popup) {
    const popupCheckInterval = setInterval(() => {
      if (popup.closed) {
        clearInterval(popupCheckInterval);

        // Start polling for session after popup closes
        let postCloseAttempts = 0;
        const maxPostCloseAttempts = 10; // Increase attempts
        const postCloseInterval = setInterval(() => {
          postCloseAttempts += 1;
          const savedSession = getSavedSession();
          if (savedSession) {
            clearInterval(postCloseInterval);
            initializeSession(savedSession);
          } else {
            sdk.sessions.check()
              .then(() => {
                clearInterval(postCloseInterval);
                initializeSession(sdk.session);
              })
              .catch(() => {
                if (postCloseAttempts >= maxPostCloseAttempts) {
                  clearInterval(postCloseInterval);
                  // Only show error if we're still in redirecting mode
                  if (parentThis.state.mode === 'redirecting-to-sso-login') {
                    parentThis.setState({
                      mode: 'prompt-email',
                      loading: false,
                      message: 'login.message.sso-session-error'
                    });
                  }
                }
              });
          }
        }, 1000);

        // Add this interval to cleanup
        parentThis._cleanup.push(() => {
          clearInterval(postCloseInterval);
        });
      }
    }, 500);

    // Add to cleanup
    this._cleanup.push(() => {
      clearInterval(popupCheckInterval);
    });
  }
}

// Update onOption to use popup only when explicitly requested
onOption = (option) => {
  switch (option.type) {
    case 'password': {
      this.setState({
        mode: 'prompt-password',
        name: option.name,
      });
      break;
    }
    case 'sso': {
      this.setState({
        mode: 'redirecting-to-sso-login',
        url: option.url,
      }, () => {
        if (this.props.onRedirectSingleSignOn) {
          this.props.onRedirectSingleSignOn();
        }

        // Check if popup=true is in the URL parameters
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const usePopup = urlParams.get('popup') === 'true';
        const isIframe = window.self !== window.top;

        if (usePopup || isIframe) {
          this.onOpenSSOPopup(option.url);
        } else {
          window.location = option.url;
        }
      });
      break;
    }
    case 'create-account': {
      this.setState({
        mode: 'create-password',
      });
      this.updateHistoryUrl('/create-account');
      break;
    }
    case 'setup-password': {
      this.onSendPasswordRequest();
      break;
    }
    default: {
      this.setState({
        message: 'login.message.unhandled-error',
      });
      break;
    }
  }
}

  onPassword = (password) => {
    this.setState({
      message: null,
      password,
    });

    switch (this.state.mode) {
      case 'prompt-password': {
        this.login(this.state.email);
        break;
      }
      case 'create-password': {
        this.createAccount(this.state.email, password);
        break;
      }
      case 'setup': {
        this.setPassword(this.state.email, password);
        break;
      }
      case 'reset-password': {
        this.resetPassword(this.state.email, password);
        break;
      }
      default: {
        this.setState({
          message: 'login.message.unhandled-error',
        });
        break;
      }
    }
  }

  onResendActivationCode = () => sdk.accounts.generateActivationCode();

  login = (email, totp) => this.loadingWhile(
    sdk.accounts.login(email, this.state.password, totp)
      .then(
        (session) => {
          this.didReceiveCredentials(session);
        },
        (err) => {
          this.onLoginError(
            err &&
            err.response &&
            err.response.data &&
            err.response.data.ResponseStatus
          );
        }
      )
  );

  createAccount = (email, password) => {
    this.loadingWhile(
      sdk.accounts.register(email, password, {
        activationCode: this.props.code,
        referrer: this.state.referrer,
      })
        .then(
          (session) => {
            this.didReceiveCredentials(session);
          },
          (err) => {
            this.onCreateAccountError(
              err &&
              err.response &&
              err.response.data &&
              err.response.data.ResponseStatus
            );
          }
        ),
      this.state.code ? 'loader-message.securing-account-details' : 'loader-message.emailing-activation-code'
    );
  }

  setPassword = (email, password) => {
    const {code} = this.state;
    this.loadingWhile(
      sdk.accounts.setPassword({
        email,
        password,
        code,
      })
        .then(
          (session) => {
            this.didReceiveCredentials(session);
          },
          (err) => {
            this.onCreateAccountError(
              err &&
              err.response &&
              err.response.data &&
              err.response.data.ResponseStatus
            );
          }
        ),
      'loader-message.securing-account-details'
    );
  }

  resetPassword = (email, password) => {
    const {code} = this.state;
    this.loadingWhile(
      sdk.accounts.resetPassword({
        email,
        password,
        code,
      })
        .then(
          (session) => {
            this.didReceiveCredentials(session);
          },
          (err) => {
            this.onResetPasswordError(
              err &&
              err.response &&
              err.response.data &&
              err.response.data.ResponseStatus
            );
          }
        ),
      'loader-message.securing-account-details'
    );
  }

  onValidatePasswordRequest = (responseStatus) => {
    switch (responseStatus) {
      case 'ERROR_USER_INVALID_PASSWORD_SECRET': {
        this.setState({
          mode: 'message',
          message: 'set-password.resent.message',
        });
        break;
      }
      case 'ERROR_USER_PASSWORD_ALREADY_SET': {
        this.setState({
          mode: 'prompt-email',
        });
        break;
      }
      case 'ERROR_USER_PASSWORD_SECRET_NOT_FOUND': {
        this.setState({
          mode: 'message',
          message: 'set-password.sent.message',
        });
        break;
      }
      default: {
        this.onLoginError(responseStatus);
        break;
      }
    }
  }

  onSetupPassword = () => {
    this.loadingWhile(sdk.accounts.sso.initiateLogin(this.state.email))
      .then(
        (response) => {
          if (response && response.option && response.option.type === 'setup-password') {
            this.setState({
              mode: 'setup',
              logo: response.logo,
              avatar: response.avatar,
            });
            this.updateHistoryUrl('/setup-password');
          } else {
            this.onInitiateLogin(response);
          }
        },
        (err) => {
          this.onValidatePasswordRequest(
            err &&
            err.response &&
            err.response.data &&
            err.response.data.ResponseStatus
          );
        }
      );
  }

  onResetPassword = () => {
    sdk.accounts.sso.initiateLogin(this.state.email)
      .then((response) => {
        this.setState({
          logo: response.logo,
          avatar: response.avatar,
        });
      });
  }

  onLoginError = (responseStatus) => {
    switch (responseStatus) {
      case 'ERROR_NOT_FOUND':
      case 'ERROR_USER_INVALID_LOGIN': {
        this.setState({
          message: 'login.message.invalid-login',
        });
        break;
      }
      case 'ERROR_USER_INVALID_LOGIN_COUNT': {
        this.setState({
          message: 'login.message.too-many-tries',
        });
        break;
      }
      case 'ERROR_USER_DISABLED': {
        this.setState({
          mode: 'account-disabled',
        });
        break;
      }
      case 'ERROR_MFA_REQUIRED': {
        this.setState({
          mode: 'prompt-mfa',
        });
        break;
      }
      case 'ERROR_INVALID_TOTP': {
        this.setState({
          message: 'enable.mfa.error.invalid-code',
        });
        break;
      }
      default: {
        this.setState({
          message: 'login.message.unhandled-error',
        });
        break;
      }
    }
  }

  onCreateAccountError = (responseStatus) => {
    switch (responseStatus) {
      case 'ERROR_USER_IS_REGISTERED': {
        this.setState({
          message: 'create-account.message.already-registered',
        });
        break;
      }
      default: {
        this.onLoginError(responseStatus);
        break;
      }
    }
  }

  onResetPasswordError = (responseStatus) => {
    switch (responseStatus) {
      case 'INVALID': {
        this.setState({
          message: 'reset-password.update.message.invalid',
          mode: 'message',
        });
        sdk.accounts.forgotPassword(this.state.email);
        break;
      }
      default: {
        this.onLoginError(responseStatus);
        break;
      }
    }
  }

  onSendPasswordRequest = () => {
    this.setState({
      message: null,
    });

    this.loadingWhile(sdk.accounts.setPasswordRequest(this.state.email))
      .then(
        () => {
          this.setState({
            mode: 'message',
            message: 'set-password.sent.message',
          });
        },
        (err) => {
          this.onValidatePasswordRequest(
            err &&
            err.response &&
            err.response.data &&
            err.response.data.ResponseStatus
          );
        }
      );
  }

  onForgot = () => {
    this.setState({
      message: null,
    });

    this.loadingWhile(sdk.accounts.forgotPassword(this.state.email))
      .then(() => {
        this.setState({
          mode: 'message',
          message: 'reset-password.sent.message',
        });
      });
  }

  onBack = () => {
    if (this.state.loading) {
      // Prevent the user from navigating backwards (via [esc] for example) while waiting for another task to complete
      return;
    }
    this.setState({
      mode: 'prompt-email',
      avatar: null,
      name: null,
      logo: null,
      message: null,
    });
  }

  didReceiveCredentials = (_session) => {
    let session = _session;
    saveSession(session);
    sdk.setSession(session);
    if (!session.user.hasActivated) {
      // if the user is not activated, and the container is passed a "code", attempt to use that code to activate the user.
      const wait = this.props.code
        ? sdk.accounts.activate(this.props.code)
        : Promise.resolve();
      wait.catch(() => {}).then(() => {
        session = sdk.session; // eslint-disable-line prefer-destructuring
        saveSession(session);
        sdk.setSession(session);
        this._didLogin();
        if (sdk.session.user.hasActivated) {
          // if the user was activated as part of the above logic, save the session, and perform the usual "successful login process"
          this.props.onCredentials(sdk.session);
        } else {
          this.setState({
            mode: 'prompt-activate',
          });
          this.updateHistoryUrl('/activate/' + session.userId);
          this.initiateActivationWatchers();
          if (this.props.onAwaitingActivation) {
            this.props.onAwaitingActivation(session);
          }
        }
        this._$apply();
      });
    } else {
      this._didLogin();
      this.props.onCredentials(session);
      this._$apply();
    }
  }

  updateHistoryUrl = (path, query) => {
    if (!window.PageProof) {
      return; // TODO maybe just use the history API directly
    }
    const {$location} = window.__pageproof_bridge__;
    if (path[0] !== '/') {
      path = '/' + path; // eslint-disable-line
    }
    const queryString = query ? ('?' + Object.keys(query).map(key => `${key}=${encodeURIComponent(query[key])}`).join('&')) : '';
    const url = path + queryString;
    if ($location.url() !== url) {
      $location.url(url).ignore();
    }
  }

  onActivationCode = code => sdk.accounts.activate(code).then(() => this.didReceiveCredentials(sdk.session));

  shouldRedirectToSSO = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    // Check if the SSORedirect parameter exists and is equal to "true"
    if (urlParams.has('SSORedirect') && urlParams.get('SSORedirect') === 'true') {
      return true;
    }
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  _$apply() {
    if (window.PageProof && !window.__pageproof_bridge__.$rootScope.$$phase) {
      window.__pageproof_bridge__.$rootScope.$apply();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  _didLogin() {
    if (window.PageProof) {
      window.__pageproof_bridge__.UserService.DidLogin();
    }
  }

  loadingWhile(promise, message) {
    this.setState({
      loading: message || true,
    });
    promise
      .catch((err) => {
        this.handleError(err);
      })
      .then(() => {
        this.setState({
          loading: false,
        });
      });
    return promise;
  }

  // eslint-disable-next-line class-methods-use-this
  handleError(err) {
    if (window.Bugsnag) {
      window.Bugsnag.notifyException(err);
    }
  }

  render() {
    return (
      <Fragment>
        <style>
          {`
            header {
              border-bottom-color: transparent;
              background-color: transparent;
            }
          `}
        </style>
        <div>
          <div style={{height: 20}} />
          <UnifiedLogin
            {...this.state}
            loading={this.state.loading}
            avatar={this.state.avatar || 'img/icons/profile.svg'}
            onEmail={this.onEmail}
            onPassword={this.onPassword}
            onBack={this.onBack}
            onForgot={this.onForgot}
            onOption={this.onOption}
            onResendActivationCode={this.onResendActivationCode}
            onActivationCode={this.onActivationCode}
            setPassword={(input) => {
              this.setState({
                password: input,
              });
            }}
            onTOTP={this.login}
          />
          <div style={{height: 60}} />
        </div>
      </Fragment>
    );
  }
}

export default withTimers(UnifiedLoginContainer);
