import { useState, useEffect } from 'react';
import { sdk } from '../util/sdk';
import { useLocalStorage } from '../components/LocalStorage';

function getUserPreferences(raw = false) {
  return sdk.preferences.all()
    .then(result => (raw ? result.data : getUserPreferencesFromRawPreferences(result.data)));
}

function getUserPreferencesFromRawPreferences(rawPreferences) {
  let normalizedPreferences = null;

  if (rawPreferences && Array.isArray(rawPreferences)) {
    normalizedPreferences = {};
    rawPreferences.forEach(({ Name, DefaultValue, Value }) => {
      normalizedPreferences[Name] = {
        value: Value !== null ? Value : DefaultValue,
        rawValue: Value,
        defaultValue: DefaultValue,
      };
    });
  }

  return normalizedPreferences;
}

let lastRefresh = null;
const refreshThreshold = 1000 * 60 * 60; // every 60 minutes

function useUserPreferences() {
  const userId = sdk.session ? sdk.session.userId : null;

  const [rawPreferences, setRawPreferences] = useLocalStorage(`pageproof.cache.user-preferences.${userId}`);
  const [isLoading, setIsLoading] = useState(() => !rawPreferences);

  const loadUserPreferences = () => {
    setIsLoading(true);
    getUserPreferences(true)
      .then((preferences) => {
        preferences.forEach((preference) => {
          if (preference.Name === 'colors') {
            if (preference.Value && preference.Value.done && preference.Value.done.normal === '#138b3b') {
              // eslint-disable-next-line no-param-reassign
              preference.Value.done.normal = '#128739';
            }
          }
        });
        setRawPreferences(preferences);
        setIsLoading(false);
      }, () => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const canRefresh = !lastRefresh || (new Date() - refreshThreshold) > lastRefresh;
    if (canRefresh) {
      lastRefresh = new Date();
      loadUserPreferences();
    }
  }, [
    userId,
  ]);

  const updateUserPreferenceLocally = (preferenceName, preferenceValue) => {
    const index = rawPreferences.findIndex(preference => preference.Name === preferenceName);
    if (index !== -1) {
      rawPreferences[index].Value = preferenceValue;
    }
    setRawPreferences(rawPreferences);
  };

  const resetUserPreference = (preferenceName) => {
    updateUserPreferenceLocally(preferenceName, null);
    sdk.preferences.reset(preferenceName);
  };

  const updateUserPreference = (preferenceName, preferenceValue) => {
    const index = rawPreferences.findIndex(preference => preference.Name === preferenceName);
    if (index !== -1) {
      rawPreferences[index].Value = preferenceValue;
    }
    updateUserPreferenceLocally(preferenceName, preferenceValue); // Updating local storage before BE to fake UI feel as have response quickly
    sdk.preferences.update(preferenceName, preferenceValue);
  };

  const normalizedPreferences = getUserPreferencesFromRawPreferences(rawPreferences);
  return [isLoading, normalizedPreferences, {
    updateUserPreference,
    resetUserPreference,
  }];
}

export default useUserPreferences;
export { getUserPreferences, useUserPreferences, getUserPreferencesFromRawPreferences };
