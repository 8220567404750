/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import { InlineSVG } from '../InlineSVG';

function SearchTab({
  selected,
  tab,
  onRemove,
  onClick,
  color,
}) {
  return (
    <div
      className={classname(`VerticalTab VerticalTab--${color}`, {
        'VerticalTab--selected': selected,
      })}
      onClick={() => onClick(tab.id)}
    >
      <div className={`VerticalTab--${color}__label`}>
        {tab.label}
        {(tab.proofCount > 0 || tab.proofs.length > 0) && (
          <span className="VerticalTab__proof-count">{tab.proofCount || tab.proofs.length}</span>
        )}
      </div>

      {onRemove &&
        <span className="VerticalTab__close" onClick={() => onRemove(tab.id)}>&times;</span>
      }
      <InlineSVG className="VerticalTab__arrow" src="/img/interface/arrow-down.svg" />
    </div>
  );
}

if (process.env.NODE_ENV !== 'production') {
  SearchTab.propTypes = {
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired,
    tab: PropTypes.objectOf(PropTypes.any).isRequired,
    onRemove: PropTypes.func,
    color: PropTypes.string.isRequired,
  };
}

export default SearchTab;
