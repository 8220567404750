/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import { InlineSVG } from '../../InlineSVG';
import Reveal from '../../Reveal';
import IconButton from '../../Button/IconButton';
import Tooltip from '../../Tooltip';
import Author from '../Author';
import Editor from '../Editor';
import Text from '../Text';
import Agree from '../Agree';
import Attach from '../Icon/Attach';
import Save from '../Icon/Save';
import Date from '../Date';
import Options from '../Options';
import Close from '../Close';
import Loading from '../Loading';
import Attachments from '../Attachments';
import Mark from '../Mark';
import Translation from '../../Text/Translation';
import FileDrop from '../../FileDrop';
import FileClick from '../../FileClick';
import Metadata from '../../Metadata/Metadata';
import MetadataButton from '../../Metadata/Button';
import MetadataProperty from '../../Metadata/Property';
import LabelOptions from '../LabelOptions';
import Spacing from '../Spacing';
import Agrees from '../Agrees';
import Indent from '../Indent';
import CommentNumber from '../CommentNumber';
import AdditionalText from '../AdditionalText';
import Edited from '../Edited';
import { getLabel, replaceLabel, removeLabel } from '../Label/utils';
import NeverEmpty from '../../NeverEmpty';
import Attachment from '../Attachment';
import Send from '../Icon/Send';
import Error from '../Error';
import MediaTime from '../MediaTime';
import css from './Comment.scss';
import getSDK from '../../../util/sdk';
import RestoreMetadata from '../Icon/RestoreMetadata';
import Snapshot from '../Icon/Snapshot';
import ShowReply from '../ShowReply';
import Delay from '../../Delay';
import NextAvatar from '../../../containers/NextAvatar';
import CommentTooltipContent from '../CommentTooltipContent';
import { PopupMenu, Option } from '../../PopupMenu';
import CommentSpacing from '../CommentSpacing';
import SetVisibility from '../SetVisibility';

const sdk = getSDK();

class Comment extends Component {
  static propTypes = {
    editorRef: PropTypes.func,
    id: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }),
    date: PropTypes.instanceOf(Date),
    attachment: PropTypes.shape(Attachment.propTypes),
    attachments: PropTypes.arrayOf(PropTypes.shape(Attachment.propTypes)),
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.node,
    mentionSuggestions: PropTypes.func,
    metadata: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    mediaTime: PropTypes.number,
    mediaDuration: PropTypes.number,
    mediaDetailed: PropTypes.bool,
    onToggleReplyEditor: PropTypes.func,
    editedDate: PropTypes.instanceOf(Date),
    editedUserId: PropTypes.string,
    mark: PropTypes.string,
    nextMarkOptions: PropTypes.arrayOf(PropTypes.any),
    agrees: PropTypes.arrayOf(PropTypes.string),
    agreeCount: PropTypes.number,
    showMetadata: PropTypes.bool,
    hasSnapshot: PropTypes.bool,
    hasUnsavedChanges: PropTypes.bool,
    pinCount: PropTypes.bool,
    timecodes: PropTypes.arrayOf(PropTypes.string),
    doesAgree: PropTypes.bool,
    hasError: PropTypes.bool,
    isLoading: PropTypes.bool,
    isEditing: PropTypes.bool,
    isSelected: PropTypes.bool,
    isReply: PropTypes.bool,
    isCreate: PropTypes.bool,
    canEdit: PropTypes.bool,
    canCancel: PropTypes.bool,
    canLabel: PropTypes.bool,
    canAttach: PropTypes.bool,
    canDelete: PropTypes.bool,
    showAgrees: PropTypes.bool,
    canAgree: PropTypes.bool,
    showMark: PropTypes.bool,
    canMark: PropTypes.bool,
    canSelect: PropTypes.bool,
    canFlash: PropTypes.bool,
    canSave: PropTypes.bool,
    editButtonTooltip: PropTypes.node,
    onSelect: PropTypes.func,
    onFlash: PropTypes.func,
    onEdit: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    onReply: PropTypes.func,
    onDelete: PropTypes.func,
    onAgree: PropTypes.func,
    onAttach: PropTypes.func,
    canTogglePrivate: PropTypes.bool,
    togglePrivateDisabledMessage: PropTypes.string,
    isPrivate: PropTypes.bool,
    onTogglePrivate: PropTypes.func,
    onValidateAttachmentType: PropTypes.func,
    onValidateAttachmentSize: PropTypes.func,
    onOpenProfile: PropTypes.func,
    onMark: PropTypes.func,
    onToggleMetadata: PropTypes.func,
    onRestoreMetadata: PropTypes.func,
    framesPerSecond: PropTypes.number,
  };

  static defaultProps = {
    onValidateAttachmentType: () => {}, // by default, skip attachment validation
    onValidateAttachmentSize: () => {}, // by default, skip attachment validation
  };

  componentDidMount() {
    window.__pageproof_bridge__.zipService.prepare();
  }

  shouldComponentUpdate(nextProps) {
    /* eslint-disable no-nested-ternary */
    return (
      this.props.id !== nextProps.id ||
      (
        ((!!this.props.user === !!nextProps.user) && !!nextProps.user)
          ? (
            this.props.user.id !== nextProps.user.id ||
            this.props.user.email !== nextProps.user.email ||
            false
          )
          : false
      ) ||
      (
        ((!!this.props.date === !!nextProps.date) && !!nextProps.date)
          ? this.props.date.getTime() !== nextProps.date.getTime()
          : (!!this.props.date !== !!nextProps.date)
      ) ||
      this.props.value !== nextProps.value ||
      (!!this.props.metadata !== !!nextProps.metadata) ||
      this.props.mediaTime !== nextProps.mediaTime ||
      this.props.mediaDuration !== nextProps.mediaDuration ||
      this.props.mark !== nextProps.mark ||
      (this.props.agrees ? this.props.agrees.length : 0) !== (nextProps.agrees ? nextProps.agrees.length : 0) ||
      this.props.agreeCount !== nextProps.agreeCount ||
      this.props.showMetadata !== nextProps.showMetadata ||
      this.props.hasSnapshot !== nextProps.hasSnapshot ||
      this.props.hasUnsavedChanges !== nextProps.hasUnsavedChanges ||
      this.props.pinCount !== nextProps.pinCount ||
      this.haveTimecodesChanged(nextProps) ||
      this.props.doesAgree !== nextProps.doesAgree ||
      this.props.hasError !== nextProps.hasError ||
      this.props.isLoading !== nextProps.isLoading ||
      this.props.isEditing !== nextProps.isEditing ||
      this.props.isSelected !== nextProps.isSelected ||
      this.props.canEdit !== nextProps.canEdit ||
      this.props.canCancel !== nextProps.canCancel ||
      this.props.canLabel !== nextProps.canLabel ||
      this.props.canAttach !== nextProps.canAttach ||
      this.props.canDelete !== nextProps.canDelete ||
      this.props.showAgrees !== nextProps.showAgrees ||
      this.props.canAgree !== nextProps.canAgree ||
      this.props.showMark !== nextProps.showMark ||
      this.props.canMark !== nextProps.canMark ||
      this.props.canSelect !== nextProps.canSelect ||
      this.props.canFlash !== nextProps.canFlash ||
      this.props.canSave !== nextProps.canSave ||
      this.props.editButtonTooltip !== nextProps.editButtonTooltip ||
      this.haveAttachmentsChanged(nextProps) ||
      this.props.editedDate !== nextProps.editedDate ||
      this.props.editedUserId !== nextProps.editedUserId ||
      this.props.mentionSuggestions !== nextProps.mentionSuggestions ||
      this.props.canTogglePrivate !== nextProps.canTogglePrivate ||
      this.props.togglePrivateDisabledMessage !== nextProps.togglePrivateDisabledMessage ||
      this.props.isPrivate !== nextProps.isPrivate ||
      this.props.onTogglePrivate !== nextProps.onTogglePrivate ||
      this.props.framesPerSecond !== nextProps.framesPerSecond ||
      false
    );
  }

  haveAttachmentsChanged = (nextProps) => {
    const previousAttachments = this.props.attachments || [];
    const nextAttachments = nextProps.attachments || [];

    if (previousAttachments.length !== nextAttachments.length) {
      return true;
    }

    return nextAttachments.some((nextAttachment, index) => {
      const previousAttachment = previousAttachments[index];
      return (
        previousAttachment.id !== nextAttachment.id ||
        previousAttachment.name !== nextAttachment.name ||
        previousAttachment.isProcessing !== nextAttachment.isProcessing ||
        previousAttachment.isUploading !== nextAttachment.isUploading ||
        previousAttachment.canDownload !== nextAttachment.canDownload ||
        previousAttachment.canView !== nextAttachment.canView ||
        previousAttachment.canDelete !== nextAttachment.canDelete
      );
    });
  }

  haveTimecodesChanged = (nextProps) => {
    const previousTimecodes = this.props.timecodes || [];
    const nextTimecodes = nextProps.timecodes || [];

    if (previousTimecodes.length !== nextTimecodes.length) {
      return true;
    }

    return nextTimecodes.some((nextTimecode, index) => previousTimecodes[index] !== nextTimecode);
  }

  render() {
    const {
      editorRef,
      id,
      user,
      date,
      attachment,
      attachments,
      value,
      placeholder,
      mentionSuggestions,
      metadata,
      mediaTime,
      mediaDuration,
      mediaDetailed,
      editedDate,
      editedUserId,
      mark,
      nextMarkOptions,
      agrees,
      agreeCount,
      showMetadata,
      hasSnapshot,
      hasUnsavedChanges,
      pinCount,
      timecodes,
      doesAgree,
      hasError,
      number,
      isLoading,
      isEditing,
      isSelected,
      isReply,
      isCreate,
      canEdit,
      canCancel,
      canLabel,
      canAttach,
      canDelete,
      showAgrees,
      canAgree,
      showMark,
      canMark,
      canSelect,
      canFlash,
      canSave,
      editButtonTooltip,
      onSelect,
      onFlash,
      onEdit,
      onCancel,
      onChange,
      onSave,
      onDelete,
      onAgree,
      onValidateAttachmentType,
      onValidateAttachmentSize,
      onAttach,
      onOpenProfile,
      onMark,
      onToggleMetadata,
      onRestoreMetadata,
      onToggleReplyEditor,
      onHashtagClick,
      onHashtagHover,
      canTogglePrivate,
      togglePrivateDisabledMessage,
      onTogglePrivate,
      isPrivate,
      importMetadata,
      framesPerSecond,
    } = this.props;

    const getCommentTooltip = (commentNumber) => {
      const commentData = onHashtagHover(commentNumber);
      return commentData
        ? <CommentTooltipContent commentData={commentData} />
        : null;
    };

    const onSafeAttach = (files) => {
      if (!canAttach) {
        return;
      }

      const validFiles = [];

      files.forEach((file) => {
        const isValidType = onValidateAttachmentType(file);
        const isValidSize = onValidateAttachmentSize(file);

        if (isValidSize) {
          if (isValidType) {
            validFiles.push(file);
          } else {
            window.__pageproof_bridge__.zipService.prepare()
              .then(() => {
                window.__pageproof_bridge__.zipService.compress({ [file.name]: file })
                  .then((blob) => {
                    // eslint-disable-next-line no-undef
                    const compressedFile = new File([blob], `${file.name}.zip`, { type: blob.type });
                    onAttach([compressedFile]);
                  });
              });
          }
        }
      });

      onAttach(validFiles);
    };
    const _attachments = Array.prototype.concat
      .apply([], attachment ? [attachment] : attachments)
      .filter(Boolean);
    let editor;
    const compact = !_attachments.length;
    const EditButton = isCreate ? Send : Save;
    const buttons = isEditing && (
      <div
        className={css.Options}
        onClick={event => event.stopPropagation()}
      >
        {canAttach &&
          <Tooltip
            title={<Translation value="comments.attachment.tooltip" />}
            up
            center
          >
            <FileClick
              multiple
              onSelect={onSafeAttach}
            >
              <Attach />
            </FileClick>
          </Tooltip>
        }
        {canLabel &&
          <LabelOptions
            type={getLabel(value)}
            onChange={(label) => {
              // eslint-disable-next-line
              let value = editor.getValue();
              if (label) {
                value = replaceLabel(value, label);
              } else {
                value = removeLabel(value);
              }
              editor.setValue(value);
            }}
          />
        }
        {isCreate && isReply && (canTogglePrivate || isPrivate || togglePrivateDisabledMessage) && (
          <SetVisibility
            isPrivate={isPrivate}
            onTogglePrivate={onTogglePrivate}
            isDisabled={!canTogglePrivate}
            disabledMessage={togglePrivateDisabledMessage}
          />
        )}
        <Tooltip
          title={editButtonTooltip}
          up
          center
          disabled={!editButtonTooltip}
        >
          <EditButton
            active={canSave}
            disabled={!canSave}
            onClick={() => onSave(editor.getValue())}
          />
        </Tooltip>
      </div>
    );
    const hasAgrees = agreeCount > 0;
    return (
      <CommentSpacing.Consumer>
        {spacing => (
          <FileDrop
            multiple
            onSelect={onSafeAttach}
          >
            {({ active }) => (
              <div
                className={classname(css.Comment, css[`Comment--${spacing}-spacing`], {
                  [css['Comment--reply']]: isReply && !isCreate,
                  [css['Comment--private']]: isPrivate,
                  [css['Comment--selected']]: isSelected,
                  [css['Comment--dropping']]: active,
                  [css['Comment--error']]: hasError,
                })}
                id={id}
                data-comment
              >
                {!isCreate && isPrivate && !isReply && (
                  <div className={css.Comment__header}>
                    <Tooltip
                      up
                      center
                      title={<Translation value="comments.private.visible-to-team" />}
                    >
                      <span className={css.Comment__header__label}>
                        <InlineSVG
                          className={css.Comment__header__label__icon}
                          src="img/icons/material/visibility_off_flipped-24px.svg"
                        />
                        <Translation value="comment.label.private" />
                      </span>
                    </Tooltip>
                  </div>
                )}
                <div
                  className={css.Comment__inner}
                  onClick={() => {
                    if (canSelect && !isSelected && !isEditing) {
                      onSelect();
                    } else if (canFlash) {
                      onFlash();
                    }
                  }}
                >
                  <NeverEmpty>
                    <div
                      className={classname(css.Options, css['Options--primary'])}
                      onClick={event => event.stopPropagation()}
                    >
                      {metadata &&
                        <MetadataButton
                          arrow={showMetadata}
                          onClick={onToggleMetadata}
                          offset={24}
                          ariaLabel={<Translation value="comments.metadata.tooltip" />}
                        />
                      }
                      {(showAgrees && spacing === 'condensed') &&
                        <Tooltip
                          title={<Agrees users={agrees} />}
                          left
                          up
                        >
                          <Agree
                            label={hasAgrees ? agreeCount : null}
                            active={doesAgree}
                            readOnly={!canAgree}
                            size={17}
                            onClick={() => (canAgree && onAgree(!doesAgree))}
                          />
                        </Tooltip>
                      }
                      {showMark && (nextMarkOptions.length > 1
                        ? (
                          <PopupMenu
                            disabled={!canMark}
                            left
                            down
                            options={(
                              <Fragment>
                                {nextMarkOptions.map(({ icon, type, state }) => (
                                  <Option
                                    label={(
                                      <div className={css.Comment__mark}>
                                        <Translation value={`comments.tooltip.option.${state ? type : 'unmarked'}`} />
                                        {icon && <IconButton
                                          icon={
                                            <Mark
                                              type={icon}
                                              light
                                            />
                                          }
                                        />}
                                      </div>
                                    )}
                                    onClick={() => onMark(type, state)}
                                  />
                                ))}
                              </Fragment>
                            )}
                          >
                            <IconButton
                              icon={
                                <Mark
                                  type={mark}
                                  readOnly={!canMark}
                                />
                              }
                              ariaLabel={<Translation value="comments.tooltip.mark" />}
                            />
                          </PopupMenu>
                        ) : (
                          <Tooltip
                            title={<Translation value={`comments.tooltip.${mark}`} />}
                            left
                            up
                          >
                            {tooltip => (
                              <IconButton
                                icon={
                                  <Mark
                                    type={mark}
                                    readOnly={!canMark}
                                  />
                                }
                                onClick={canMark && (() => {
                                  onMark(nextMarkOptions[0].type, nextMarkOptions[0].state);
                                  tooltip.hide();
                                })}
                                ariaLabel={tooltip.title}
                              />
                            )}
                          </Tooltip>
                        )
                      )}
                      {(number > 0 && spacing === 'condensed') && (
                        <CommentNumber value={number} />
                      )}
                      {isPrivate && !isCreate && isReply && (
                        <Tooltip
                          up
                          center
                          title={<Translation value="comments.private.visible-to-team" />}
                        >
                          <div className={css.Comment__privatePill}>
                            <InlineSVG
                              className={css.Comment__privatePill__icon}
                              src="img/icons/material/visibility_off_flipped-24px.svg"
                            />
                            <Translation value="comment.label.private" />
                          </div>
                        </Tooltip>
                      )}
                      {(!isCreate) &&
                        <Options
                          isEditing={isEditing}
                          hasUnsavedChanges={hasUnsavedChanges}
                          canEdit={canEdit}
                          canCancel={canCancel}
                          canDelete={canDelete}
                          onEdit={onEdit}
                          onCancel={onCancel}
                          onDelete={onDelete}
                        />
                      }
                      {!isReply && (canTogglePrivate || togglePrivateDisabledMessage) && (
                        <SetVisibility
                          isPrivate={isPrivate}
                          onTogglePrivate={onTogglePrivate}
                          isDisabled={!canTogglePrivate}
                          disabledMessage={togglePrivateDisabledMessage}
                        />
                      )}
                      {isCreate && canCancel &&
                        <Close onClose={onCancel} />
                      }
                    </div>
                  </NeverEmpty>
                  {user &&
                    <Author
                      id={user.id}
                      email={user.email}
                      isPdfImport={importMetadata && importMetadata.type === 'pdf'}
                      date={date}
                      name={importMetadata && importMetadata.author}
                      wrapAdditionalText={!!mediaDetailed && !!mediaDuration && mediaDuration !== 0.5}
                      additional={[
                        pinCount > 1 && (
                          <AdditionalText>
                            <Tooltip
                              title={() => (
                                <Fragment>
                                  {timecodes.map(timecode => <div key={timecode}>{timecode}</div>)}
                                </Fragment>
                              )}
                              up
                              center
                              disabled={!timecodes}
                            >
                              <span style={{ whiteSpace: 'nowrap' }}>
                                <Translation
                                  value="comment.multiple-pins"
                                  params={{ count: pinCount }}
                                />
                              </span>
                            </Tooltip>
                          </AdditionalText>
                        ),
                        (mediaTime !== undefined && pinCount < 2) && (
                          <AdditionalText>
                            <MediaTime
                              time={mediaTime}
                              duration={mediaDuration}
                              detailed={mediaDetailed}
                              framesPerSecond={framesPerSecond}
                            />
                          </AdditionalText>
                        ),
                        date && (spacing !== 'condensed') && (
                          <AdditionalText>
                            <Date
                              date={date}
                              isImported={!!importMetadata}
                            />
                          </AdditionalText>
                        ),
                        editedDate && editedUserId && (
                          <AdditionalText>
                            <Edited
                              date={editedDate}
                              userId={editedUserId}
                            />
                          </AdditionalText>
                        ),
                        hasError && <Error />,
                      ]}
                      compact={isReply}
                      onOpenProfile={onOpenProfile}
                    />
                  }
                  <Spacing>
                    {(number > 0 && spacing !== 'condensed') && (
                      <CommentNumber value={number} />
                    )}
                    <Indent>
                      {(metadata || importMetadata) &&
                        <Reveal
                          align="top"
                          visible={showMetadata}
                          render
                          immediate
                        >
                          <div onClick={event => event.stopPropagation()}>
                            <Metadata>
                              {onRestoreMetadata &&
                                <div className={css.MetadataIcon}>
                                  <Tooltip
                                    title={<Translation value="comment.metadata.restore-view.tooltip" />}
                                    up
                                    center
                                  >
                                    <RestoreMetadata
                                      active
                                      onClick={onRestoreMetadata}
                                    />
                                  </Tooltip>
                                </div>
                              }
                              {hasSnapshot &&
                                <div className={css.MetadataIcon}>
                                  <Tooltip
                                    title={<Translation value="comment.metadata.snapshot.tooltip" />}
                                    up
                                    center
                                  >
                                    <Snapshot
                                      active
                                      onClick={onSelect}
                                    />
                                  </Tooltip>
                                </div>
                              }
                              {metadata.map(props => (
                                <MetadataProperty {...props} />
                              ))}
                            </Metadata>
                          </div>
                        </Reveal>
                      }
                      <div className={css.Split}>
                        {isEditing
                          ? (
                            <div>
                              {/* TODO (jacob) we should optimise this component so that it only loads Draft.js when the user interacts with it (onFocus) */}
                              <Editor
                                ref={(ref) => {
                                  editor = ref;
                                  if (editorRef) editorRef(ref);
                                }}
                                initialValue={value}
                                mentionSuggestions={!isPrivate && mentionSuggestions}
                                placeholder={placeholder}
                                onChange={onChange}
                                onSave={onSave}
                                canSave={canSave}
                                onFilesPasted={onSafeAttach}
                              />
                              {(user === null && isEditing && spacing !== 'condensed') &&
                                <div className={css.Split__Avatar}>
                                  <NextAvatar
                                    id={sdk.session.userId}
                                    size={30}
                                  />
                                </div>
                              }
                            </div>
                          )
                          : (
                            isLoading
                              ? (
                                <Delay ms={1000}>
                                  <Loading />
                                </Delay>
                              )
                              : (
                                <div className={css.clearfix}>
                                  {(showAgrees && spacing !== 'condensed') && (
                                    <div
                                      className={css.Agree}
                                      onClick={event => event.stopPropagation()}
                                    >
                                      <Tooltip
                                        title={<Agrees users={agrees} />}
                                        left
                                        up
                                      >
                                        <Agree
                                          label={hasAgrees ? agreeCount : null}
                                          active={doesAgree}
                                          readOnly={!canAgree}
                                          size={17}
                                          onClick={() => (canAgree && onAgree(!doesAgree))}
                                        />
                                      </Tooltip>
                                    </div>
                                  )}
                                  <div onDoubleClick={canEdit ? (() => onEdit(true)) : undefined}>
                                    <Text
                                      value={value === ' ' && !!importMetadata ? importMetadata.annotationDisplayName : value}
                                      onHashtagClick={onHashtagClick}
                                      onHashtagHover={getCommentTooltip}
                                      isPageProofGenerated={value === ' ' && !!importMetadata && !!importMetadata.annotationDisplayName}
                                    />
                                  </div>
                                </div>
                              )
                          )
                        }
                        {compact
                          ? buttons
                          : null}
                      </div>
                      {user === null && (
                        <ShowReply
                          onToggleReplyEditor={onToggleReplyEditor}
                          isCondensed={spacing === 'condensed'}
                        />
                      )}
                    </Indent>
                  </Spacing>
                  {(_attachments.length || !compact) &&
                    <Spacing>
                      <Indent>
                        <div
                          className={css.clearfix}
                          onClick={event => event.stopPropagation()}
                        >
                          {!compact
                            ? buttons
                            : null}
                          <Attachments
                            attachments={_attachments}
                          />
                        </div>
                      </Indent>
                    </Spacing>
                  }
                </div>
              </div>
            )}
          </FileDrop>
        )}
      </CommentSpacing.Consumer>
    );
  }
}

export default Comment;
