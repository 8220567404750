/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { ApolloClient } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient as createWsClient } from 'graphql-ws';
import env from '../../../../shared/env';
import { getSharedClientComponents } from './getSharedClientComponents';
import { validateUserSession } from './validateUserSession';
import { getAuthHeaders } from './getAuthHeaders';

// Borrowed from stitch default env vars
// https://github.com/PageProofCom/stitch/blob/a24a5daebae472d392216429ef4f41a15201863c/src/internals/defaultEnv.ts#L5
const maxWebsocketConnectionAttempts = 20;
const websocketConnectionAcknowledgmentTimeout = 10000;
const websocketKeepAliveInterval = 30000;

let spiderClient;

/**
 * Currently only subscription links are implemented, queries will still run but will be sent
 * as a subscription request over websockets which may not be the most efficient way to do things.
 * Use sdk.graphql (from next/projects/quark/src/util/sdk) for spider queries for now.
 */
export function getSpiderClient() {
  if (!spiderClient) {
    spiderClient = createSpiderClient(env.spider_query_url, env.spider_subscription_url);
  }

  return spiderClient;
}

// Borrowed from stitch
// https://github.com/PageProofCom/stitch/blob/a24a5daebae472d392216429ef4f41a15201863c/src/components/ApolloProvider/internals/createClient.ts#L33
// TODO: Implement query link
function createSpiderClient(queryUri, subscriptionUri) {
  validateUserSession();

  let connectingTime;
  let lastKeepAliveTime;
  let keepAliveTimeout;
  let currentSocket;
  let nextId = 0;

  const wsClient = createWsClient({
    url: () => subscriptionUri,
    connectionParams: async () => ({ headers: getAuthHeaders('POST') }),
    // eslint-disable-next-line no-plusplus
    generateID: () => `${++nextId}`,
    keepAlive: websocketKeepAliveInterval,
    connectionAckWaitTimeout: websocketConnectionAcknowledgmentTimeout,
    retryAttempts: maxWebsocketConnectionAttempts,
    shouldRetry: () => true,
    on: {
      connecting() {
        // eslint-disable-next-line no-undef
        connectingTime = performance.now();
        console.info('Connecting to WebSocket...');
      },
      connected(socket) {
        currentSocket = socket;

        // eslint-disable-next-line no-undef
        console.info(`Connected to WebSocket in ${(performance.now() - connectingTime).toFixed(2)}ms.`);
      },
      ping(received) {
        if (!received) {
          // eslint-disable-next-line no-undef
          lastKeepAliveTime = performance.now();
          keepAliveTimeout = window.setTimeout(() => {
            if (currentSocket && currentSocket.readyState === 1) {
              console.info('Keep-alive timed out waiting for a response.');
              wsClient.terminate();
            }
          }, 5000);
        }
      },
      pong(received) {
        if (received) {
          clearTimeout(keepAliveTimeout);
          // eslint-disable-next-line no-undef
          const roundTripDuration = performance.now() - lastKeepAliveTime;
          console.debug(`Keep-alive response received in ${roundTripDuration.toFixed(2)}ms.`);
        }
      },
      closed(event) {
        clearTimeout(keepAliveTimeout);
        console.info(`Disconnected from WebSocket (reason: ${JSON.stringify((event).reason)}).`);
      },
      message(message) {
        console.debug(() => [`Received message from WebSocket (${JSON.stringify(message).length} bytes).`, message]);
      },
      error(err) {
        onError(err);
      },
    },
    onNonLazyError(err) {
      onError(err);
    },
  });

  const wsLink = new GraphQLWsLink(wsClient);

  const { cache, defaultOptions } = getSharedClientComponents();

  function onError(err) {
    console.error(err);

    if (err instanceof Error) {
      window.Bugsnag.notify(err);
    // eslint-disable-next-line no-undef
    } else if (err instanceof ErrorEvent) {
      window.Bugsnag.notify(err.error || new Error(err.message));
    }
  }

  return new ApolloClient({
    link: wsLink,
    cache,
    defaultOptions: { ...defaultOptions },
  });
}
