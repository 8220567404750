/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import GeneralCommentIconButton from '../GeneralCommentIconButton';
import css from './GeneralCommentIconButtons';

const TARGET_OFFSET = 10;

const GeneralCommentIconButtons = ({
  position,
  compareType,
  page,
  targetZIndex,
  filterName,
  commentOrder,
  getGeneralCommentsByType,
  selectGeneralComment,
  flashingType,
}) => {
  const [generalComments, setGeneralComments] = useState({});

  useEffect(() => {
    setGeneralComments(getGeneralCommentsByType(page.comments));
  }, [
    page.generalCommentDoneCount,
    page.generalCommentTodoCount,
    page.generalCommentUnmarkedCount,
    filterName,
    commentOrder.orderCommentsBy,
    commentOrder.isReversedCommentOrder,
    page.comments,
  ]);

  return (
    <div
      id={compareType ? `general-comment-icon-buttons-${compareType}` : 'general-comment-icon-buttons'}
      className={css.GeneralCommentIconButtons}
      style={{
        top: position.top,
        left: position.left,
        zIndex: targetZIndex + 1,
      }}
    >
      {Object
        .keys(generalComments)
        .filter(type => generalComments[type].length > 0)
        .map(type => (
          <GeneralCommentIconButton
            key={type}
            commentMarkType={type}
            comments={generalComments[type]}
            isFlashing={type === flashingType}
            selectComment={selectGeneralComment}
            targetOffset={TARGET_OFFSET}
          />
        ))}
    </div>
  );
};

if (process.env.NODE_ENV !== 'production') {
  GeneralCommentIconButtons.propTypes = {
    compareType: PropTypes.oneOf(['left', 'right']),
    page: PropTypes.shape({
      generalCommentDoneCount: PropTypes.number,
      generalCommentTodoCount: PropTypes.number,
      generalCommentUnmarkedCount: PropTypes.number,
      comments: PropTypes.arrayOf(PropTypes.shape({
        $selected: PropTypes.bool,
        id: PropTypes.string,
        ownerId: PropTypes.string,
        ownerEmail: PropTypes.string,
        comment: PropTypes.string,
        decryptedComment: PropTypes.string,
        isTodo: PropTypes.bool,
        isDone: PropTypes.bool,
      })),
    }),
    position: PropTypes.shape({
      top: PropTypes.number,
      left: PropTypes.number,
    }).isRequired,
    targetZIndex: PropTypes.number,
    getGeneralCommentsByType: PropTypes.func,
    filterName: PropTypes.string,
    commentOrder: PropTypes.shape({
      isReversedCommentOrder: PropTypes.bool,
      orderCommentsBy: PropTypes.string,
    }),
    selectGeneralComment: PropTypes.func,
    flashingType: PropTypes.string,
  };
}

export default GeneralCommentIconButtons;
