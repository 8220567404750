/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Enum } from '@pageproof/sdk';
import classname from 'classname';
import { InlineSVG } from '../InlineSVG';
import Thumbnail from './Thumbnail';
import TileFooter from './TileFooter';
import { Translation } from '../Text';
import Hover from '../Hover';
import ProofWorkflowStatusBar from '../ProofWorkflowStatusBar';
import Tooltip from '../Tooltip';
import { getProofLink } from '../../util/proof-utils';
import styles from './ProofTileStyles.scss';
import ProofTileFileDropper from '../ProofSetup/components/ProofTileFileDropper';
import { goToUrl } from '../../util/location';
import RoleDot from '../RoleDot';

const APPROVED_AND_ARCHIVED = 'approved-and-archived';
const WITH_EDITORS = 'with-editors';
const AWAITING_COLLECTION = 'awaiting-collection';
const AWAITING_EMAIL = 'awaiting-email';
const AWAITING_FILE = 'awaiting-file';

class ProofTile extends Component {
  state = {
    uploadOptionVisible: false,
  };

  getOverlay = (proof) => {
    let overlay = null;
    if (proof.status === Enum.ProofStatus.PROOFING ||
        proof.status === Enum.ProofStatus.FINAL_APPROVING ||
        (proof.state === Enum.ProofState.NOT_STARTED && proof.groupId)) {
      overlay = 'yellow';
    }
    if ((!proof.status && proof.fileStatus !== Enum.FileStatus.RIPPING) ||
          proof.status === Enum.ProofStatus.ARCHIVED) {
      overlay = 'grey';
    }
    if (proof.status === Enum.ProofStatus.TODOS_REQUESTED ||
        proof.status === Enum.ProofStatus.AWAITING_NEW_VERSION ||
        (proof.hasEditors && proof.status !== Enum.ProofStatus.ARCHIVED) ||
        proof.fileStatus === Enum.FileStatus.RIPPING) {
      overlay = 'green';
    }
    if (proof.type === Enum.ProofType.BRIEF && proof.status !== Enum.ProofStatus.ARCHIVED) {
      overlay = 'blue';
    }
    if (!proof.fileId || (proof.fileStatus === Enum.FileStatus.CREATED && proof.source === 'email')) {
      overlay = 'grey';
    }
    return overlay;
  }

  getStatus = (proof) => {
    let status = proof.getState();

    if (status === Enum.ProofState.ARCHIVED && this.hasApproved(proof)) {
      status = APPROVED_AND_ARCHIVED;
    } else if (status !== Enum.ProofState.ARCHIVED && status !== Enum.ProofState.APPROVED && proof.hasEditors) {
      status = WITH_EDITORS;
    } else if (proof.fileStatus === Enum.FileStatus.CREATED && proof.source === 'email') {
      status = AWAITING_EMAIL;
    } else if (status === Enum.ProofState.NOT_STARTED && proof.groupId) {
      status = AWAITING_COLLECTION;
    } else if (!proof.fileId) {
      status = AWAITING_FILE;
    }

    return status;
  }

  hasApproved = proof => !!(proof.approvedDate);

  updateUploadOptionVisibility = (bool) => {
    this.setState({
      uploadOptionVisible: bool,
    });
  }

  getDecisionsSummaryData = (proof) => {
    const { decisionSummary = [] } = proof;
    const currentUser = window.__pageproof_bridge__.userService.getUser();

    const isProofOwner = proof.isOwner(currentUser.id) || (
      proof.teamId === currentUser.teamId &&
      currentUser.isDomainAdmin
    );

    return window.__pageproof_quark__.features.getDecisionSummaryProps({
      workflowId: proof.workflowId,
      currentUserId: currentUser.id,
      decisionSummary,
      availableActions: [
        isProofOwner ? 'nudge-user' : undefined,
        isProofOwner ? 'nudge-all' : undefined,
      ],
      onActionCompleted: () => {
        this.forceUpdate();
      },
    });
  }

  onTileClick = (event, proof) => {
    const { from, onOpenProofInfo, manageItem, isSelectableForBulkAction } = this.props;
    if (
      (
        from === 'team-dashboard' &&
        !proof.isAdminOnProof &&
        (!manageItem || !isSelectableForBulkAction)
      ) ||
      (
        proof.hasFileError() ||
        proof.isProcessing() ||
        proof.fileStatus === 'Created'
      )
    ) {
      event.preventDefault();
      onOpenProofInfo(proof, from);
    } else {
      if (window.__pageproof_quark__.timing.shouldReloadOnNavigation()) {
        return;
      }
      event.preventDefault();
      goToUrl(this.getProofLink(proof));
    }
  }

  getProofLink = (proof) => {
    if (!proof.hasFileError() &&
      !proof.isProcessing() &&
      (this.props.from !== 'team-dashboard' || proof.isAdminOnProof)) {
      return getProofLink(proof);
    }
    return '#';
  }

  render() {
    const {
      uploadOptionVisible,
    } = this.state;
    const {
      proofData,
      url,
      hasUploader,
      footerOptions,
      userId,
      from,
      manageItem,
      onSelectProof,
      view,
      onAllowReupload,
      isSelectableForBulkAction,
      onRefreshCurrentTab,
    } = this.props;

    const proof = proofData.$$sdkProof || proofData;
    const status = this.getStatus(proof);
    const proofType = proof.type === Enum.ProofType.BRIEF ? 'brief' : 'proof';
    const printType = proof.approvedDate ? 'approval' : 'comments';
    const isFromDashboardOrCollection = from === 'dashboard' || from === 'collection';
    const isHidden = from === 'team-dashboard' && !proofData.isAdminOnProof && !proofData.isPublic;
    const editorUserIds = status === WITH_EDITORS
      ? proof.editorUserIds.map(id => ({ userId: id }))
      : null;
    const hasDecisionSummary = (
      (
        proof.status === Enum.ProofStatus.NEW ||
        proof.status === Enum.ProofStatus.PROOFING ||
        proof.status === Enum.ProofStatus.FINAL_APPROVING
      ) &&
      proof.type !== Enum.ProofType.BRIEF &&
      proof.hasDecisionsEnabled
    );
    const { thumbsUpUsers, approvedWithChangesUsers, thumbsDownUsers, noDecisionUsers, noDecisionGroup } = this.getDecisionsSummaryData(proof);

    const isLastStepVisible = (proof.workflowMetrics && proof.workflowMetrics.visibleStepCount) === (proof.workflowMetrics && proof.workflowMetrics.totalStepCount);

    const canSkipAll = window.generalfunctions_canSkipAll(proof.status, isLastStepVisible);

    return (
      <Hover>
        {({ isActive, Target }) => (
          <a
            href={this.getProofLink(proof)}
            onClick={(event) => { this.onTileClick(event, proof); }}
          >
            <Target>
              <div
                className={classname(
                  styles.ProofTile,
                  styles[`ProofTile--${status}`],
                  styles[`ProofTile--${view}`],
                  styles[`ProofTile--${status}__${proofType}`],
                  { [styles[`ProofTile--${view}__visible-option`]]: uploadOptionVisible },
                  { [styles['ProofTile--reupload']]: proofData.allowReupload },
                  { [styles['ProofTile--downloading']]: proofData.$$downloadProgress },
                  { [styles['ProofTile--hovered']]: isActive },
                )}
              >
                {manageItem && isSelectableForBulkAction &&
                  <div
                    className={classname(
                      styles.ProofTile__owned,
                      { [styles['ProofTile__owned--selected']]: proofData.hasSelected },
                    )}
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      onSelectProof();
                    }}
                  >
                    <div>
                      <span>&#10003;</span>
                    </div>
                  </div>
                }
                {proof.role && ![Enum.WorkflowUserRole.REVIEWER, Enum.ProofRole.UNLISTED_REVIEWER, Enum.WorkflowUserRole.VIEW_ONLY].includes(proof.role) &&
                  <div className={styles.ProofTile__role}>
                    <RoleDot
                      userRole={proof.role}
                      tooltipMessage={`dashboard.proof.user-role.${proof.role}.tooltip`}
                    />
                  </div>
                }
                {isHidden &&
                  <Tooltip
                    title={<Translation value="team-dashboard.proof.hidden-eye.info" params={{proofType}} />}
                    up
                    center
                  >
                    <div className={styles['ProofTile__hidden-eye']}>
                      <InlineSVG src="/img/interface/hidepins.svg" />
                    </div>
                  </Tooltip>
                }
                <div className={styles.ProofTile__thumb}>
                  {isFromDashboardOrCollection && (hasUploader || proof.hasFileError() || proof.allowReupload)
                    ? (
                      <ProofTileFileDropper
                        proof={proof}
                        variant={view}
                      />
                    )
                    : <Thumbnail
                      overlay={this.getOverlay(proof)}
                      hasFileError={proof.hasFileError()}
                      hasUploader={hasUploader}
                      url={url}
                      title={proof.name}
                      commentCount={proof.status === Enum.ProofStatus.TODOS_REQUESTED ? proof.todoCommentCount : (proof.commentCount - (proof.privateCount || 0))}
                      isTodos={proof.status === Enum.ProofStatus.TODOS_REQUESTED}
                      variant={view}
                      progressPercent={proofData.getProcessingProgress()}
                      status={status}
                    />
                  }
                  {proof.fileStatus === 'Created' && (
                    <div className={classname(styles.ProofTile__emailOrb, styles[`ProofTile__emailOrb--${view}`])}>
                      <InlineSVG
                        src="/img/icons/email-holo.svg"
                        className={styles.ProofTile__emailOrb__icon}
                      />
                    </div>
                  )}
                </div>
                {proofData.canReupload && !proofData.allowReupload &&
                  <div
                    role="presentation"
                    className={styles['ProofTile__try-reupload']}
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      onAllowReupload();
                    }}
                  >
                    <Translation value="dashboard.proof.button.try-reupload" />
                  </div>
                }
                <div
                  className={classname(
                    styles.ProofTile__title,
                    { [styles['ProofTile__title--editor']]: editorUserIds },
                  )}
                >
                  <p className={styles.ProofTile__title_main}>
                    {proof.version > 0 &&
                      <span className={styles.ProofTile__title_version}>
                        <Translation
                          value="dashboard.proof.version-number"
                          params={{ versionNumber: proof.version }}
                        />
                      </span>
                    }
                    {proof.name}
                  </p>
                  {proof.groupName &&
                    <p className={styles.ProofTile__title_sub}>
                      {proof.groupName}
                    </p>
                  }
                </div>
                <TileFooter
                  proof={proof}
                  footerOptions={footerOptions}
                  proofType={proofType}
                  printType={printType}
                  userId={userId}
                  isActive={isActive}
                  status={status}
                  editorUserIds={editorUserIds}
                />
                {hasDecisionSummary && proof.status > 0 && (
                  <ProofWorkflowStatusBar
                    thumbsUpUsers={thumbsUpUsers}
                    approvedWithChangesUsers={approvedWithChangesUsers}
                    thumbsDownUsers={thumbsDownUsers}
                    noDecisionUsers={noDecisionUsers}
                    noDecisionGroup={noDecisionGroup}
                    currentStepName={proof.workflowMetrics && proof.workflowMetrics.currentStepName}
                    currentStepDueDate={
                      proof.workflowMetrics &&
                      proof.workflowMetrics.currentStepDueDate &&
                      new Date(proof.workflowMetrics.currentStepDueDate)
                    }
                    totalStepCount={proof.workflowMetrics && proof.workflowMetrics.totalStepCount}
                    visibleStepCount={proof.workflowMetrics && proof.workflowMetrics.visibleStepCount}
                    visibleReviewerPercentage={proof.workflowMetrics && proof.workflowMetrics.visibleReviewerPercentage}
                    isOverdue={proof.isOverdue}
                    viewType={view}
                    hovered={isActive}
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                    }}
                    workflowId={proof.workflowId}
                    refreshDecisionSummary={onRefreshCurrentTab}
                    canSkipAll={canSkipAll}
                  />
                )}
              </div>
            </Target>
          </a>
        )}
      </Hover>
    );
  }
}

ProofTile.defaultProps = {
  hasUploader: false,
  manageItem: false,
  footerOptions: {
    info: { enabled: true },
    manage: { enabled: false },
    print: { enabled: false },
    download: { enabled: false },
    lock: { enabled: false },
  },
  view: 'tile',
  url: '',
};

if (process.env.NODE_ENV !== 'production') {
  ProofTile.propTypes = {
    proofData: PropTypes.objectOf(PropTypes.any).isRequired,
    hasUploader: PropTypes.bool,
    onOpenProofInfo: PropTypes.func,
    footerOptions: PropTypes.objectOf(PropTypes.object),
    userId: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
    manageItem: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]),
    onSelectProof: PropTypes.func,
    view: PropTypes.string,
    url: PropTypes.string,
    onAllowReupload: PropTypes.func,
    isSelectableForBulkAction: PropTypes.bool,
  };
}

export default ProofTile;
