/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Translation from '../../components/Text/Translation';
import Dashboard from './Dashboard';
import DashboardTab from './DashboardTab';
import {
  getURL,
  updateTab,
  getRouteParams,
  view,
  setViewInStorage,
  isStaticTab,
  openProofInfo,
  openManageProof,
  isDownloadSupported,
  getSearchTab,
  isCollectionTab,
  setTitle,
  openCollectionManage,
  printProofLegacy,
  goToPath,
} from './ActionsInternal';
import {
  bulkArchive,
  bulkDueDateChange,
  bulkAddEditor,
  loadGroups,
  loadCustomProofList,
  loadCustomGroupList,
  downloadProof,
  cancelDownload,
  removeProofFromGroup,
  addProofToGroup,
  deleteCollection,
  fetchAllProofsForAdmin,
  bulkUpdate,
} from './ActionsSDK';
import DashboardFilters from './DashboardFilters';
import { DASHBOARD_REFRESH_MS, DASHBOARD_LIMIT } from '../../util/constants';
import { sdk } from '../../util/sdk';
import { canSelectForBulkAction } from '../../util/proof-utils';
import { openModal } from '../../util/modal';
import BulkDeleteProofsModal from '../BulkDeleteProofsModal';
import BulkNudgeProofsModal from '../BulkNudgeProofsModal';
import i18n from '../../util/i18n';
import { loadSharedCollections } from '../../util/gql/getSharedCollectionByUserId';

window.addEventListener('focus', updateRefreshEnable);
window.addEventListener('blur', updateRefreshEnable);

const ALLOW_REFRESH_ACTION_TYPES = ['add-workflow', 'set-status-as-awaiting-new-version'];
let IS_REFRESH_ENABLED = true;
function updateRefreshEnable() {
  IS_REFRESH_ENABLED = document.hasFocus();
}

class DashboardContainer extends Component {
  constructor(props) {
    super(props);

    const routeParams = getRouteParams();
    this.proofId = routeParams.proofId;
    this.groupId = routeParams.groupId;
    this.replacingFile = Number(routeParams.replacingFile) === 1;

    this.state = {
      isManageMode: false,
      manageItem: null,
      collectionLoadRef: {
        current: {
          isLoading: false,
          onceLoaded: false,
        },
        archived: {
          isLoading: false,
          onceLoaded: false,
        },
      },
      exportProgress: null,
      selectedTab: routeParams.tab || 'inbox',
      selectedFilters: [],
      selectedDateFilters: [],
      orderBy: null,
      reverseOrder: false,
      search: '',
      searchWithinTabProofs: {},
      view: view(),
      thumbnails: {},
      proofs: {},
      finalProofs: [],
      selectableProofs: [],
      tabs: {
        inbox: props.from === 'dashboard' ? DashboardTab.inbox() : DashboardTab.teamInbox(1),
        sent: props.from === 'dashboard' ? DashboardTab.sent() : DashboardTab.teamSent(1),
        outbox: props.from === 'dashboard' ? DashboardTab.outbox() : DashboardTab.teamOutbox(1),
        approved: props.from === 'dashboard' ? DashboardTab.approved() : DashboardTab.teamApproved(1),
        brief: DashboardTab.brief(),
        everything: props.from === 'dashboard' ? DashboardTab.everything() : DashboardTab.teamEverything(1),
      },
      sharedGroups: [],
      sharedGroupsLoaded: false,
      groupId: routeParams.groupId,
    };

    this.bridge = window.__pageproof_bridge__;
    this.returnUrl = getURL();

    this.user = props.user;
    this.bridge.DataService.resetMultipleFile();

    this.refreshInterval = 0;
    this.$$refreshing = false;

    this.selectedProofs = [];
    this.groups = [];
    this.fileIdBufferArray = [];
    this.collectionLoadPromise = {};
    this.isCSVDownloadCancelled = false;
    this.listeners = [];

    this.userDomain = this.user.email.substring(this.user.email.indexOf('@'));

    const userDomainHash = (window.__md5 && window.__md5(this.userDomain)) || '';
    const briefingDomainsHashes = (window.__pageproof_of__ && window.__pageproof_of__.briefing) || [];

    this.canShowBrief = (this.user.type === 1 || this.user.type === 2) &&
      briefingDomainsHashes.indexOf(userDomainHash) !== -1 &&
      props.from === 'dashboard';

    this.dashboardStorage = this.bridge.storageService('pageproof.app.' + props.from + '.');
    this.state.orderBy = this.dashboardStorage.get('order-by');
    const localDateFilters = this.dashboardStorage.json('date-filters') || [];
    this.state.selectedDateFilters = localDateFilters.map(dateFilter => ({
      startDate: new Date(dateFilter.startDate),
      endDate: new Date(dateFilter.endDate),
      type: dateFilter.type,
    }));

    this.state.reverseOrder = this.dashboardStorage.json('reverse-order') || false;

    this.tabStorage = this.bridge.storageService('pageproof.app.' + props.from + '.tabs.' + this.user.id + '.');
    this._deleteTextTabsFromTabStorage();
    this.tabStorageArray = this.getTabStorageArray();
    this.tabStorageIndex = (this.tabStorageArray.length) ? this.tabStorageArray[this.tabStorageArray.length - 1] : 0;

    this.uploadProofOptions = {
      dropbox: true,
      drive: true,
      box: true,
      onedrive: true,
    };
  }

  componentDidMount() {
    i18n.on('did-load-locale-translations', this.updateTitle);
    this.initWatchers();
    this.addStoredTabs()
      .then(() => {
        this.init();
      });
    this.enableDashboardRefresh();
  }

  componentWillUnmount() {
    i18n.off('did-load-locale-translations', this.updateTitle);
    this.unregisterEvents();
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  getSearchProps() {
    const { tabs, selectedTab, search, selectedFilters, orderBy, reverseOrder, exportProgress, selectedDateFilters } = this.state;

    let placeholderTranslationKey = 'dashboard.search.search-proofs';
    let extendedPlaceholderTranslationKey = 'dashboard.search.search-proofs.extended';

    if (isCollectionTab(selectedTab)) {
      placeholderTranslationKey = 'dashboard.search.search-collection';
      extendedPlaceholderTranslationKey = 'dashboard.search.search-collection.extended';
    }

    return {
      $$loading: tabs[selectedTab] ? tabs[selectedTab].loading : false,
      search,
      placeholderTranslationKey,
      extendedPlaceholderTranslationKey,
      selectedTab,
      selectedFilters,
      selectedDateFilters,
      filters: DashboardFilters.filters,
      onSearchChange: this.updateSearch,
      toggleFilter: this.toggleFilter,
      toggleDateFilter: this.toggleDateFilter,
      onOrderByChange: this.updateOrderBy,
      orderBy,
      onReverseOrderChange: this.updateReverseOrder,
      reverseOrder,
      onExport: this.exportDashboard,
      onCancelExport: this.cancelCSVDownload,
      exportProgress,
      onClearFilters: this.onClearFilters,
      onResetOrder: this.onResetOrder,
    };
  }

  getUploaderText() {
    const { selectedTab } = this.state;
    // eslint-disable-next-line no-nested-ternary
    return isCollectionTab(selectedTab)
      ? 'collection'
      : selectedTab === 'brief'
        ? 'brief'
        : 'proof';
  }

  getUploaderProps() {
    const {
      selectedTab,
    } = this.state;
    return {
      uploadProofOptions: this.uploadProofOptions,
      isTile: this.state.view === 'tile',
      returnUrl: this.returnUrl,
      proofType: (selectedTab === 'brief') ? 'brief' : 'proof',
      uploaderText: this.getUploaderText(),
      isMultipleSelect: selectedTab !== 'brief' && !isCollectionTab(selectedTab),
      selectedTab,
      groupId: isCollectionTab(selectedTab) ? (this.groupId || selectedTab) : null,
      groupName: isCollectionTab(selectedTab) ? (this.state.tabs[selectedTab] && this.state.tabs[selectedTab].groupName) : null,
    };
  }

  getNavigationProps() {
    return {
      selectedTab: this.state.selectedTab,
      view: this.state.view,
      tabData: this.state.tabs,
      isNewUser: this.isNewUser(),
      collectionLoadRef: this.state.collectionLoadRef,
      showBrief: this.canShowBrief,
      onLoadCollections: this.loadAllCollections,
      onRemove: this.removeStoredTab,
      onRemoveAll: this.removeAllStoredTabs,
      onToggle: this.toggleView,
      onSelect: this.selectTab,
      onSelectGroupTab: this.goToGroupTab,
      onDrop: this.handleDrop,
      onCollectionCreate: this.handleCollectionCreate,
      onCollectionRemove: this.handleDeleteCollection,
      onCollectionManage: (tab) => {
        const groupId = tab === 'group' ? this.groupId : tab;
        openCollectionManage(groupId);
      },
      manage: {
        proofCount: this.selectedProofs.length,
        showManagePane: this.state.isManageMode,
        label: 'dashboard.manage.heading',
        tip: 'dashboard.manage.heading.tip',
        options: [
          {
            id: 'compare',
            label: 'dashboard.manage.compare.label',
            confirmLabel: 'dashboard.manage.compare.confirm-label',
            buttonLabel: 'dashboard.manage.compare.button',
            cancelButton: 'button.cancel',
            validCount: 2,
            onClick: this.goToCompare,
          },
          {
            id: 'nudge',
            label: 'dashboard.manage.nudge.label',
            confirmLabel: 'dashboard.manage.nudge.confirm-label',
            buttonLabel: 'dashboard.manage.nudge.button',
            cancelButton: 'button.cancel',
            onClick: ({ message }) => this.bulkNudge(message),
            messageEnabled: true,
          },
          {
            id: 'archive',
            label: 'dashboard.manage.archive.label',
            confirmLabel: 'dashboard.manage.archive.confirm-label',
            buttonLabel: 'dashboard.manage.archive.button',
            cancelButton: 'button.cancel',
            onClick: () => this.bulkArchive(),
          },
          {
            id: 'delete',
            label: 'dashboard.manage.delete.label',
            confirmLabel: 'dashboard.manage.delete.confirm-label',
            buttonLabel: 'dashboard.manage.delete.button',
            cancelButton: 'button.cancel',
            buttonVariant: 'danger',
            onClick: () => this.bulkDelete(),
          },
          {
            id: 'add-owner',
            label: 'dashboard.manage.add-owner.label',
            confirmLabel: 'dashboard.manage.add-owner.confirm-label',
            buttonLabel: 'dashboard.manage.add-owner.button',
            buttonLabelMultiple: 'dashboard.manage.add-owners.button',
            cancelButton: 'button.cancel',
            onClick: ({ emails }) => this.bulkUpdate({ emails }, 'add-owner'),
            emailEnabled: true,
          },
          {
            id: 'add-workflow',
            label: 'dashboard.manage.add-workflow.label',
            confirmLabel: 'dashboard.manage.add-workflow.confirm-label',
            buttonLabel: 'dashboard.manage.add-workflow.button',
            cancelButton: 'button.cancel',
            onClick: ({ workflowId }) => this.bulkUpdate({ workflowId }, 'add-workflow'),
            workflowChooserEnabled: true,
          },
          {
            id: 'replace-approver',
            label: 'dashboard.manage.replace-approver.label',
            confirmLabel: 'dashboard.manage.replace-approver.confirm-label',
            buttonLabel: 'dashboard.manage.replace-approver.button',
            cancelButton: 'button.cancel',
            onClick: ({ email }) => this.bulkUpdate({ email }, 'replace-approver'),
            emailEnabled: true,
          },
          {
            id: 'add-reviewer',
            label: 'dashboard.manage.add-reviewer.label',
            confirmLabel: 'dashboard.manage.add-reviewer.confirm-label',
            buttonLabel: 'dashboard.manage.add-reviewer.button',
            buttonLabelMultiple: 'dashboard.manage.add-reviewers.button',
            cancelButton: 'button.cancel',
            onClick: ({ emails }) => this.bulkUpdate({ emails }, 'add-reviewer'),
            emailEnabled: true,
          },
          {
            id: 'due-date',
            label: 'dashboard.manage.due-date.label',
            confirmLabel: 'dashboard.manage.due-date.confirm-label',
            buttonLabel: 'dashboard.manage.due-date.button',
            cancelButton: 'button.cancel',
            onClick: ({ date }) => this.bulkDueDateChange(date),
            dateEnabled: true,
          },
          {
            id: 'message-to-reviewers',
            label: 'dashboard.manage.message-to-reviewers.label',
            confirmLabel: 'dashboard.manage.message-to-reviewers.confirm-label',
            buttonLabel: 'dashboard.manage.message-to-reviewers.button',
            cancelButton: 'button.cancel',
            onClick: ({ messageToReviewers }) => this.bulkUpdate({ messageToReviewers }, 'message-to-reviewers'),
            messageToReviewersEnabled: true,
          },
          {
            id: 'add-editor',
            label: 'dashboard.manage.add-editor.label',
            confirmLabel: 'dashboard.manage.add-editor.confirm-label',
            buttonLabel: 'dashboard.manage.add-editor.button',
            cancelButton: 'button.cancel',
            onClick: ({ email, message }) => this.bulkAddEditor(email, message),
            emailEnabled: true,
            messageEnabled: true,
          },
          {
            id: 'set-status-as-awaiting-new-version',
            label: 'dashboard.manage.set-status-as-awaiting-new-version.label',
            confirmLabel: 'dashboard.manage.set-status-as-awaiting-new-version.confirm-label',
            buttonLabel: 'dashboard.manage.set-status-as-awaiting-new-version.button',
            cancelButton: 'button.cancel',
            onClick: () => this.bulkUpdate({}, 'set-status-as-awaiting-new-version'),
          },
        ],
        onToggle: () => !this.state.manageItem && this.toggleManageMode(),
        onUpdateManageItem: this.updateManageItem,
      },
    };
  }

  getTabProofsProps() {
    const { from, user } = this.props;
    const isDashboard = from === 'dashboard';
    const { tabs, selectedTab, searchWithinTabProofs, manageItem, selectedDateFilters, selectableProofs } = this.state;
    const limit = tabs[selectedTab] && isDashboard ? tabs[selectedTab].limit : -1;
    const selectableProofIds = selectableProofs.map(proof => proof.id);

    return {
      uploadProofOptions: this.uploadProofOptions,
      footerOptions: {
        info: { enabled: true, onClick: proof => openProofInfo(proof, from, user.id) },
        collection: {
          enabled: true,
          viewLink: {
            enabled: !isCollectionTab(selectedTab),
            onClick: groupId => this.goToUrl('dashboard/group?groupId=' + groupId),
          },
          onManageOpen: groupId => openCollectionManage(groupId),
          onRemove: proofId => removeProofFromGroup(proofId)
            .then(() => {
              this.refreshCurrentTab();
            }),
          onCopy: groupId => window.generalfunction_copyToClipboard(`${window.location.origin}/dashboard/group?groupId=${groupId}`),
        },
        manage: { enabled: true, onClick: proof => openManageProof(proof, user.id) },
        print: { enabled: isDashboard, onPrint: proof => this.presentPrintOptions(proof), onPrintLegacy: (proof, type) => printProofLegacy(proof, type) },
        download: {
          enabled: isDashboard && isDownloadSupported(),
          onClick: (proof) => {
            if (!proof.$$downloadProgress) {
              this.updateDownloadProgress(proof, 0.1);
              return downloadProof(proof, this.updateDownloadProgress);
            } else {
              this.updateDownloadProgress(proof, null);
              return cancelDownload();
            }
          },
        },
        lock: { enabled: false },
      },
      returnUrl: this.returnUrl,
      proofIds: this.state.tabs[this.state.selectedTab] ? this.state.tabs[this.state.selectedTab].proofIds : [],
      allProofs: this.state.proofs,
      allThumbnails: this.state.thumbnails,
      view: this.state.view,
      manageItem,
      limit,
      userId: user.id,
      selectableProofIds,
      onDeferVisibility: proof => this.autoQueueThumbnail(proof),
      onOpenProofInfo: proof => openProofInfo(proof, from, user.id),
      onSelectProof: proof => this.selectProof(proof),
      onAllowReupload: proof => this.allowReupload(proof),
      orderBy: this.state.orderBy,
      reverseOrder: this.state.reverseOrder,
      search: searchWithinTabProofs,
      dateFilters: selectedDateFilters.concat([]),
      didSearch: proofs => this.setFinalProofs(proofs),
      onRefreshCurrentTab: () => this.refreshCurrentTab(),
    };
  }

  getPaginationProps() {
    const { tabs, selectedTab } = this.state;
    return {
      total: tabs[selectedTab] ? tabs[selectedTab].proofCount : 0,
      count: tabs[selectedTab] ? tabs[selectedTab].proofIds.length : 0,
      isLoading: tabs[selectedTab] ? tabs[selectedTab].loading : false,
      onPageChange: this.changePage,
      resetPagination: selectedTab,
      isPaginationEnabled: tabs[selectedTab] ? tabs[selectedTab].isPaginationEnabled : true,
    };
  }

  getShowMoreProps() {
    const { tabs, selectedTab } = this.state;
    const limit = tabs[selectedTab] ? tabs[selectedTab].limit : 0;
    const totalProofs = tabs[selectedTab] ? tabs[selectedTab].proofIds.length : 0;
    return {
      showAll: limit < totalProofs,
      showMore: limit < totalProofs,
      onShowMore: () => this.showMore(selectedTab),
      onShowAll: () => this.showAll(selectedTab, totalProofs),
    };
  }

  getTabStorageArray() {
    return this.tabStorage.keys().sort((a, b) => a - b);
  }

  getUploaderTooltipTitle() {
    if (this.state.selectedTab === 'brief') {
      return <Translation value="dashboard.proof.options.create-new-brief" />;
    }

    if (isCollectionTab(this.state.selectedTab)) {
      return <Translation value="dashboard.proof.options.create-new-proof-in-collection" />;
    }

    return <Translation value="dashboard.proof.options.create-new-proof" />;
  }

  setTitle(tab) {
    const tabData = this.state.tabs[tab];
    setTitle(tabData);
  }

  onProofLoadError = (responseStatus, tabId) => {
    switch (responseStatus) {
    case 'ERROR_GROUP_NO_PROOFS_FOUND': {
      this.removeItemFromCollectionTab(tabId);
      break;
    }

    default: {
      console.debug('There is some error during loading proofs for dashboard', responseStatus);
    }
    }
  }

  getThumbnails = window.debounce(() => {
    const fileIds = this.fileIdBufferArray;
    this.fileIdBufferArray = [];
    sdk.files.thumbnails(fileIds, {
      async: true,
      onLoadThumbnails: (filesData) => {
        this.updateThumbnails(filesData);
      },
      onLoadError: (err) => {
        console.log('File not found :', err);
      },
    })
      .then((filesData) => {
        this.updateThumbnails(filesData);
      });
  }, 100);

  setFinalProofs = (proofs) => {
    this.updateState({
      type: 'SET_ITEMS',
      data: [{
        key: 'finalProofs',
        value: proofs,
      }],
      callback: () => {
        this.updateSelectableProofs();
      },
    });
  }

  loadAll = () => {
    this.$$pendingAll = true;
    Promise.all([
      this.loadTab('inbox'),
      this.loadTab('sent'),
      this.loadTab('outbox'),
    ]).then(() => {
      this.$$pendingAll = false;
    });
  }

  loadTab = (name) => {
    const tab = this.state.tabs[name];
    switch (name) {
    case 'proof': {
      return loadCustomProofList('load', this.proofId)
        .then((proof) => {
          // eslint-disable-next-line no-param-reassign
          proof.allowReupload = proof.canReupload && this.replacingFile;
          this.updateProofOrGroupTab(name, proof.name, [proof], null);
        });
    }
    case 'group': {
      return loadCustomGroupList(this.groupId, this.props.from)
        .then((group) => {
          this.updateProofOrGroupTab(
            name,
            group.name,
            group.proofs,
            {
              canAddProofs: group.canAddProofs,
              isEmpty: group.isEmpty,
              groupName: group.name,
              getTitle: () => group.name,
            },
          );
        });
    }
    default:
      if (tab && !tab.isPending) {
        this.updateState({
          type: 'SET_LOADING',
          tab: name,
        });
        // tab.togglePending(true);
        return tab.request()
          .then(({ proofs, count, addedData, error }) => {
            // tab.togglePending(false);
            if (addedData) {
              this.updateState({
                type: 'UPDATE_TAB',
                tab: name,
                tabData: { ...addedData },
              });
            }
            if (error) {
              this.onProofLoadError(error, tab.id);
            }
            if (proofs) {
              this.updateState({
                type: 'SET_PROOFS',
                tab: name,
                proofs,
                count,
                callback: () => {
                  this.updateSelectableProofs();
                },
              });
            }
          });
      }
    }
    return false;
  }

  loadCollections = (type) => {
    if (!this.collectionLoadPromise[type]) {
      this.updateState({
        type: 'UPDATE_GROUP_LOAD_REF',
        key: type,
        value: {
          isLoading: true,
        },
      });
      this.collectionLoadPromise[type] = loadGroups(type, this.props.from)
        .then((groups) => {
          this.updateState({
            type: 'UPDATE_GROUP_LOAD_REF',
            key: type,
            value: {
              isLoading: false,
              onceLoaded: true,
            },
          });
          this.groups = [...this.groups, ...groups];
          this.createCollectionTabs(groups);
        });
    }
  }

  changePage = (page) => {
    const { tabs, selectedTab } = this.state;
    tabs[selectedTab].changePage(page);
    this.loadTab(selectedTab);
  }

  // todo replace with UPDATE_PROOF
  updateDownloadProgress = (proof, downloadProgress) => {
    const proofData = proof;
    proofData.$$downloadProgress = downloadProgress;
    this.updateState({
      type: 'UPDATE_PROOFS',
      proofs: [proofData],
    });
  }

  allowReupload = (proof) => {
    if (proof.canReupload) {
      // eslint-disable-next-line no-param-reassign
      proof.allowReupload = true;
      this.updateState({
        type: 'UPDATE_PROOF',
        proof,
      });
    }
  }

  getFileName = () => {
    const { tabs, selectedTab } = this.state;
    return tabs[selectedTab].getTitle();
  }

  cancelCSVDownload = () => {
    cancelDownload();
    this.isCSVDownloadCancelled = true;
    this.setState({
      exportProgress: null,
    });
    this.processed = false;
  }

  exportDashboard = () => {
    let promise = Promise.resolve();
    if (this.processed) {
      return;
    }

    let { finalProofs } = this.state;
    this.isCSVDownloadCancelled = false;
    this.processed = true;
    const fileName = this.getFileName();
    if (this.props.from === 'team-dashboard') {
      promise = fetchAllProofsForAdmin(this.state.tabs[this.state.selectedTab], (progress) => {
        this.setState({
          exportProgress: progress,
        });
      })
        .then((data) => {
          finalProofs = data;
          this.setState({
            exportProgress: null,
          });
        });
    }

    promise
      .then(() => {
        this.processed = false;
        this.downloadCSV(fileName, finalProofs);
      });
  }

  autoQueueThumbnail = (proof) => {
    const { thumbnails } = this.state;
    // eslint-disable-next-line no-prototype-builtins
    if (!thumbnails.hasOwnProperty(proof.fileId)) {
      this.fileIdBufferArray.push(proof.fileId);
    }
    this.getThumbnails();
  }

  isNewUser = () => {
    const { tabs } = this.state;
    return !this.props.user.lastLogin &&
    (tabs.inbox && !tabs.inbox.proofIds.length) &&
    (tabs.outbox && !tabs.outbox.proofIds.length) &&
    (tabs.sent && !tabs.sent.proofIds.length);
  }

  toggleManageMode = () => {
    this.updateState({
      type: 'SET_ITEMS',
      data: [{
        key: 'isManageMode',
        value: !this.state.isManageMode,
      }],
      callback: () => {
        if (!this.state.isManageMode) {
          this.resetSelectedProofs();
        }
      },
    });
  }

  updateManageItem = (item) => {
    this.updateState({
      type: 'SET_ITEMS',
      data: [{
        key: 'manageItem',
        value: item,
      }],
      callback: () => {
        if (!item) {
          this.resetSelectedProofs();
        } else {
          this.updateSelectableProofs();
        }
      },
    });
  }

  resetSelectedProofs = () => {
    const { proofs } = this.state;
    Object.keys(proofs).forEach((id) => {
      proofs[id].hasSelected = false;
    });
    this.selectedProofs = [];
    this.setSelectableProofs([]);
  }

  updateSelectableProofs = () => {
    const { finalProofs, manageItem } = this.state;
    const selectableProofs = [];
    finalProofs.forEach((proof) => {
      if (canSelectForBulkAction(proof, sdk.session.user, manageItem, this.props.from)) {
        selectableProofs.push(proof);
      }
    });

    this.setSelectableProofs(selectableProofs);
  }

  getSelectedProofsCountInSelectedTab = () => {
    const { proofs, selectedTab, tabs } = this.state;
    let selectedProofsCount = 0;
    const proofIds = tabs[selectedTab] ? tabs[selectedTab].proofIds : [];
    proofIds.forEach((proofId) => {
      if (proofs[proofId] && proofs[proofId].hasSelected) {
        selectedProofsCount++; // eslint-disable-line no-plusplus
      }
    });
    return selectedProofsCount;
  }

  setSelectableProofs = (selectableProofs) => {
    this.updateState({
      type: 'SET_ITEMS',
      data: [{
        key: 'selectableProofs',
        value: selectableProofs,
      }],
    });
  }

  toggleSelectionOfAllProofs = () => {
    const { selectableProofs } = this.state;
    const canSelectAllProofs = this.getSelectedProofsCountInSelectedTab() < selectableProofs.length;
    selectableProofs.forEach((proof) => {
      proof.hasSelected = canSelectAllProofs; // eslint-disable-line
      if (canSelectAllProofs) {
        if (this.selectedProofs.indexOf(proof.id) === -1) {
          this.selectedProofs.push(proof.id);
        }
      } else {
        this.selectedProofs.splice(this.selectedProofs.indexOf(proof.id), 1);
      }
    });
    this.updateState({
      type: 'UPDATE_PROOFS',
      proofs: selectableProofs,
    });
  }

  toggleView = (newView) => {
    this.updateState({
      type: 'SET_ITEMS',
      data: [{
        key: 'view',
        value: newView,
      }],
    });
    setViewInStorage(newView);
  }

  updateOrderBy = (orderBy) => {
    if (orderBy === 'auto') {
      orderBy = null; // eslint-disable-line no-param-reassign
    }
    this.updateState({
      type: 'SET_ITEMS',
      data: [{
        key: 'orderBy',
        value: orderBy,
      }],
      callback: () => {
        if (orderBy) {
          this.dashboardStorage.set('order-by', orderBy);
        } else {
          this.dashboardStorage.remove('order-by');
        }
      },
    });
    // if (orderBy === 'comment-count' && !this.state.reverseOrder) {
    //   this.updateReverseOrder();
    // }
  }

  updateReverseOrder = () => {
    this.updateState({
      type: 'SET_ITEMS',
      data: [{
        key: 'reverseOrder',
        value: !this.state.reverseOrder,
      }],
      callback: () => {
        this.dashboardStorage.set('reverse-order', this.state.reverseOrder);
      },
    });
  }

  handleDrop = (proof, id) => {
    const groupId = id === 'group' ? this.groupId : id;
    addProofToGroup(proof.id, groupId)
      .then(() => {
        this.refreshCurrentTab();
      });
  }

  handleCollectionCreate = (newGroup) => {
    const newCollection = { ...newGroup, canAddProofs: true };
    this.createCollectionTabs([newCollection])
      .then(() => {
        this.goToGroupTab(newCollection.id);
      });
  }

  goToGroupTab = (groupId) => {
    this.groupId = groupId;
    this.bridge.$location.path('/dashboard/group').search({ groupId }).ignore();
    this.addProofOrGroupTab('group');
    const elem = document.getElementsByClassName('VerticalTab--selected');
    elem[0].scrollIntoView(false);
  }

  goToUrl = (url) => {
    this.bridge.$location.url(url);
    this.bridge.$rootScope.$apply();
  }

  goToCompare = () => {
    const { selectedProofs } = this;
    const url = `proof/compare/${selectedProofs[0]}/${selectedProofs[1]}`;
    this.goToUrl(url);
  }

  handleDeleteCollection = (tab) => {
    const groupId = tab === 'group' ? this.groupId : tab;
    deleteCollection(groupId)
      .then(() => {
        this.removeItemFromCollectionTab(groupId);
        if (tab === 'group' || this.groupId === tab) {
          this.removeItemFromCollectionTab('group');
        }
      });
  }

  selectTab = (tab) => {
    const { tabs } = this.state;
    if (tabs[tab]) {
      this.updateState({
        type: 'SET_ITEMS',
        data: [{
          key: 'selectedTab',
          value: tab,
        },
        {
          key: 'searchWithinTabProofs',
          value: {},
        }],
        callback: () => {
          updateTab(tab, this.props.from);
          this.populateTabData(tab);
          this.loadTab(tab);
          this.setTitle(tab);
          this.scrollToTop();
        },
      });
    }
  }

  selectProof = (proof) => {
    const { manageItem } = this.state;
    const canSelectMoreProofs = (manageItem !== 'compare' || this.selectedProofs.length < 2);
    if (!proof.hasSelected && canSelectMoreProofs) {
      // eslint-disable-next-line no-param-reassign
      proof.hasSelected = true;
      if (this.selectedProofs.indexOf(proof.id) === -1) {
        this.selectedProofs.push(proof.id);
      }
    } else if (proof.hasSelected) {
      // eslint-disable-next-line no-param-reassign
      proof.hasSelected = false;
      this.selectedProofs.splice(this.selectedProofs.indexOf(proof.id), 1);
    }
    this.updateState({
      type: 'UPDATE_PROOF',
      proof,
    });
  }

  bulkNudge = (message) => {
    const selectedProofsObject = this.selectedProofs.map(id => ({
      name: this.state.proofs[id].name,
      workflowId: this.state.proofs[id].workflowId,
    }));

    const bulkNudgeProofsModal = openModal(
      <BulkNudgeProofsModal
        proofs={selectedProofsObject}
        nudgeMessage={message}
        onClose={() => {
          bulkNudgeProofsModal.destroy();
          this.refreshCurrentTab();
          this.resetSelectedProofs();
        }}
      />,
      null,
      false,
    );
  }

  bulkArchive = () => {
    if (this.selectedProofs.length) {
      // temporary removing selected proofs from tab.proofs
      this.updateState({
        type: 'UPDATE_PROOFIDS',
        tab: this.state.selectedTab,
        proofIds: this.selectedProofs,
      });
      return bulkArchive(this.selectedProofs)
        .then(() => {
          this.refreshCurrentTab();
          this.resetSelectedProofs();
        });
    }
    return false;
  }

  bulkDelete = () => {
    const selectedProofsObject = this.selectedProofs.map(id => ({
      id,
      name: this.state.proofs[id].name,
    }));

    const bulkDeleteProofsModal = openModal(
      <BulkDeleteProofsModal
        proofs={selectedProofsObject}
        onClose={() => {
          bulkDeleteProofsModal.destroy();
          this.refreshCurrentTab();
        }}
      />,
      null,
      false,
    );
  }

  bulkUpdate = (data, type) => {
    const selectedIds = type === 'replace-approver' // This endpoint works with workflowIds unlike other bulk endpoints
      ? this.getSelectedWorkflowIds()
      : this.selectedProofs;
    return bulkUpdate(data, selectedIds, type)
      .then(() => {
        this.resetSelectedProofs();
        if (ALLOW_REFRESH_ACTION_TYPES.includes(type)) {
          this.refreshCurrentTab();
        }
      });
  }

  getSelectedWorkflowIds = () => {
    const { proofs } = this.state;
    const workflowIds = [];
    this.selectedProofs.forEach((proofId) => {
      const proof = proofs[proofId];
      if (proof) {
        workflowIds.push(proof.workflowId);
      }
    });
    return workflowIds;
  }

  bulkDueDateChange = (date) => {
    const { dateService } = window.__pageproof_bridge__;
    const formattedDate = dateService.parse(date);
    const proofsWithDueDate = [];
    this.selectedProofs.forEach((id) => {
      proofsWithDueDate.push({
        id,
        dueDate: formattedDate,
      });
    });
    return bulkDueDateChange(proofsWithDueDate)
      .then(() => {
        this.resetSelectedProofs();
        this.refreshCurrentTab();
      });
  }

  bulkAddEditor = (email, messageToEditors) => {
    const proofsWithEditors = [];
    this.selectedProofs.forEach((proofId) => {
      proofsWithEditors.push({
        proofId,
        editors: [{ email }],
        messageToEditors,
      });
    });
    return bulkAddEditor(proofsWithEditors)
      .then(() => {
        this.resetSelectedProofs();
        this.refreshCurrentTab();
      });
  }

  shouldSearch = () => this.state.search || this.state.selectedFilters.length;

  onClearFilters = () => {
    const { selectedTab } = this.state;
    this.updateState({
      type: 'SET_ITEMS',
      data: [{
        key: 'selectedFilters',
        value: [],
      }, {
        key: 'selectedDateFilters',
        value: [],
      }],
      callback: () => {
        this.updateLocalDateFilters([]);
        if (this.shouldSearch() && !isCollectionTab(selectedTab)) {
          this.updateNavigationPanel();
        } else if (!isStaticTab(selectedTab)) {
          this.removeStoredTab(selectedTab);
        }
      },
    });
  }

  onResetOrder = () => {
    this.updateState({
      type: 'SET_ITEMS',
      data: [{
        key: 'orderBy',
        value: null,
      }, {
        key: 'reverseOrder',
        value: false,
      }],
      callback: () => {
        this.dashboardStorage.remove('order-by');
        this.dashboardStorage.set('reverse-order', false);
      },
    });
  }

  toggleFilter = (filter) => {
    const { selectedFilters, selectedTab, search } = this.state;
    const filterIndex = selectedFilters.indexOf(filter);
    const hasFilter = filterIndex !== -1;
    if (hasFilter) {
      selectedFilters.splice(filterIndex, 1);
    } else {
      selectedFilters.push(filter);
    }
    this.updateState({
      type: 'SET_ITEMS',
      data: [{
        key: 'selectedFilters',
        value: selectedFilters,
      }],
      callback: () => {
        if (this.shouldSearch() && !isCollectionTab(selectedTab)) {
          this.updateNavigationPanel();
        } else if (isCollectionTab(selectedTab)) {
          const searchWithinTabProofs = {
            text: search,
            filters: this.getFilterBy(selectedFilters),
          };
          this.updateState({
            type: 'SET_ITEMS',
            data: [{
              key: 'searchWithinTabProofs',
              value: searchWithinTabProofs,
            }],
          });
        } else {
          this.removeStoredTab(selectedTab);
        }
      },
    });
  }

  toggleDateFilter = (dateFilterType, startDate, endDate) => {
    const { selectedDateFilters } = this.state;
    const newDateFilter = {
      type: (startDate && endDate ? dateFilterType : null),
      startDate,
      endDate,
    };

    const filterIndex = selectedDateFilters.indexOf(selectedDateFilters.find(dateFilter => dateFilter.type === dateFilterType));
    const hasFilter = filterIndex !== -1;
    if (hasFilter) {
      selectedDateFilters.splice(filterIndex, 1);
    }
    if (startDate && endDate) {
      selectedDateFilters.push(newDateFilter);
    }
    this.updateState({
      type: 'SET_ITEMS',
      data: [{
        key: 'selectedDateFilters',
        value: selectedDateFilters,
      }],
      callback: () => {
        this.updateLocalDateFilters(selectedDateFilters);
      },
    });
  }

  updateLocalDateFilters = (dateFilters) => {
    this.dashboardStorage.json('date-filters', dateFilters);
  }

  updateSearch = (search) => {
    const { selectedTab, selectedFilters } = this.state;
    this.updateState({
      type: 'SET_ITEMS',
      data: [{
        key: 'search',
        value: search,
      }],
      callback: () => {
        if (search && !isCollectionTab(selectedTab)) {
          this.updateNavigationPanel();
        } else {
          const searchWithinTabProofs = {
            text: search,
            filters: this.getFilterBy(selectedFilters),
          };
          this.updateState({
            type: 'SET_ITEMS',
            data: [{
              key: 'searchWithinTabProofs',
              value: searchWithinTabProofs,
            }],
          });
        }
      },
    });
  }

  updateStorageTab = (proofCount = null, tabData = null) => {
    const { selectedTab, search, selectedFilters } = this.state;
    const tab = (tabData) ? tabData.id : selectedTab;
    if (!isStaticTab(tab) && tab === selectedTab) {
      this.tabStorage.set(tab, JSON.stringify({
        label: getSearchTab(search, selectedFilters),
        searchKeyword: search,
        filters: selectedFilters,
        proofCount,
      }));
      if (proofCount === null) {
        this.updateTabsObj(tab);
      }
    }
  }

  getFilterBy = filters => filters.map((id) => {
    const filter = DashboardFilters[id];
    return filter && filter.code;
  }).filter(code => code).join(',');

  removeStoredTab = (id) => {
    const tabId = id || this.tabStorageIndex;
    this.tabStorage.remove(tabId);
    delete this.state.tabs[tabId];
    this.destroySearch();
  }

  removeAllStoredTabs = () => {
    const storedTabArray = this.getTabStorageArray();
    let i = 0;
    // eslint-disable-next-line no-plusplus
    for (i; i < storedTabArray.length; i++) {
      this.removeStoredTab(storedTabArray[i]);
    }
  }

  enableDashboardRefresh = () => {
    this.refreshInterval = setInterval(() => {
      const selectedTab = this.state.tabs[this.state.selectedTab];
      const allow = selectedTab.allowScheduledRefresh &&
                    selectedTab.endpoint !== 'search' &&
                    !this.state.isManageMode;
      if (this.props.from === 'dashboard' && IS_REFRESH_ENABLED && allow) { // no 30 seconds refresh cycle on team-dashboard
        this.refreshCurrentTab();
      }
    }, DASHBOARD_REFRESH_MS);
  }

  updateCollectionTab = (groupId, tabData) => {
    const tab = this.state.tabs[groupId];
    if (tab) {
      this.updateState({
        type: 'UPDATE_TAB',
        tab: groupId,
        tabData,
      });
    }

    // Update selected collection tab view
    const { selectedTab } = this.state;
    if (selectedTab === 'group' && this.groupId === groupId) {
      this.updateState({
        type: 'UPDATE_TAB',
        tab: 'group',
        tabData,
      });
      if (tabData.isArchived) {
        this.refreshCurrentTab();
      }
    }
  }

  loadAllCollections = () => {
    this.loadCollections('current');
    this.loadCollections('archived');
  }

  updateTitle = () => {
    this.setTitle(this.state.selectedTab);
    const { $rootScope } = window.__pageproof_bridge__;
    if (!$rootScope.$$phase) {
      $rootScope.$apply();
    }
  }

  loadSharedCollectionsByUserId = () => {
    const { id: userId } = this.user;

    if (userId) {
      loadSharedCollections(userId).then((sharedGroups) => {
        this.setState({ sharedGroups, sharedGroupsLoaded: true });
      });
    }
  };


  unregisterEvents() {
    this.listeners.forEach((listener) => {
      listener();
    });
  }

  presentPrintOptions(proof) {
    this.props.showDialog('print', { proofId: proof.id, proofType: proof.type });
  }

  downloadCSV(fileName, proofs) {
    if (!this.isCSVDownloadCancelled) {
      const csvObj = [];
      const getStatus = state => (state === 'active') ? 'in-proofing' : state; // eslint-disable-line
      proofs.forEach((proof) => {
        csvObj.push({
          Name: proof.name,
          Version: proof.version,
          'File Type': proof.fileExtension,
          'Collection Name': proof.groupName || '',
          'Creation Date': moment(proof.createdDate).format('LLLL'),
          'Due Date': moment(proof.dueDate).format('LLLL'),
          Comments: proof.commentCount,
          'No. of To-dos': proof.todoCommentCount,
          'No. of Dones': proof.doneCommentCount,
          Status: Translation.text(`dashboard.proof.state.${getStatus(proof.getState())}`, { dueDate: moment(proof.dueDate).format('LLLL') }),
          'Approved Date': proof.approvedDate ? moment(proof.approvedDate).format('LLLL') : '',
        });
      });

      const csvFile = window.generalfunctions_csv(csvObj);
      // eslint-disable-next-line no-undef
      window.downloadFile(`${fileName}.csv`, new Blob([csvFile]));
    }
  }

  initWatchers() {
    this.listeners.push(
      this.bridge.$rootScope.$on('collection.archived', (event, data) => this.updateCollectionTab(data.groupId, { isArchived: data.isArchived })),
      this.bridge.$rootScope.$on('collection.reopened', (event, data) => this.updateCollectionTab(data.groupId, { isArchived: data.isArchived })),
      this.bridge.$rootScope.$on('collection.name', (event, data) => this.updateCollectionTab(data.groupId, { label: data.name, groupName: data.name })),
      this.bridge.$rootScope.$on('collection.folder', (event, data) => this.updateCollectionTab(data.groupId, { folder: data.folder })),
      this.bridge.$rootScope.$on('collection.nudged', () => this.refreshCurrentTab()),
      this.bridge.proofInfoService.on('close', () => {
        if (!this.isUserInManageMode()) {
          this.refreshCurrentTab();
        }
      }),
    );
  }

  isUserInManageMode() {
    return this.state.manageItem;
  }

  showMore(tab) {
    const { tabs } = this.state;
    const limit = tabs[tab] ? tabs[tab].limit + DASHBOARD_LIMIT : 0;
    const tabData = { limit };
    this.updateState({
      type: 'UPDATE_TAB',
      tab,
      tabData,
    });
  }

  showAll(tab, total) {
    const tabData = { limit: total };
    this.updateState({
      type: 'UPDATE_TAB',
      tab,
      tabData,
    });
  }

  updateThumbnails(filesData) {
    const { thumbnails } = this.state;

    Object.keys(filesData).forEach((key) => {
      thumbnails[key] = URL.createObjectURL(filesData[key]);
    });

    this.updateState({
      type: 'SET_THUMBNAILS',
      thumbnails,
    });
  }

  updateProofOrGroupTab(tab, label, proofs, addedData) {
    const display = label.length > 100 ? label.substr(0, 100) + '...' : label;
    this.updateState({
      type: 'UPDATE_TAB',
      tab,
      tabData: { label: display, ...addedData },
      callback: () => {
        this.updateState({
          type: 'SET_PROOFS',
          tab,
          proofs,
          count: proofs.length,
        });
        this.updateTitle();
      },
    });
  }

  scrollToTop() {
    this.bridge.domService.scrollTo('.proof-dashboard', 300, -100);
  }

  populateTabData(tab) {
    const tabData = JSON.parse(this.tabStorage.get(tab));
    let search;
    let selectedFilters;
    if (tabData) {
      search = tabData.searchKeyword;
      selectedFilters = tabData.filters;
    } else if (isStaticTab(tab)) {
      search = '';
      selectedFilters = [];
    }
    this.updateState({
      type: 'SET_ITEMS',
      data: [
        { key: 'search', value: search },
        { key: 'selectedFilters', value: selectedFilters },
      ],
    });
  }

  storeSingleTab(index, storedTab) {
    const page = this.props.from === 'team-dashboard' ? 1 : null;
    const isTeam = this.props.from === 'team-dashboard';
    const searchTab = new DashboardTab(index, storedTab.label, () => storedTab.label, 'search', false, isTeam, page);
    searchTab.search = storedTab.searchKeyword;
    searchTab.filters = this.getFilterBy(storedTab.filters);
    searchTab.proofCount = storedTab.proofCount || 0;
    this.updateState({
      type: 'ADD_TAB',
      tab: index,
      tabData: searchTab,
    });
  }

  addNewTabToTabs(index) {
    return new Promise((resolve) => {
      const storedTab = JSON.parse(this.tabStorage.get(index));
      if (storedTab && storedTab.label) {
        this.storeSingleTab(index, storedTab);

        // to do update proof count after laod tab method call
      }
      resolve(true);
    });
  }

  storeNewSearchTab() {
    const { search, selectedFilters } = this.state;
    return new Promise((resolve) => {
      // eslint-disable-next-line no-plusplus
      this.tabStorage.set(++this.tabStorageIndex, JSON.stringify({
        label: getSearchTab(search, selectedFilters),
        searchKeyword: search,
        filters: selectedFilters,
      }));
      this.addNewTabToTabs(this.tabStorageIndex); // show this newly added tabbed search in menu bar
      resolve(this.tabStorageIndex);
    });
  }

  updateNavigationPanel() {
    if (isStaticTab(this.state.selectedTab)) {
      this.storeNewSearchTab()
        .then((newTab) => {
          this.selectTab(newTab);
        });
    } else {
      this.updateStorageTab();
    }
  }

  updateTabsObj(index) {
    if (!isStaticTab(index) && index !== 'search') {
      const { search, selectedFilters, tabs } = this.state;
      const tabData = {
        label: getSearchTab(search, selectedFilters),
        search,
        filters: this.getFilterBy(selectedFilters),
        proofCount: 0,
      };
      tabs[index].updateSearchData(tabData.search, tabData.filters);
      this.updateState({
        type: 'UPDATE_TAB',
        tab: index,
        tabData,
        callback: () => {
          this.selectTab(index);
        },
      });
    }
  }

  addStoredTabs() {
    const searches = [];
    return new Promise((resolve) => {
      this.tabStorageArray.forEach((index) => {
        const storedTab = JSON.parse(this.tabStorage.get(index));
        if (storedTab && storedTab.label) {
          if (searches.indexOf(storedTab.searchKeyword) === -1) {
            searches.push(storedTab.searchKeyword);
            this.storeSingleTab(index, storedTab);
          } else {
            this.removeStoredTab(index);
          }
        }
      });
      resolve(true);
    });
  }

  _deleteTextTabsFromTabStorage() {
    this.tabStorage.keys().forEach((key) => {
      // eslint-disable-next-line no-restricted-globals
      if (isNaN((key))) {
        this.tabStorage.remove(key);
      }
    });
  }

  destroySearch() {
    this.setState({
      search: '',
      selectedFilters: [],
    });
    if (this.bridge.$location.path() !== '/dashboard/group') {
      this.selectTab('inbox');
    }
  }

  createCollectionTabs(groupArr) {
    return new Promise((resolve) => {
      const tabsData = groupArr.reduce((tabs, group) => {
        const collectionTab = new DashboardTab(group.id, group.name, () => group.name, 'group', false, false, 0, false);
        collectionTab.isCollection = true;
        collectionTab.isArchived = group.isArchived;
        collectionTab.groupId = group.id;
        collectionTab.canAddProofs = group.canAddProofs;
        collectionTab.isEmpty = group.isEmpty;
        collectionTab.groupName = group.name;
        collectionTab.folder = group.folder;
        return {
          ...tabs,
          [collectionTab.groupId]: collectionTab,
        };
      }, {});
      this.updateState({
        type: 'ADD_BULK_TABS',
        tabsData,
      });
      resolve(true);
    });
  }

  removeItemFromCollectionTab(groupId) {
    const index = this.groups.findIndex(group => group.id === groupId);
    this.groups.splice(index, 1);
    delete this.state.tabs[groupId];
    if (groupId === 'group') {
      this.selectTab('inbox'); // Go to inbox if selected collection got deleted
    }
  }

  goToCollectionTab(tabId) {
    const tab = tabId || (this.groups.length ? this.groups[0].id : 'inbox');
    this.selectTab(tab);
    const elem = document.getElementsByClassName('VerticalTab--selected');
    elem[0].scrollIntoView();
  }

  refreshCurrentTab() {
    if (!this.$$refreshing) {
      this.$$refreshing = true;
      const request = this.loadTab(this.state.selectedTab);

      if (request) {
        request.then(() => {
          this.$$refreshing = false;
        });
      }
    }
  }

  updateState(action) {
    const state = this.state;
    switch (action.type) {
    case 'SET_ITEMS': {
      this.setState(prevState => ({
        ...prevState,
        // eslint-disable-next-line no-param-reassign
        ...action.data.map(item => prevState[item.key] = item.value),
      }), () => {
        if (action.callback) {
          action.callback();
        }
      });
      break;
    }
    case 'SET_THUMBNAILS': {
      this.setState(prevState => ({
        ...prevState,
        thumbnails: action.thumbnails,
      }));
      break;
    }
    case 'ADD_TAB': {
      this.setState(prevState => ({
        ...prevState,
        tabs: {
          ...prevState.tabs,
          [action.tab]: action.tabData,
        },
      }), () => {
        if (action.callback) {
          action.callback();
        }
      });
      break;
    }
    case 'ADD_BULK_TABS': {
      this.setState(prevState => ({
        ...prevState,
        tabs: {
          ...prevState.tabs,
          ...action.tabsData,
        },
      }), () => {
        if (action.callback) {
          action.callback();
        }
      });
      break;
    }
    case 'UPDATE_TAB': {
      this.setState(prevState => ({
        ...prevState,
        tabs: {
          ...prevState.tabs,
          [action.tab]: {
            ...prevState.tabs[action.tab],
            ...action.tabData,
          },
        },
      }), () => {
        if (action.callback) {
          action.callback();
        }
      });
      break;
    }
    case 'UPDATE_PROOF': {
      this.setState(prevState => ({
        ...prevState,
        proofs: {
          ...prevState.proofs,
          [action.proof.id]: action.proof,
        },
      }));
      break;
    }
    case 'UPDATE_GROUP_LOAD_REF': {
      this.setState(prevState => ({
        ...prevState,
        collectionLoadRef: {
          ...prevState.collectionLoadRef,
          [action.key]: action.value,
        },
      }));
      break;
    }
    case 'UPDATE_PROOFIDS': {
      this.setState(prevState => ({
        ...prevState,
        tabs: {
          ...prevState.tabs,
          [action.tab]: {
            ...prevState.tabs[action.tab],
            proofIds: prevState.tabs[action.tab].proofIds.filter(id => action.proofIds.indexOf(id) === -1),
          },
        },
      }));
      break;
    }
    case 'SET_LOADING': {
      this.setState(prevState => ({
        ...prevState,
        tabs: {
          ...prevState.tabs,
          [action.tab]: {
            ...prevState.tabs[action.tab],
            loading: true,
          },
        },
      }));
      break;
    }
    case 'UPDATE_PROOFS': {
      this.setState(prevState => ({
        ...prevState,
        proofs: {
          ...prevState.proofs,
          ...action.proofs.reduce((allProofs, proof) => {
            // eslint-disable-next-line no-param-reassign
            allProofs[proof.id] = proof;
            return allProofs;
          }, {}),
        },
      }));
      break;
    }
    case 'SET_PROOFS': {
      this.setState(prevState => ({
        ...prevState,
        tabs: {
          ...prevState.tabs,
          [action.tab]: {
            ...prevState.tabs[action.tab],
            loading: false,
            onceLoaded: true,
            lastUpdated: new Date(),
            proofIds: action.proofs.map(proof => proof.id),
            proofCount: action.count || 0,
          },
        },
        proofs: prevState.selectedTab === action.tab
          ? {
            ...prevState.proofs,
            ...action.proofs.reduce((allProofs, proof) => {
              if (prevState.proofs[proof.id]) {
                // eslint-disable-next-line no-param-reassign
                proof.hasSelected = prevState.proofs[proof.id].hasSelected;
              }
              // eslint-disable-next-line no-param-reassign
              allProofs[proof.id] = proof;
              return allProofs;
            }, {}),
          }
          : { ...prevState.proofs },
      }), () => {
        if (action.callback) {
          action.callback();
        }
      });
      break;
    }
    default: {
      return state;
    }
    }
    return state;
  }

  addProofOrGroupTab(tab) {
    const proofIds = tab === 'proof' ? [this.proofId] : [];
    const loadingTab = new DashboardTab(tab, <Translation value="dashboard.tab.loading" />, () => Translation.text('dashboard.tab.loading'), '');
    loadingTab.proofIds = proofIds;
    loadingTab.proofs = [];

    this.updateState({
      type: 'ADD_TAB',
      tab,
      tabData: loadingTab,
      callback: () => {
        this.selectTab(tab);
      },
    });
  }

  groupOwned() {
    const { selectedTab, tabs } = this.state;
    return tabs[selectedTab] ? tabs[selectedTab].canAddProofs : null;
  }

  init() {
    this.loadAll();
    this.loadSharedCollectionsByUserId();

    const { selectedTab } = this.state;
    if (['inbox', 'sent', 'outbox'].indexOf(selectedTab) === -1) {
      this.selectTab(selectedTab);
    }
    if (selectedTab === 'proof' && this.proofId) { // if a proof id is given in url
      this.addProofOrGroupTab('proof');
    } else if (selectedTab === 'group' && this.groupId) { // if a groupId is given in url
      this.addProofOrGroupTab('group');
    } else if (isCollectionTab(selectedTab)) {
      this.bridge.$location.path('/dashboard/group').search({ groupId: selectedTab });
    } else if ((!this.proofId && selectedTab === 'proof') ||
      (!this.groupId && selectedTab === 'group')) {
      this.selectTab('inbox');
    }
    this.bridge.DataService.clearData();
  }

  render() {
    const {
      navigation,
      from,
      color,
      ...props
    } = this.props;

    const { selectedTab, tabs, finalProofs, manageItem, selectableProofs } = this.state;

    const navigationProps = this.getNavigationProps();
    const tabProofsProps = this.getTabProofsProps();
    const isStatic = isStaticTab(selectedTab);
    const noProofs = tabs[selectedTab] ? tabs[selectedTab].proofIds.length === 0 : false;
    const noProofsWithFilter = finalProofs ? !finalProofs.length : false;
    const isLoading = tabs[selectedTab] ? tabs[selectedTab].loading && !this.$$refreshing : false;
    const onceLoaded = tabs[selectedTab] ? tabs[selectedTab].onceLoaded : false;
    const canShowUploader = this.user.features.createNewProofs &&
    ((isStatic && selectedTab !== 'proof' && !isCollectionTab(selectedTab)) ||
    (isCollectionTab(selectedTab) && (this.groupOwned() || !!this.state.sharedGroups.some(group => group.id === this.state.groupId)))) &&
    onceLoaded;

    return (
      <Dashboard
        {...props}
        searchProps={this.getSearchProps()}
        navigation={navigationProps}
        tabProofs={tabProofsProps}
        paginationProps={this.getPaginationProps()}
        uploaderProps={this.getUploaderProps()}
        showMoreProps={this.getShowMoreProps()}
        color={color}
        title="dashboard.header"
        from={from}
        selectedTab={selectedTab}
        isCollectionTab={isCollectionTab(selectedTab)}
        isStaticTab={isStatic}
        noProofs={noProofs}
        noProofsWithFilter={noProofsWithFilter}
        isLoading={isLoading}
        canShowUploader={canShowUploader}
        onceLoaded={onceLoaded}
        userId={this.user.id}
        manageItem={manageItem}
        selectedProofsCount={this.getSelectedProofsCountInSelectedTab()}
        selectableProofsCount={selectableProofs.length}
        onToggleSelectionOfAllProofs={this.toggleSelectionOfAllProofs}
        uploaderTooltipTitle={this.getUploaderTooltipTitle()}
        goToPath={goToPath}
      />
    );
  }
}

if (process.env.NODE_ENV !== 'production') {
  DashboardContainer.propTypes = {
    user: PropTypes.objectOf(PropTypes.any).isRequired,
  };
}

export default DashboardContainer;
