/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import PropTypes from 'prop-types';
import React, { forwardRef, Fragment, useContext, useState } from 'react';
import SideBarTheme from '../../hoc/Contexts/SideBarTheme';
import AddBlankCollection from '../AddBlankCollection';
import { sdk } from '../../util/sdk';
import RevealingPlus from '../RevealingPlus';
import { CollapsibleSearch } from '../Search';
import css from './CollectionDashboardOptions';


const CollectionDashboardOptions = ({
  onCollectionCreate,
  onSearch,
  from,
  goToPath,
}, ref) => {
  const [canRevealAddCollection, setCanRevealAddCollection] = useState(false);
  const [isSearchBarShown, setSearchBarShown] = useState(false);
  const theme = useContext(SideBarTheme);

  return (
    <Fragment>
      <div className={css.CollectionDashboardOptions}>
        {!isSearchBarShown && !!sdk.session.user.teamId &&
          <RevealingPlus
            theme={theme}
            textToReveal="collection.tab.add-new"
            onClick={() => {
              if (from === 'team-dashboard') {
                goToPath('/team/collections');
              } else {
                setCanRevealAddCollection(!canRevealAddCollection);
              }
            }}
          />
        }
        <CollapsibleSearch
          theme={theme}
          ref={ref}
          onToggleDisplay={setSearchBarShown}
          onSearch={onSearch}
          canClose
          placeholder="collection.placeholder.search-collection-name"
        />
      </div>
      <AddBlankCollection
        color={theme === 'green' ? 'grey' : 'green'}
        onAction={(data) => {
          onCollectionCreate(data);
          setCanRevealAddCollection(false);
        }}
        canShow={canRevealAddCollection}
        onCancel={() => setCanRevealAddCollection(false)}
      />
    </Fragment>
  );
};

const CollectionDashboardOptionsWithRef = forwardRef(CollectionDashboardOptions);

if (process.env.NODE_ENV !== 'production') {
  CollectionDashboardOptions.propTypes = {
    onCollectionCreate: PropTypes.func,
    onSearch: PropTypes.func,
    from: PropTypes.string,
    goToPath: PropTypes.func,
  };
}

export default CollectionDashboardOptionsWithRef;
