/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Search } from '../Search';
import InputOptions from '../ProofSetup/components/InputOptions';

const InputOptionsSearchSelector = ({ options, onSelect }) => {
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <InputOptions
      maxHeight="max(calc(100vh - 220px), 200px)"
      options={
        <div style={{ maxWidth: 'max(calc(100vw - 450px), 450px)' }}>
          <Search
            autoFocus
            searchTerm={searchTerm}
            onSearch={value => setSearchTerm(value)}
          />
          {options.filter(option => option.name.toLowerCase().includes(searchTerm.toLowerCase()))
            .map(option => (
              <InputOptions.Option
                key={option.id}
                label={option.name}
                onClick={() => onSelect(option)}
              />
            ))}
        </div>
      }
    />
  );
};

if (process.env.NODE_ENV !== 'production') {
  InputOptionsSearchSelector.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
    onSelect: PropTypes.func.isRequired,
  };
}

export default InputOptionsSearchSelector;
