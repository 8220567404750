/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState } from 'react';
import { InlineSVG } from '../InlineSVG';
import Tooltip from '../Tooltip';
import { useStitchEmbed } from '../../hooks/useStitchEmbed';
import useEventListener from '../../hooks/useEventListener';
import css from './ProofChecklistMobile.scss';

const ProofChecklistMobile = ({ checklistId, canBeOpen, onChecklistUpdated }) => {
  const [isVisible, setIsVisible] = useState(true);

  const ProofChecklistStitch = useStitchEmbed('ProofChecklist', {
    iframeProps: {
      style: {
        zIndex: 99999998,
        width: window.innerWidth,
      },
    },
    observeHeight: true,
  });

  const [maxHeight, setMaxHeight] = useState(window.innerHeight - 70);

  useEventListener(window, 'resize', () => {
    setMaxHeight(window.innerHeight - 70);
  });

  return (
    <ProofChecklistStitch.Eager
      checklistId={checklistId}
      onChecklistUpdated={onChecklistUpdated}
      onClose={() => setIsVisible(false)}
      onOpen={() => setIsVisible(true)}
      maxHeight={maxHeight}
      onBeforeEmbedUpdatesPosition={({ inlineStyles, isInitialPosition }) => ({
        inlineStyles: {
          ...inlineStyles,
          transition: !isInitialPosition && canBeOpen ? 'opacity 250ms ease-in-out' : '',
          opacity: isVisible && canBeOpen && !isInitialPosition ? 1 : 0,
        },
      })}
    >
      {ProofChecklistStitchPlacement => (
        <div className={css.ProofChecklistMobile}>
          <Tooltip
            delay={0}
            zIndex={99999997}
            visible={isVisible && canBeOpen}
            popover
            title={(
              <ProofChecklistStitchPlacement
                style={{
                  width: window.innerWidth,
                  pointerEvents: 'none',
                }}
              />
            )}
            variant="light"
            center
            arrow
            animateDisappear={canBeOpen}
            offset={8}
            padding={false}
            maxWidth={false}
            up
          >
            <button
              type="button"
              className="page__proof__toolbar__button page__proof__toolbar__button__icon-checklist page__proof__toolbar__button--checklist-button"
              onClick={() => setIsVisible(prevIsVisible => !prevIsVisible)}
              aria-label="Checklist"
            >
              <InlineSVG
                src="img/interface/checklist.svg"
                className={css.ProofFooterChecklistIcon__icon}
              />
            </button>
          </Tooltip>
        </div>
      )}
    </ProofChecklistStitch.Eager>
  );
};

export default ProofChecklistMobile;
