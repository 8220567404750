/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-disable */

import React, { Fragment, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useSynchronizedUrl } from '../../hooks/useSynchronizedUrl';
import CollectionsDashboard from '../../components/CollectionsDashboard';

export default ({ initialRouteParams }) => {
  const [hasInitialized, setHasInitialized] = useState(false);
  const [filter, setFilter] = useState(initialRouteParams.filter || 'active');
  const [collectionId, setCollectionId] = useState(initialRouteParams.collectionId || null);

  useSynchronizedUrl(['/team/collections', collectionId], { filter });

  return (
    <Fragment>
      {hasInitialized && (
        <style>{`
          header {
            background: transparent;
            backdrop-filter: none;
            -webkit-backdrop-filter: none;
            border-bottom: 0;
          }
        `}</style>
      )}
      <CollectionsDashboard
        collectionId={collectionId}
        filterState={filter}
        onFilterStateChange={(filterState) => {
          unstable_batchedUpdates(() => {
            setFilter(filterState);
            setCollectionId(null);
          });
        }}
        onSelectCollection={(collectionId) => {
          setCollectionId(collectionId);
        }}
        onNewCollection={(collectionId) => {
          unstable_batchedUpdates(() => {
            setFilter('active');
            setCollectionId(collectionId);
          });
        }}
        onInit={() => {
          setHasInitialized(true);
        }}
      />
    </Fragment>
  );
};
