import React from 'react';
import ReactInlineSvg from 'react-inlinesvg';

export function InlineSVG({ className, ...props }) {
  return (
    <div
      className={className}
      style={{ display: 'inline' }}
    >
      <ReactInlineSvg
        {...props}
      />
    </div>
  );
}
