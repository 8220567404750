/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, {Fragment} from 'react';
import { InlineSVG } from '../InlineSVG';
import Translation from '../Text/Translation';
import TodosMessage from './components/TodosMessage';
import DecisionSummaryContainer from '../../containers/DecisionSummaryContainer';
import CommentStatusCountBar from './CommentStatusCountBar';
import css from './ModalMessage.scss';
import MarkDone from '../Comment/Icon/MarkDone';

function OwnerTodosRequestedModalMessage({
  hasDecisionsEnabled,
  commentsDoneStatus: popupData,
  decisionSummary,
}) {
  const {
    todoComments,
    doneComments,
    unmarkedCount,
    message,
  } = popupData;
  return (
    <Fragment>
      <h1><Translation value="proof.dialog.owner-todos-requested.title" /></h1>
      {hasDecisionsEnabled &&
        <div className={css.ModalMessage__decisionSummary}>
          <DecisionSummaryContainer
            {...decisionSummary}
            hasBorder
          />
        </div>
      }
      {message &&
        <TodosMessage data={popupData} />
      }
      <h2 className={css['ModalMessage__sub-heading']}>
        {<Translation value="proof.dialog.what-todo-next" />}
      </h2>
      <Translation
        value="proof.dialog.owner-todos-requested.mark-comment"
        params={{
          icon: <MarkDone active size={14} readOnly />,
        }}
      />
      <br />
      <Translation
        value={`proof.dialog.owner-todos-requested.${todoComments ? 'send-message' : 'upload'}`}
        params={{
          icon: (
            <div className={css.ModalMessage__uploadIcon}>
              <InlineSVG
                src="/img/icons/file-dropper.svg"
              />
            </div>
          ),
        }}
      />
      <CommentStatusCountBar
        todos={todoComments}
        dones={doneComments}
        notMarked={unmarkedCount}
        showTotal
      />
    </Fragment>
  );
}

export default OwnerTodosRequestedModalMessage;
