/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classname from 'classname';
import { InlineSVG } from '../InlineSVG';
import styles from './ManageTab.scss';
import { TranslatedProps, Translation, withTranslations } from '../Text';
import { Button } from '../Button';
import Textarea from '../Textarea';
import FormControl from '../FormControl';
import AddressBookControl from '../AddressBookControl';
import { INITIAL_PROOF_DUE } from '../../util/constants';
import { getFormattedDate } from '../../util/date-time-utils';
import WorkflowChooser from '../ProofSetup/components/WorkflowChooser';
import DateTimePickerIconButton from '../DateTimePickerIconButton';
import ConfirmBox from '../ConfirmBox';
import ModalServiceBridge from '../ModalServiceBridge';
import MultiEmailField from '../MultiEmailField/MultiEmailField';
import TextareaWithTools from '../TextareaWithTools';
import InputOptions from '../ProofSetup/components/InputOptions';
import { getUserPreferences } from '../../services/userPreferences';

class ManageTab extends Component {
  state = {
    showConfirm: false,
    isActionButtonClicked: false,
    validEmail: false,
    email: '',
    emails: [],
    message: '',
    messageToReviewers: '',
    dueDate: null,
    showConfirmPopUp: false,
    workflowId: null,
    isLoadingWorkflow: false,
  };

  messageToReviewersRef = React.createRef();

  // eslint-disable-next-line consistent-return
  handleClick = () => {
    if (this.state.showConfirm) {
      if (this.props.tabData.id === 'compare') {
        this.doBulkAction();
      } else {
        this.toggleConfirmPopUp(true);
      }
    } else {
      this.props.onToggle(this.props.id);
      this.toggleConfirmSlide();
    }
  }

  toggleConfirmSlide = () => {
    this.setState(({ showConfirm }) => ({
      showConfirm: !showConfirm,
      isActionButtonClicked: showConfirm,
    }));
  }

  doBulkAction = () => {
    const email = this.props.tabData.emailEnabled ? this.state.email : null;
    const emails = this.props.tabData.emailEnabled ? this.state.emails : null;
    const message = this.props.tabData.messageEnabled ? this.state.message : null;
    const messageToReviewers = this.props.tabData.messageToReviewersEnabled ? this.state.messageToReviewers : null;
    const date = this.props.tabData.dateEnabled ? this.state.dueDate : null;
    const workflowId = this.props.tabData.workflowChooserEnabled ? this.state.workflowId : null;
    this.props.tabData.onClick({ email, emails, message, date, workflowId, messageToReviewers });
    this.props.onCloseManagePanel();
    this.props.onToggle();
    this.toggleConfirmPopUp(false);
    this.toggleConfirmSlide();
    this.setState({
      email: '',
      emails: [],
      messageToReviewers: '',
    });
  }

  toggleConfirmPopUp = (bool) => {
    this.updateInput('showConfirmPopUp', bool);
  }

  updateEmail = (email) => {
    this.setState({
      email,
      validEmail: window.validateEmail(email),
    });
  }

  updateEmails = (emails) => {
    this.setState({
      emails,
      validEmail: emails.length,
    });
  }

  updateInput = (key, value) => {
    this.setState({
      [key]: value,
    });
  }

  cancel = () => {
    this.setState({
      showConfirm: false,
      email: '',
      emails: [],
      messageToReviewers: '',
      dueDate: null,
      workflowId: null,
    });
    this.props.onToggle();
  }

  formatDateTime = () => {
    const { dueDate } = this.state;
    const formattedDate = getFormattedDate(dueDate || INITIAL_PROOF_DUE);

    this.setState({
      dueDate: dueDate ? formattedDate : null,
    });
  };

  render() {
    const {
      proofCount: count,
      tabData,
      hide,
      validCount,
      color,
    } = this.props;

    const {
      showConfirm,
      isActionButtonClicked,
      email,
      emails,
      validEmail,
      message,
      messageToReviewers,
      dueDate,
      showConfirmPopUp,
      workflowId,
    } = this.state;

    const confirmLabelType = (
      // eslint-disable-next-line no-nested-ternary
      !count
        ? 'zero'
        : count === 1
          ? 'single'
          : 'multi'
    );

    return (
      <nav
        className={classname(
          styles.ManageTab,
          styles[`ManageTab--${color}`],
          { [styles['ManageTab__show-confirm']]: showConfirm },
          { [styles.ManageTab__hide]: hide }
        )}
      >
        <a
          onClick={this.handleClick}
          className={styles.ManageTab__tab}
        >
          <Translation value={tabData.label} />
          {tabData.tip &&
            <span className={styles.ManageTab__tip}>
              <Translation value={tabData.tip} />
            </span>
          }
          <span className={styles.ManageTab__arrow}>
            <InlineSVG src="img/interface/arrow-down.svg" />
          </span>
        </a>
        <div className={styles['ManageTab__confirm-box']}>
          <p>
            <Translation
              value={tabData.confirmLabel + '.' + confirmLabelType}
              params={{ count }}
            />
          </p>

          {tabData.emailEnabled && count > 0 && (
            (tabData.id === 'add-reviewer' || tabData.id === 'add-owner') ? (
              <MultiEmailField
                emails={emails}
                updateEmails={this.updateEmails}
                color={color === 'green' ? 'white' : 'grey'}
              />
            ) : (
              <div className={styles.ManageTab__addressBook}>
                <TranslatedProps placeholder={`dashboard.manage.${tabData.id}.email.placeholder`}>
                  <AddressBookControl
                    type="text"
                    value={email}
                    onChange={this.updateEmail}
                  />
                </TranslatedProps>
              </div>
            )
          )}
          {tabData.messageEnabled && count > 0 &&
            <FormControl>
              <Textarea
                placeholder={Translation.text(`dashboard.manage.${tabData.id}.message.placeholder`)}
                type="text"
                value={message}
                onChange={val => this.updateInput('message', val)}
              />
            </FormControl>
          }

          {tabData.dateEnabled && count > 0 &&
            <div className={styles.ManageTab__date}>
              <FormControl>
                <span className={styles.ManageTab__date__calender}>
                  <DateTimePickerIconButton
                    onChange={date => this.updateInput('dueDate', moment(date).format('h:mma, Do MMMM YYYY'))}
                    onBlur={this.formatDateTime}
                    className={styles['ManageTab__date__calender-icon']}
                  />
                </span>
                <Textarea
                  type="text"
                  value={dueDate}
                  placeholder={Translation.text(`dashboard.manage.${tabData.id}.date.placeholder`)}
                  onBlur={this.formatDateTime}
                  onChange={date => this.updateInput('dueDate', date)}
                />
              </FormControl>
            </div>
          }
          {tabData.messageToReviewersEnabled && count > 0 &&
            <div className={styles.ManageTab__message}>
              <FormControl>
                <TextareaWithTools
                  tools={
                    <InputOptions
                      color={color === 'green' ? 'overGreen' : 'overGrey'}
                      options={
                        <React.Fragment>
                          <InputOptions.Option
                            label={<Translation value="dashboard.manage.message-to-reviewers.use-my-default" />}
                            onClick={() => {
                              getUserPreferences().then((userPreferences) => {
                                this.setState({ messageToReviewers: userPreferences.messageToReviewers.value });
                              });
                            }}
                          />
                          <InputOptions.Separator />
                          <InputOptions.Option
                            label={<Translation value="button.clear" />}
                            onClick={() => {
                              this.setState({ messageToReviewers: '' });
                              this.messageToReviewersRef.current.focus();
                            }}
                          />
                        </React.Fragment>
                      }
                    />
                  }
                  placeholder={Translation.text(`dashboard.manage.${tabData.id}.placeholder`)}
                  ref={this.messageToReviewersRef}
                  variant="compact"
                  value={messageToReviewers}
                  onChange={val => this.updateInput('messageToReviewers', val)}
                />
              </FormControl>
            </div>
          }

          {tabData.workflowChooserEnabled && count > 0 &&
            <div className={classname(styles.ManageTab__workflowChooser,
              ['ManageTab__workflowChooser__dropdown'])}
            >
              <WorkflowChooser.WorkflowsDropdown
                isEdited={false}
                currentWorkflowId={workflowId}
                canCreateNew={false}
                onWorkflow={({ workflow }) => this.updateInput('workflowId', workflow.id)}
                defaultSelectedWorkflowName={<Translation value="proof.setup.workflow.chooser.add-workflow" />}
                isLoadingWorkflow={this.state.isLoadingWorkflow}
                setIsLoadingWorkflow={bool => this.setState({ isLoadingWorkflow: bool })}
              />
            </div>
          }

          {count >= validCount &&
            <Button
              className={classname(styles['ManageTab__action-button'],
                styles[`ManageTab__action-button--${color}`])}
              variant={tabData.buttonVariant || (
                color === 'green'
                  ? 'overColor'
                  : 'outline'
              )}
              size="small"
              disabled={(
                isActionButtonClicked ||
                (tabData.emailEnabled && !validEmail) ||
                (tabData.dateEnabled && !dueDate) ||
                (tabData.workflowChooserEnabled && !workflowId)
              )}
              label={<Translation value={emails.length > 1 ? tabData.buttonLabelMultiple : tabData.buttonLabel} />}
              onClick={this.handleClick}
            />
          }
          <Button
            className={styles['ManageTab__cancel-button']}
            variant="text"
            label={<Translation value={tabData.cancelButton} />}
            onClick={this.cancel}
          />
        </div>
        <ModalServiceBridge
          componentName="ConfirmBox"
          isModalVisible={showConfirmPopUp}
          className={styles.ManageTab__confirmPopup}
          heading="dashboard.manage.confirm-popup.heading"
          subHeading="dashboard.manage.confirm-popup.sub-heading"
          message={`dashboard.manage.confirm-popup.message.${tabData.id}`}
          buttons={[
            {
              ...ConfirmBox.CancelButton,
              onClick: () => this.toggleConfirmPopUp(false),
            },
            {
              id: 'action-button',
              type: 'orange',
              label: 'button.yes-i-am-sure',
              activeLabel: 'button.yes-i-am-sure',
              onClick: () => this.doBulkAction(),
            },
          ]}
          params={{ count, user: email, users: emails.join(', ') }}
          onClose={() => this.toggleConfirmPopUp(false)}
        />
      </nav>
    );
  }
}

ManageTab.defaultProps = {
  validCount: 1,
  hide: false,
  color: '',
};

if (process.env.NODE_ENV !== 'production') {
  ManageTab.propTypes = {
    id: PropTypes.string.isRequired,
    proofCount: PropTypes.number.isRequired,
    validCount: PropTypes.number,
    tabData: PropTypes.objectOf(PropTypes.any),
    onCloseManagePanel: PropTypes.func,
    onToggle: PropTypes.func,
    hide: PropTypes.bool,
    color: PropTypes.string,
  };
}

export default withTranslations(ManageTab);
