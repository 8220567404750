/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { getProofLink } from '../../util/proof-utils';

const bridge = window.__pageproof_bridge__;

const storage = () => bridge.storageService('pageproof.app.dashboard.');

function getURL() {
  return bridge.$location.url();
}

const goToPath = (path) => {
  window.location.href = path;
};

const updateTab = (tab, from) => {
  if (tab) {
    const path = `/${from}/${tab}`;
    if (bridge.$location.path() !== path) {
      bridge.$location.path(path).ignore();
    }
  }
};

const getRouteParams = () => bridge.$routeParams;

function view() {
  return bridge.browserService.is('mobile') ? 'list' : storage().get('view') || 'tile';
}

function setViewInStorage(value) {
  storage().set('view', value);
}

function isCollectionTab(tab) {
  return window.isGroupId(tab) || tab === 'group';
}

function isStaticTab(tab) {
  return (tab === 'inbox' ||
    tab === 'outbox' ||
    tab === 'sent' ||
    tab === 'brief' ||
    tab === 'approved' ||
    tab === 'everything' ||
    tab === 'proof' ||
    tab === 'group' ||
    isCollectionTab(tab)
  );
}

function openProof(proof) {
  const path = getProofLink(proof);
  bridge.$location.url(path);
  if (!bridge.$rootScope.$$phase) bridge.$rootScope.$apply();
  return false;
}

function openCollectionManage(groupId) {
  bridge.$rootScope.$broadcast('openCollectionPane', groupId);
  if (!bridge.$rootScope.$$phase) bridge.$rootScope.$apply();
}

function openManageProof(proof, userId) {
  const options = {
    canManage: proof.isOwner(userId),
  };
  bridge.proofInfoService.openManageProof(proof.id, 'dashboard-manage', options);
}

function openProofInfo(proof, from, userId) {
  if (proof.id) {
    const options = from === 'dashboard'
      ? { canManage: proof.isOwner(userId) }
      : null;
    bridge.proofInfoService.open(proof.id, from, options); // from = dashboard/team-dashboard
  }
}

function printProofLegacy(proof, type) {
  bridge.printServiceLegacy.printProof(proof.id, type);
}

function isDownloadSupported() {
  return !bridge.browserService.is('safari') ||
    (bridge.browserService.is('safari') && parseFloat(bridge.browserService.version) >= 10.1);
}

function getSearchTab(search, filters) {
  let displaySearch;
  let displayFilters = '';

  displaySearch = bridge.$filter('ellipsis')(search, { length: 20 });

  if (displaySearch.length) {
    displaySearch = `“${displaySearch}”`;
  }

  displayFilters = filters.join(' or ');

  if (displaySearch && displayFilters) {
    displaySearch += ' and ';
  }

  if (!displaySearch && displayFilters) {
    displayFilters = displayFilters[0].toUpperCase() + displayFilters.slice(1);
  }
  return displaySearch + displayFilters;
}

function setTitle(tab) {
  bridge.seoService.set({ title: tab.getTitle() });
}

export {
  getURL,
  updateTab,
  getRouteParams,
  view,
  setViewInStorage,
  isStaticTab,
  openProofInfo,
  openManageProof,
  printProofLegacy,
  isDownloadSupported,
  getSearchTab,
  isCollectionTab,
  setTitle,
  openProof,
  openCollectionManage,
  goToPath,
};
