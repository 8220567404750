/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import { InlineSVG } from '../../components/InlineSVG';
import styles from './DashboardManage.scss';
import ManageTab from '../../components/ManageTab';
import ManageBox from '../../components/ManageBox';
import { Translation } from '../../components/Text';
import Reveal from '../../components/Reveal';

class DashboardManageContainer extends Component {
  state = {
    selectedOption: null,
  };

  updateSelectedOption = (option) => {
    this.setState({
      selectedOption: option,
    });
    if (this.props.onUpdateManageItem) {
      this.props.onUpdateManageItem(option);
    }
  };

  render() {
    const {
      options,
      manageItem,
      proofCount,
      label,
      tip,
      showManagePane,
      onToggle,
      color,
    } = this.props;

    const { selectedOption } = this.state;
    const selectedHeader = (selectedOption && options.filter(option => option.id === selectedOption)[0]);
    return (
      <Reveal
        align="bottom"
        visible={showManagePane}
        immediate
      >
        <div
          className={classname([
            styles.DashboardManage,
            styles.DashboardManage__showPanel,
            styles[`DashboardManage--${color}`],
          ])}
        >
          <div className={styles.DashboardManage__header}>
            <div className={styles.DashboardManage__header__text}>
              <span className={styles.DashboardManage__header__text__title}>
                <Translation value={selectedHeader ? selectedHeader.label : label} />
              </span>
              <span className={styles.DashboardManage__header__text__tip}>
                <Translation value={!selectedHeader ? tip : ''} />
              </span>
            </div>
            {!selectedHeader && (
              <button
                type="button"
                className={styles.DashboardManage__header__close}
                onClick={onToggle}
              >
                <InlineSVG src="/img/interface/menu-close-icon.svg" />
              </button>)}
          </div>
          {options &&
            <div className={styles.DashboardManage__panel}>
              {options.map(option => (
                <div key={option.id}>
                  <ManageTab
                    id={option.id}
                    onCloseManagePanel={onToggle}
                    tabData={option}
                    proofCount={proofCount}
                    validCount={option.validCount}
                    onToggle={id => this.updateSelectedOption(id)}
                    hide={selectedOption && option.id !== selectedOption}
                    color={color}
                  />
                </div>
              ))}
            </div>
          }
          {manageItem &&
            <div className={styles.DashboardManage__panel}>
              <ManageBox
                color={color}
                tabData={manageItem}
                onCloseManagePanel={onToggle}
                onAction={manageItem.onAction}
                onCancel={manageItem.onCancel}
                disabled={manageItem.disabled}
              />
            </div>
          }
        </div>
      </Reveal>
    );
  }
}

DashboardManageContainer.defaultProps = {
  proofCount: 0,
  label: '',
  tip: '',
  color: 'green',
};

if (process.env.NODE_ENV !== 'production') {
  DashboardManageContainer.propTypes = {
    options: PropTypes.arrayOf(PropTypes.any),
    manageItem: PropTypes.objectOf(PropTypes.any),
    onToggle: PropTypes.func,
    proofCount: PropTypes.number,
    label: PropTypes.string,
    tip: PropTypes.string,
    showManagePane: PropTypes.bool,
    onUpdateManageItem: PropTypes.func,
    color: PropTypes.string,
  };
}

export default DashboardManageContainer;
