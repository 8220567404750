/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { InlineSVG } from '../InlineSVG';
import css from './ProofCollectionButton.scss';
import UnstyledButton from '../Button/UnstyledButton';
import HoverCard from '../HoverCard';
import CollectionCard from '../CollectionCard';
import { Translation } from '../Text';

const ProofCollectionButton = ({ showCollection, onClick, proofCount, collectionCardProps }) => (
  <HoverCard
    content={collectionCardProps
      ? <CollectionCard {...collectionCardProps} />
      : <Translation value="proof.utilities.collection.tooltip" />
    }
    up
    center
    variant={collectionCardProps ? 'light' : 'dark'}
    maxWidth={collectionCardProps ? 300 : 150}
    padding={!collectionCardProps}
    disabled={showCollection}
  >
    <UnstyledButton
      className={css.ProofCollectionButton}
      onClick={onClick}
      ariaLabel="Collection"
    >
      <InlineSVG src="img/content/proof/icons/collection_icon.svg" />
      <div className={css.ProofCollectionButton__count}>
        {proofCount}
      </div>
    </UnstyledButton>
  </HoverCard>
);

if (process.env.NODE_ENV !== 'production') {
  ProofCollectionButton.propTypes = {
    showCollection: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    proofCount: PropTypes.number,
    collectionCardProps: PropTypes.shape(CollectionCard.propTypes),
  };
}

export default ProofCollectionButton;
