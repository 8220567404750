/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import { InlineSVG } from '../InlineSVG';
import { useLocalStorage } from '../LocalStorage';
import { sdk } from '../../util/sdk';
import MobileDashboard from '../Helpers/MobileDashboard';
import css from './NotificationTray.scss';
import Tooltip from '../Tooltip';
import { Translation } from '../Text';

function NotificationTray({
  notificationsCount,
  onPassValue,
}) {
  const [isSnoozed] = useLocalStorage(`pageproof.app.notifications.${sdk.session.user.id}.snoozed`, false);

  return (
    <MobileDashboard>
      {yes => (
        <div
          className={classname('notification-tray', {
            'notification-tray--compact': yes,
          })}
        >
          <div className={css.NotificationTray}>
            <div className={css.NotificationTray__button} onClick={() => onPassValue('view')}>
              {(notificationsCount > 0 && !isSnoozed) &&
                <div
                  className={classname(css.NotificationTray__button__count, {
                    [css.NotificationTray__button__unreadMany]: notificationsCount >= 5,
                  })}
                >
                  {notificationsCount >= 5
                    ? <span>{notificationsCount}</span>
                    : <span />
                  }
                </div>
              }
              <Tooltip
                title={<Translation value="notifications-tray.tooltip" />}
                down
                center
                offset={15}
              >
                <div>
                  {isSnoozed && (
                    <div className={css.NotificationTray__button__snoozeText}>
                      <span className={css.NotificationTray__button__snoozeText__zFirst}>z</span>
                      <sup className={css.NotificationTray__button__snoozeText__zSecond}>z</sup>
                    </div>
                  )}
                  <div className={css.NotificationTray__button__iconWrapper}>
                    <InlineSVG
                      src="img/interface/notification.svg"
                      className={css.NotificationTray__button__iconWrapper__snoozeIcon}
                    />
                  </div>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      )}
    </MobileDashboard>
  );
}

if (process.env.NODE_ENV !== 'production') {
  NotificationTray.propTypes = {
    onPassValue: PropTypes.func,
    notificationsCount: PropTypes.number,
  };
}

export default NotificationTray;
