/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { InlineSVG } from '../InlineSVG';
import css from './ProgressCopyShareLinkButton.scss';
import Tooltip from '../Tooltip';
import { Translation } from '../Text';
import useIsMobile from '../../hooks/useIsMobile';

const ProgressCopyShareLinkButton = ({ isCopied, onClick }) => {
  const isMobile = useIsMobile();
  const isMobileIcon = isMobile ? '/img/icons/material/share-24px.svg' : '/img/icons/monday/link.svg';

  return (
    <Tooltip
      title={
        <Translation
          value={isCopied
            ? 'proof.utilities.share.copied'
            : 'proof.utilities.share.share-link'
          }
        />
      }
      up
      center
    >
      <button
        type="button"
        className={css.ProgressCopyShareLinkButton}
        onClick={onClick}
      >
        <InlineSVG
          src={isCopied
            ? '/img/interface/tick.svg'
            : isMobileIcon
          }
        />
      </button>
    </Tooltip>
  );
};

if (process.env.NODE_ENV !== 'production') {
  ProgressCopyShareLinkButton.propTypes = {
    isCopied: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
  };
}

export default ProgressCopyShareLinkButton;
