/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getSpiderClient } from '../util/gql/getSpiderClient';

let spiderClient;

const query = gql`
  query ppxapp_getTeamUserGroups($states: [UserGroupState!]) {
    me {
      team {
        userGroups(states: $states) {
          name
          members {
            role
            user {
              email
            }
          }
        }
        settings {
          userGroups {
            isEnabled {
              value
            }
          }
        }
      }
    }
  }
`;

const getCanAccessUserGroupsFromData = data => data.me.team && data.me.team.settings.userGroups.isEnabled.value;
const getUserGroupsFromData = data => data.me.team.userGroups;

const useUserGroupSuggestions = (props) => {
  if (!spiderClient) {
    spiderClient = getSpiderClient();
  }

  const { states = ['active'] } = props;
  const [userGroups, setUserGroups] = useState([]);
  const { loading, error, data } = useQuery(query, { client: spiderClient, variables: { states } });

  useEffect(() => {
    if (loading || error || !data) {
      setUserGroups([]);
    } else if (getCanAccessUserGroupsFromData(data)) {
      setUserGroups(getUserGroupsFromData(data));
    }
  }, [loading, error, data]);

  return userGroups
    .filter(userGroup => userGroup.members.length > 0)
    .sort((a, b) => (a.name < b.name ? -1 : 1))
    .map(userGroup => ({
      name: userGroup.name,
      members: userGroup.members.map(member => ({ email: member.user.email, role: member.role })),
    }));
};

export default useUserGroupSuggestions;
