/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import classname from 'classname';
import React, { useMemo, useRef, useState, Fragment } from 'react';
import { InlineSVG } from '../../../InlineSVG';
import { Separator } from '../../../PopupMenu';
import { Search } from '../../../Search';
import { Spinner } from '../../../Spinner';
import Tooltip from '../../../Tooltip';
import InputOptions from '../InputOptions';
import BackButton from './BackButton';
import css from './IntegrationReferenceSelector.scss';
import SelectorSection from './SelectorSection';

const FRONT_END_SEARCH_VALUES = { maxOnScreenOptions: 1000, minimumSearchLength: 2 };

function SearchableSelector({ referenceType, onClickBack, searchTerm, onSearch, selectOption, selectedOptions, onReloadReferenceOptions, isLoadingOptions, hasSearchableBreadcrumbs }) {
  const searchRef = useRef();

  const [holderMinWidth, setHolderMinWidth] = useState();
  const holderRef = useRef();

  const updateSearchTerm = (term) => {
    onSearch(term);
    setHolderMinWidth(referenceType.options.length ? holderRef.current.offsetWidth : undefined);
  };

  const canRefresh = referenceType.cached && referenceType.options && (searchTerm.length >= referenceType.minimumSearchLength || !referenceType.serverSideSearch);

  const minimumSearchLength = useMemo(() => {
    if (referenceType.serverSideSearch) {
      return referenceType.minimumSearchLength;
    }
    const optionsCount = referenceType.options &&
      referenceType.options.reduce((accumulator, currentValue) => accumulator + (currentValue.options ? currentValue.options.length : 0), 0);

    return optionsCount > FRONT_END_SEARCH_VALUES.maxOnScreenOptions ? FRONT_END_SEARCH_VALUES.minimumSearchLength : 0;
  }, [referenceType.options]);

  return (
    <div
      style={holderMinWidth && referenceType.options.length ? { minWidth: holderMinWidth } : {}}
      ref={holderRef}
      className={classname(css.Holder, css['Holder--searchable'])}
    >
      <div
        className={css.TopOptions}
      >
        <BackButton
          label={referenceType.label}
          sublabel={referenceType.sublabel}
          onClick={onClickBack}
          className={css.TopOptions__back}
        />
        {canRefresh && (
          <Tooltip
            up
            center
            offset={16}
            title={isLoadingOptions ? 'Reloading options' : 'Refresh list. This may take a while depending on the size of the linked account.'}
          >
            {/* Wrapping the option in a div is required for the tooltip to display */}
            <div>
              <InputOptions.Option
                className={css.TopOptions__reload}
                label={(
                  <InlineSVG
                    className={classname(css.TopOptions__reload__icon, { [css['TopOptions__reload__icon--loading']]: isLoadingOptions })}
                    src="/img/content/proof/icons/refresh.svg"
                  />
                )}
                onClick={onReloadReferenceOptions}
              />
            </div>
          </Tooltip>
        )}
        {!canRefresh && isLoadingOptions && referenceType.options && (
          <div className={css.TopOptions__spinnerHolder}>
            <Spinner
              color="white"
              size={24}
            />
          </div>
        )}
      </div>
      {!referenceType.options
        ? (
          <div className={css.Holder__spinner}>
            <Spinner
              color="white"
              size={40}
            />
          </div>
        )
        : (
          <Fragment>
            <Search
              searchRef={searchRef}
              searchTerm={searchTerm}
              onSearch={updateSearchTerm}
              autoFocus
            />
            {searchTerm.length < minimumSearchLength && (
              <InputOptions.Option
                className={css.Prompt}
                label={`Please enter at least ${minimumSearchLength} letters to search`}
                disabled
              />)}
            {/* If there is a minimum search length and it's not a serverSideSearch it means the minimum search length is artificial for rendering reasons */}
            {(referenceType.serverSideSearch || searchTerm.length >= minimumSearchLength) && referenceType.options
              .filter(ot => ot.options.length)
              .map(ot => (
                <SelectorSection
                  key={`option-section-${ot.options.map(option => option.referenceId).join()}`}
                  searchTerm={referenceType.serverSideSearch ? '' : searchTerm}
                  breadcrumb={ot.breadcrumb}
                  showOptionsUnderBreadcrumbsIndented={referenceType.selectorId === 'mondayItemByBoard'}
                  options={ot.options}
                  updateSearchTerm={(term) => {
                    updateSearchTerm(term);
                    searchRef.current.focus();
                  }}
                  selectOption={selectOption}
                  selectedOptions={selectedOptions}
                  hasSearchableBreadcrumbs={hasSearchableBreadcrumbs}
                />
              ))}
            {referenceType.message && (
              <Fragment>
                {referenceType.options.some(ot => ot.options.length) && <Separator />}
                <InputOptions.Option
                  label={referenceType.message}
                  disabled
                />
              </Fragment>
            )}
          </Fragment>
        )}
    </div>
  );
}

export default SearchableSelector;
