export default function getCommentMentionedIds(tokens) {
  const userIds = [];
  tokens.bs.forEach((block) => {
    block.en.forEach((entity) => {
      if (entity.t === 'mention') {
        const userId = entity.md.i_;
        if (userIds.indexOf(userId) === -1) {
          userIds.push(userId);
        }
      }
    });
  });
  return userIds;
}
