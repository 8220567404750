/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

/* eslint-disable no-nested-ternary, consistent-return */
import React, { useState, useEffect } from 'react';
import classname from 'classname';
import { InlineSVG } from '../../components/InlineSVG';
import { useWorkflowTemplate } from './internals/useWorkflowTemplate';
import Workflow from '../../components/ProofSetup/components/Workflow';
import { InlineSpinner } from '../../components/Spinner';
import { Button } from '../../components/Button';
import { NameInput } from './internals/NameInput';
import Switch from '../../components/Switch';
import { Subheading } from '../../components/Page';
import { Flex } from '../../components/Flex';
import { Tab, Tabs } from '../../components/Tabs';
import css from './WorkflowTemplatePage.scss';
import FormControl from '../../components/FormControl';
import { useSynchronizedUrl } from '../../hooks/useSynchronizedUrl';
import { sdk } from '../../util/sdk';
import { UserList } from './internals/UserList';
import { useText, Translation } from '../../components/Text';

export const WorkflowTemplatePage = ({ initialRouteParams }) => {
  const { workflowId, from } = initialRouteParams;

  const { isLoading, isReadOnly, workflowTemplate, mutations, reload } = useWorkflowTemplate(workflowId);
  const [activeTab, setActiveTab] = useState(initialRouteParams.tab || 'workflow');

  const text = useText();

  useEffect(() => {
    if (!workflowTemplate) {
      return;
    }

    const translatedDefaultWorkflowName = text('workflow.structure.needs-a-name');
    const isDefaultName = workflowTemplate.name === translatedDefaultWorkflowName || workflowTemplate.name === '* This workflow needs a name';

    if (workflowTemplate.name && !isDefaultName) {
      window.__pageproof_bridge__.seoService.set({
        title: workflowTemplate.name,
      });
    }
  }, [workflowTemplate && workflowTemplate.name]);

  const enableDebugging = initialRouteParams.tab === 'debug';
  const [showHiddenFields, setShowHiddenFields] = useState(false);
  const enablePolling = isReadOnly;

  useSynchronizedUrl(
    '/workflows/templates/' + workflowId,
    {
      ...(from ? { from } : {}),
      ...(activeTab !== 'workflow' ? { tab: activeTab } : {}),
    }
  );

  useEffect(() => {
    if (enablePolling) {
      const int = setInterval(reload, 3000);
      return () => clearInterval(int);
    }
  }, [enablePolling]);

  const isInitialLoad = !workflowTemplate && isLoading;

  useEffect(() => {
    if (isInitialLoad) {
      window.__pageproof_bridge__.appService.showLoader(text('workflow.template.page.loading-template'));
    } else {
      window.__pageproof_bridge__.appService.hideLoader();
    }
  }, [isInitialLoad]);

  if (isInitialLoad) {
    return null;
  }

  return (
    <div className={css.WorkflowTemplatePage}>
      <br />
      <br />
      <div className={css.WorkflowTemplatePage__backButton}>
        <Button
          label={
            <Flex
              container
              gap={10}
              style={{ overflow: 'visible' }}
            >
              <InlineSVG
                className={css.WorkflowTemplatePage__backIcon}
                src="img/interface/arrow-down.svg"
              />
              <Translation value="workflow.template.page.back" />
            </Flex>
          }
          variant="text"
          autoWidth
          onClick={() => {
            let backUrl = '/workflow-dashboard';

            switch (from) {
            case 'shared':
              backUrl = '/workflow-dashboard-shared';
              break;
            case 'team':
              backUrl = '/team-workflows';
              break;
            default:
              break;
            }

            window.__pageproof_bridge__.$location.url(backUrl);
            window.__pageproof_bridge__.$rootScope.$apply();
          }}
        />
      </div>
      <div
        style={{
          marginTop: -40,
        }}
      />
      <NameInput
        workflowTemplate={workflowTemplate}
        isReadOnly={isReadOnly}
        onNameChange={mutations.setName}
        onFavoriteChange={mutations.setFavorite}
        onDelete={() => {
          mutations.delete().then(() => {
            window.__pageproof_bridge__.$location.url('/workflow-dashboard');
            window.__pageproof_bridge__.$rootScope.$apply();
          });
        }}
      />
      <div style={{ fontSize: 12, marginTop: isReadOnly ? 0 : 8, color: '#666' }}>
        <Translation value="workflow.template.page.created-by" />
        {' '}
        {workflowTemplate.ownerId === sdk.session.userId
          ? <Translation value="workflow.template.page.created-by.you" />
          : workflowTemplate.owner.email
        }
      </div>
      <br />
      <br />
      <Tabs selected={activeTab}>
        <Tab
          id="workflow"
          name={<Translation value="workflow.template.page.tab.workflow" />}
          onClick={() => setActiveTab('workflow')}
        >
          <br />
          <Subheading
            description={<Translation value="workflow.template.page.tab.description.workflow" />}
            center
          />
          <div
            className={classname(css.WorkflowTemplatePage__workflowWrapper, {
              [css['WorkflowTemplatePage__workflowWrapper--isReadOnly']]: isReadOnly,
            })}
          >
            <Workflow
              type="template"
              workflow={workflowTemplate}
              owners={workflowTemplate.proofOwners.map(owner => owner.email)}
              events={{
                onAddStep: mutations.addWorkflowStep,
                onDeleteStep: mutations.deleteWorkflowStep,
                onAddUsers: mutations.addWorkflowUsers,
                onRemoveUser: mutations.removeWorkflowUser,
                onUpdateUserRole: mutations.updateWorkflowUserRole,
                onUpdateUserPermissions: mutations.updateWorkflowUserPermissions,
                onMoveStep: mutations.moveWorkflowStep,
                onUpdateStepName: mutations.updateWorkflowStepName,
                onUpdateStepMandatoryDecisionThreshold: mutations.updateWorkflowStepMandatoryDecisionThreshold,
              }}
              isReadOnly={isReadOnly}
              onChange={() => {}}
            />
          </div>
        </Tab>
        <Tab
          id="proof-owners"
          name={<Translation value="workflow.template.page.tab.proof-owners" />}
          onClick={() => setActiveTab('proof-owners')}
        >
          <div>
            <br />
            <Subheading
              title={<Translation value="workflow.template.page.tab.title.proof-owners" />}
              description={<Translation value="workflow.template.page.tab.description.proof-owners" />}
              helpMessage={<Translation value="workflow.template.page.tab.help.proof-owners" />}
              helpUrl="https://go.pageproof.com/workflows/proof-owners"
            />
            {(isReadOnly && !workflowTemplate.proofOwners.length)
              ? <div className={css.WorkflowTemplatePage__proofOwners__none}><Translation value="workflow.template.page.tab.message.no-proof-owners" /></div>
              : (
                <UserList
                  users={workflowTemplate.proofOwners}
                  onRemove={user => mutations.removeProofOwner(user)}
                  isReadOnly={isReadOnly}
                  onAdd={(emails) => {
                    emails.forEach((email) => {
                      if (workflowTemplate.proofOwners.some(user => user.email === email)) {
                        return;
                      }
                      mutations.addProofOwner(email);
                    });
                  }}
                />
              )
            }
          </div>
        </Tab>
        {!isReadOnly && (
          <Tab
            id="shared-with"
            name={<Translation value="workflow.template.page.tab.shared-with" />}
            onClick={() => setActiveTab('shared-with')}
          >
            <div>
              <br />
              <Subheading
                title={<Translation value="workflow.template.page.tab.title.share-with-team" />}
                description={<Translation value="workflow.template.page.tab.description.share-with-team" />}
              />
              <Switch
                value={workflowTemplate.isOwnedByTeam}
                disabled={isReadOnly}
                onChange={() => mutations.setSharedWithTeam(!workflowTemplate.isOwnedByTeam)}
              />
              <br />
              <Subheading
                title={<Translation value="workflow.template.page.tab.title.share-with-users" />}
                description={<Translation value="workflow.template.page.tab.description.share-with-users" />}
              />
              <UserList
                hasPermissions
                isReadOnly={isReadOnly}
                users={workflowTemplate.sharedWith}
                additionalExclusions={[workflowTemplate.owner.email]}
                onAdd={(emails) => {
                  emails.forEach((email) => {
                    if (workflowTemplate.owner.email === email || workflowTemplate.sharedWith.some(user => user.email === email)) {
                      return;
                    }
                    mutations.addSharedWith(email);
                  });
                }}
                onRemove={(user) => {
                  mutations.removeSharedWith(user.id);
                }}
                onCanEditUpdated={(user, canEdit) => {
                  if (canEdit) {
                    mutations.enableSharedUserCanEdit(user.id);
                  } else {
                    mutations.disableSharedUserCanEdit(user.id, user._memberId);
                  }
                }}
              />
            </div>
          </Tab>
        )}
        {enableDebugging && (
          <Tab
            id="debug"
            name={
              <span
                onContextMenu={(event) => {
                  event.preventDefault();
                  reload();
                }}
              >
                Debug
                {isLoading && (
                  <span>
                    &nbsp;&nbsp;
                    <InlineSpinner size={16} />
                  </span>
                )}
              </span>
            }
            onClick={() => setActiveTab('debug')}
          >
            <br />
            <FormControl label="Show hidden fields">
              <Switch
                value={showHiddenFields}
                onChange={setShowHiddenFields}
              />
            </FormControl>
            <FormControl label="JSON">
              <pre>
                {JSON.stringify(workflowTemplate, (key, value) => (!showHiddenFields && (key === 'state' || key.startsWith('_')) ? undefined : value), 2)}
              </pre>
            </FormControl>
          </Tab>
        )}
      </Tabs>
      {/* {isLoading && <Spinner />} */}
      <br />
      <br />
    </div>
  );
};
