/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import { InlineSVG } from '../InlineSVG';
import VideoSpeedDropdown from '../../containers/VideoSpeedDropdown';
import VideoQualityDropdown from '../../containers/VideoQualityDropdown';
import Tooltip from '../Tooltip';
import {Translation} from '../Text';
import PlayerVolume from '../../containers/PlayerVolume';

function ScrubberIcons({
  playerQualityProps,
  playerSpeedProps,
  loop,
  onToggleLoop,
  allowFullscreen,
  onToggleFullscreen,
  volumeProps,
  extension,
}) {
  const {$rootScope} = window.__pageproof_bridge__;
  return (
    <div className="ScrubberIcons">
      {extension !== 'gif' && (
        <div className="ScrubberIcons__icon">
          <VideoQualityDropdown {...playerQualityProps} />
        </div>
      )}
      <div className="ScrubberIcons__icon">
        <VideoSpeedDropdown {...playerSpeedProps} />
      </div>
      <Tooltip title={<Translation value={`proof.utilities.loop.${loop ? 'on' : 'off'}`} />} up center>
        <div
          className={classname('ScrubberIcons__icon ScrubberIcons__icon--loop', {
            'ScrubberIcons__icon--active': loop,
          })}
          onClick={() => {
            onToggleLoop();
            $rootScope.$apply();
          }}
        >
          <InlineSVG
            src="img/content/proof/icons/video/loop.svg"
            className="ScrubberIcons__icon--loop--primary"
          />
          <InlineSVG
            src="img/content/proof/icons/video/loop.svg"
            className="ScrubberIcons__icon--loop--pop"
          />
        </div>
      </Tooltip>
      {allowFullscreen && (
        <Tooltip title={<Translation value="proof.utilities.fullscreen" />} up center>
          <div
            className="ScrubberIcons__icon ScrubberIcons__icon--fullscreen"
            onClick={onToggleFullscreen}
          >
            <InlineSVG src="img/content/proof/icons/video/fullscreen.svg" />
          </div>
        </Tooltip>
      )}
      {extension !== 'gif' && (
        <div className="ScrubberIcons__icon ScrubberIcons__icon--volume">
          <PlayerVolume
            onChange={volumeProps.onChange}
            volume={volumeProps.volume}
          />
        </div>
      )}
    </div>
  );
}

if (process.env.NODE_ENV !== 'production') {
  ScrubberIcons.propTypes = {
    playerQualityProps: PropTypes.shape({
      autoQuality: PropTypes.string,
      direction: PropTypes.string,
      onChange: PropTypes.func,
      qualities: PropTypes.arrayOf(PropTypes.object),
      selected: PropTypes.number,
    }).isRequired,
    playerSpeedProps: PropTypes.shape({
      selected: PropTypes.string,
      selectedIcon: PropTypes.string,
      direction: PropTypes.string,
      onChange: PropTypes.func,
    }).isRequired,
    volumeProps: PropTypes.shape({
      volume: PropTypes.number,
      onChange: PropTypes.func,
    }).isRequired,
    loop: PropTypes.bool.isRequired,
    onToggleLoop: PropTypes.func.isRequired,
    allowFullscreen: PropTypes.bool.isRequired,
    onToggleFullscreen: PropTypes.func.isRequired,
  };
}

export default ScrubberIcons;
