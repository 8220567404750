/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-disable */

import React, { cloneElement, Children, useRef, useState, useEffect, Fragment } from 'react';
import { InlineSVG } from '../InlineSVG';
import css from './Suggestions.scss';

import Tooltip from '../Tooltip/Tooltip';
import { OptionList, Option, Separator } from '../PopupMenu';
import Avatar from '../Avatar/Avatar';
import HighlightedText from '../HighlightedText/HighlightedText';
import env from '../../../../shared/env';
import useDarkMode from '../../hooks/useDarkMode';
import useTeamMemberSuggestions from '../../hooks/useTeamMemberSuggestions';
import useUserGroupSuggestions from '../../hooks/useUserGroupSuggestions';

const DEFAULT_SELECTED_INDEX = -1;

const TEAM_MEMBERS_SOURCE = 'teamMembers';
const ADDRESS_BOOK_SOURCE = 'addressBook';
const USER_GROUPS_SOURCE = 'userGroups';

// TODO: Move this into its own file
const SuggestionLabel = (props) => {
  const {
    suggestion,
    source,
    inputValue,
    onRemove,
  } = props;

  return (
    <div className={css.Suggestion__container}>
      {(source === TEAM_MEMBERS_SOURCE || source === ADDRESS_BOOK_SOURCE) &&
        <div className={css.Suggestion__avatar}>
          <Avatar
            active
            size={22}
            url={`${env.avatar_url}/-/${source === ADDRESS_BOOK_SOURCE ? suggestion : suggestion.email}`}
            spinner
          />
        </div>
      }
      <div className={css.Suggestion__email}>
        <div className={css.Suggestion__email__inner}>
          {source === USER_GROUPS_SOURCE && (
            <HighlightedText
              parentText={suggestion.name}
              subtext={inputValue}
            />
          )}
          {source === ADDRESS_BOOK_SOURCE && <HighlightedText
            parentText={suggestion}
            subtext={inputValue}
          />}
          {source === TEAM_MEMBERS_SOURCE &&
           <Fragment>
            {
              suggestion.name && 
              <div className={css.Suggestion__name}>
                <HighlightedText
                parentText={suggestion.name}
                subtext={inputValue}
                />

              </div>
            }
        
              <HighlightedText
              parentText={suggestion.email}
              subtext={inputValue}
              />
          </Fragment>
          }
        </div>
      </div>
      {source === ADDRESS_BOOK_SOURCE && (
        <Tooltip
          up
          center
          title="Remove from address book"
        >
          <button
            className={css.Suggestion__remove}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onRemove(suggestion);
            }}
          >
            <InlineSVG
              src="/img/interface/menu-close-icon.svg"
              className={css.Suggestion__removeIcon}
            />
          </button>
        </Tooltip>
      )}
    </div>
  )
}

export default function Suggestions({
  inputRef: parentInputRef,
  exclusions = [],
  maxSuggestions = 5,
  onSelect,
  children,
  inputId,
  includeUserGroups = false,
}) {
  const {
    $addressBook: addressBook,
    addressBookRepositoryService: addressBookRepository,
    UserService: userService,
  } = window.__pageproof_bridge__;

  const userData = userService.GetUserData();
  const suggestionsIncludeTeamMembers = userData.features && userData.features.suggestionsIncludeTeamMembers;
  const [isDarkMode] = useDarkMode();
  const [focused, setFocused] = useState(false);
  const [fieldId] = useState(() => inputId || `search_${window.randomId('_')}`);
  let [selectedIndex, setSelectedIndex] = useState(-1);
  const inputRef = useRef();

  const teamMemberSuggestions = useTeamMemberSuggestions();
  const userGroupSuggestions = useUserGroupSuggestions({ expandedUserList: true });

  const [suggestions, setSuggestions] = useState([]);
  const [filteredTeamMembers, setFilteredTeamMembers] = useState([]);
  const [filteredAddressBook, setFilteredAddressBook] = useState([]);
  const [filteredUserGroups, setFilteredUserGroups] = useState([]);
  
  const filtered = [...filteredAddressBook, ...filteredTeamMembers, ...filteredUserGroups];
  
  if (filtered.length === 1) {
    selectedIndex = 0;
  }

  const child = Children.only(children);

  const calculate = () => {
    if (!inputRef.current || !focused) {
      return;
    }
  
    const value = inputRef.current.value.toLowerCase();

    // Handle user groups searches starting with #
    if (includeUserGroups && value.startsWith('#')) {
      setFilteredAddressBook([]);
      setFilteredTeamMembers([]);
      setSelectedIndex(DEFAULT_SELECTED_INDEX);
      
      if (value.length === 1) {
        setFilteredUserGroups(userGroupSuggestions);
        return;
      }

      const searchString = value.slice(1);  // Remove the # from the input value
      const newFilteredUserGroups = userGroupSuggestions.filter(groupName => {
        const lowercaseGroupName = groupName.name.toLowerCase();
        return lowercaseGroupName.indexOf(searchString) !== -1 && lowercaseGroupName !== searchString;
      });
      setFilteredUserGroups(newFilteredUserGroups);
      return;
    } else {
      setFilteredUserGroups([]);
    }

    if (value.length) {
      const newFilteredAddressBook = [];
      const newFilteredTeamMembers = [];
      
      if (suggestionsIncludeTeamMembers) {
        teamMemberSuggestions.some((suggestion) => {
          const lowerEmail = suggestion.email.toLowerCase();
          const lowerName = suggestion.name ? suggestion.name.toLowerCase() : '';
          if ((lowerEmail.indexOf(value) !== -1 || lowerName.indexOf(value) !== -1) &&
              exclusions.indexOf(lowerEmail) === -1 &&
              lowerEmail !== value) {
            newFilteredTeamMembers.push(suggestion);
          }
          return newFilteredTeamMembers.length >= maxSuggestions;
        });
      }
      
      if (newFilteredTeamMembers.length < maxSuggestions) {
        suggestions.some((suggestion) => {
          const lowerSuggestion = suggestion.toLowerCase();
          const isInTeamMembers = suggestionsIncludeTeamMembers && 
            newFilteredTeamMembers.some(member => member.email.toLowerCase() === lowerSuggestion);
          
          if (lowerSuggestion.indexOf(value) !== -1 && 
              exclusions.indexOf(lowerSuggestion) === -1 && 
              lowerSuggestion !== value &&
              !isInTeamMembers) {
            newFilteredAddressBook.push(suggestion);
          }
          return (newFilteredAddressBook.length + newFilteredTeamMembers.length) >= maxSuggestions;
        });
      }
      setFilteredAddressBook(newFilteredAddressBook);
      setFilteredTeamMembers(newFilteredTeamMembers);
    } else {
      setFilteredAddressBook([]);
      setFilteredTeamMembers([]);
      setSelectedIndex(DEFAULT_SELECTED_INDEX);
    }
  };

  useEffect(calculate, [
    focused,
    exclusions.join(','),
    suggestions.join(','),
    teamMemberSuggestions.map(member => member.email).join(','),
    userGroupSuggestions.map(userGroup => userGroup.name).join(','),
  ]);

  useEffect(() => {
    addressBook.load(setSuggestions);

    const { userId } = userService.GetUserData();
    const offRemove = addressBookRepository.cache.on('remove', userId, () => {
      addressBook.load(setSuggestions);
    });
    const offUpdate = addressBookRepository.cache.on('update', userId, () => {
      addressBook.load(setSuggestions);
    });

    return () => {
      offRemove();
      offUpdate();
    };
  }, []);

  const removeSuggestion = (email) => {
    const updatedSuggestions = [...suggestions];
    updatedSuggestions.splice(suggestions.indexOf(email), 1);

    setSuggestions(updatedSuggestions);
    addressBook.delete(email);
  };

  const navigate = (direction) => {
    let newSelectedIndex = (selectedIndex + direction) % filtered.length;
    if (newSelectedIndex < DEFAULT_SELECTED_INDEX) newSelectedIndex = filtered.length - 1;
    setSelectedIndex(newSelectedIndex);
  };

  const select = (suggestion) => {
    onSelect(suggestion);
    setFilteredAddressBook([]);
    setFilteredTeamMembers([]);
    setFilteredUserGroups([]);
    setSelectedIndex(DEFAULT_SELECTED_INDEX);
  };

  const getGroupFromInput = (input) => {
    if (input.startsWith('#')) {
      const lowercaseInput = input.slice(1).toLowerCase();
      return userGroupSuggestions.find(userGroup => userGroup.name.toLowerCase() === lowercaseInput);
    }
  }

  const variant = isDarkMode ? 'dark' : 'light';

  return (
    <Tooltip
      title={
        <OptionList variant={variant}>
          {!!filteredUserGroups.length && (
            <Fragment>
              <Option
                disabled
                label="User groups"
              />
              {filteredUserGroups.map((suggestion, index) => (
                <Option
                  key={suggestion.name}
                  selected={index === selectedIndex}
                  label={
                    <SuggestionLabel
                      suggestion={suggestion}
                      source={USER_GROUPS_SOURCE}
                      inputValue={inputRef.current.value.slice(1).toLowerCase()}
                    />
                  }
                  onMouseDown={(event) => {
                    event.preventDefault();
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    select(suggestion);
                  }}
                />
              ))}
            </Fragment>
          )}
          {!!filteredAddressBook.length && (
            <Fragment>
              <Option
                disabled
                label="Address book"
              />
          {filteredAddressBook.map((suggestion, index) => (
            <Option
              key={suggestion}
              selected={index === selectedIndex}
              label={
                <SuggestionLabel
                  suggestion={suggestion}
                  source={ADDRESS_BOOK_SOURCE}
                  inputValue={inputRef.current.value.toLowerCase()}
                  onRemove={removeSuggestion}
                />
              }
              onMouseDown={(event) => {
                event.preventDefault();
              }}
              onClick={(event) => {
                event.preventDefault();
                select(suggestion);
              }}
            />
          ))}
          </Fragment>
        )}
          {!!filteredTeamMembers.length && (
            <Fragment>
              <Separator
                variant="light"
                fullWidth
              />
              <Option
                disabled
                label="Team members"
              />
              {filteredTeamMembers.map((suggestion, index) => (
                <Option
                  key={suggestion.email}
                  selected={(filteredAddressBook.length + index) === selectedIndex}
                  label={
                    <SuggestionLabel
                      suggestion={suggestion}
                      source={TEAM_MEMBERS_SOURCE}
                      inputValue={inputRef.current.value.toLowerCase()}
                    />
                  }
                  onMouseDown={(event) => {
                    event.preventDefault();
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    select(suggestion.email);
                  }}
                />
              ))}
            </Fragment>
          )}
        </OptionList>
      }
      variant={variant}
      visible={focused && filtered.length !== 0}
      delay={0}
    >
      {cloneElement(child, {
        // start: safari contact auto-fill fix
        id: fieldId,
        autoComplete: 'no-thank-you',
        autoCorrect: 'off',
        autoCapitalize: 'none',
        spellcheck: 'false',
        // end

        ref: (element) => {
          if (element instanceof HTMLElement) {
            if (parentInputRef) {
              parentInputRef.current = element; // eslint-disable-line no-param-reassign
            }
            inputRef.current = element;
          }
        },
        inputRef: (element) => {
          if (element instanceof HTMLElement) {
            inputRef.current = element;
          }
        },
        onChange: (event) => {
          if (child.props.onChange) {
            child.props.onChange(event);
          }
          calculate();
        },
        onFocus: (event) => {
          if (child.props.onFocus) {
            child.props.onFocus(event);
          }
          setFocused(true);
        },
        onBlur: (event) => {
          const group = getGroupFromInput(event.target.value);
          if (group) {
            select(group);
            return;
          }
          if (child.props.onBlur) {
            child.props.onBlur(event);
          }
          setFocused(false);
        },
        onKeyDown: (event) => {
          if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            event.preventDefault();
            navigate(event.key === 'ArrowDown' ? +1 : -1);
          }
          else if (event.key === 'Enter') {
            const suggestion = filtered[selectedIndex];
            if (suggestion) {
              event.preventDefault();
              if (typeof suggestion === 'object' && 'members' in suggestion) {
                select(suggestion);
                return;
              }
              select(typeof suggestion === 'string' ? suggestion : suggestion.email);
              return;
            }
            const group = getGroupFromInput(event.target.value);
            if (group) {
              select(group);
              return;
            }
          }
          else if (event.key === 'Escape') {
            event.preventDefault();
            setFilteredAddressBook([]);
            setFilteredTeamMembers([]);
            setFilteredUserGroups([]);
          } else if (event.key === 'Tab') {
            const suggestion = filtered[selectedIndex];
            if (filtered.length !== 0 && suggestion) {
              event.preventDefault();
              if (typeof suggestion === 'object' && 'members' in suggestion) {
                select(suggestion);
                return;
              }
              select(typeof suggestion === 'string' ? suggestion : suggestion.email);
            }
            const group = getGroupFromInput(event.target.value);
            if (group) {
              select(group);
              return;
            }
          }

          if (child.props.onKeyDown) {
            child.props.onKeyDown(event);
          }
        },
      })}
    </Tooltip>
  );
}

export function addEmail(email) {
  const { 
    $addressBook, 
    sdk,
   } = window.__pageproof_bridge__;
   
  
  return new Promise((resolve) => {
    if (email) {
      $addressBook.load((emails) => {
        if (emails.indexOf(email) === -1) {
          sdk.users.byEmail(email).then((result) => {
            $addressBook.add(email, () => {
              resolve();
            });
          });
        } else {
          resolve();
        }
      });
    } else {
      resolve();
    }
  });
}

Suggestions.addEmail = addEmail;
