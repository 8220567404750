/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import { InlineSVG } from '../InlineSVG';
import { Translation } from '../Text';
import Tooltip from '../Tooltip';
import Avatar from '../Avatar/Avatar';
import { Spinner } from '../Spinner';
import css from './EmailAddressEdit.scss';
import env from '../../../../shared/env';

const EmailAddressEdit = ({
  email,
  onDelete,
  onUpdate,
  showRemoveOnHover,
}) => {
  const [editedEmail, setEditedEmail] = useState(email);
  const [isDeletingEmail, setIsDeletingEmail] = useState(false);

  useEffect(() => {
    if (editedEmail !== email) {
      setEditedEmail(email);
      setIsDeletingEmail(false);
    }
  }, [email]);

  return (
    <div className={css.EmailAddressEdit}>
      <span className={css.EmailAddressEdit__avatar}>
        <Avatar
          active
          url={`${env.avatar_url}/-/${email}`}
          size={26}
          spinner
        />
      </span>
      <Tooltip
        title={<Translation value={email} />}
        up
        center
      >
        <input
          className={css.EmailAddressEdit__email}
          value={editedEmail}
          onChange={event => setEditedEmail(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              onUpdate(editedEmail, email);
            }
          }}
          onBlur={() => {
            if (editedEmail === '') {
              setIsDeletingEmail(true);
              onDelete(email);
            } else {
              onUpdate(editedEmail, email);
            }
          }}
          onFocus={(event) => {
            event.target.select();
          }}
        />
      </Tooltip>
      <Tooltip
        title={<Translation value="email-address-edit.delete.tooltip" />}
        up
        center
      >
        {isDeletingEmail
          ? <Spinner
            size={16}
            style={{ paddingRight: '20px' }}
          />
          : (
            <button
              type="button"
              className={classname(css.EmailAddressEdit__remove, {
                [css['EmailAddressEdit__remove--showRemoveOnHover']]: showRemoveOnHover,
              })}
              onClick={() => {
                setIsDeletingEmail(true);
                onDelete(email);
              }}
            >
              <InlineSVG
                src="/img/interface/menu-close-icon.svg"
                className={css.EmailAddressEdit__removeIcon}
              />
            </button>)
        }
      </Tooltip>
    </div>
  );
};

if (process.env.NODE_ENV !== 'production') {
  EmailAddressEdit.propTypes = {
    email: PropTypes.string,
    onDelete: PropTypes.func,
    onUpdate: PropTypes.func,
    showRemoveOnHover: PropTypes.bool,
  };
}

export default EmailAddressEdit;
