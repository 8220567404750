/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
* Unauthorized copying of this file, via any medium is strictly prohibited.
* Proprietary and confidential.
*/

/* eslint-disable no-nested-ternary, no-param-reassign */
import React from 'react';
import classname from 'classname';
import { InlineSVG } from '../../../components/InlineSVG';
import UnstyledButton from '../../../components/Button/UnstyledButton';
import Tooltip from '../../../components/Tooltip';
import css from './IconButton.scss';

export const IconButton = ({ iconUrl, activeIconUrl, title, isActive, activeColor, activeColorHover, onClick }) => (
  <Tooltip
    up
    center
    title={title}
  >
    <UnstyledButton
      className={classname(css.IconButton, 'depress-active', {
        [css['IconButton--isActive']]: isActive,
      })}
      onClick={onClick}
      style={{
        '--IconButton--active-color': activeColor,
        '--IconButton--active-color-hover': activeColorHover,
      }}
      ariaLabel={title}
    >
      <InlineSVG src={isActive && activeIconUrl ? activeIconUrl : iconUrl} />
    </UnstyledButton>
  </Tooltip>
);
