/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import { InlineSVG } from '../InlineSVG';
import InputOptions from '../ProofSetup/components/InputOptions';
import CollectionProofDownloadContainer from '../../containers/CollectionProofDownload';
import css from './CollectionProofMenu.scss';

const CollectionProofMenu = ({ canManage, onManageClick, collectionProofDownloadProps }) => (
  <InputOptions
    options={[
      canManage &&
      <InputOptions.Option
        key="Manage"
        label={
          <div className={css.OptionContainer}>
            <InlineSVG
              src="img/interface/settings.svg"
              width="16"
              height="16"
              fill="#ffffff"
            />
            <span className={css.OptionContainer__label}>Manage</span>
          </div>
        }
        onClick={onManageClick}
      />,
      <CollectionProofDownloadContainer {...collectionProofDownloadProps}>
        <InputOptions.Option
          label={
            <div className={css.OptionContainer}>
              <InlineSVG
                src="img/interface/download.svg"
                width="16"
                height="16"
                fill="#ffffff"
              />
              <span className={css.OptionContainer__label}>Download</span>
            </div>
          }
        />
      </CollectionProofDownloadContainer>,
    ]}
    buttonStyle={{
      right: '5px',
    }}
  />
);


export default CollectionProofMenu;
