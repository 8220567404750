/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Component } from 'react';
import { InlineSVG } from '../../components/InlineSVG';
import {
  Heading,
  Subheading,
} from '../../components/Page';
import { Button } from '../../components/Button';
import Form from '../../components/Form';
import Switch from '../../components/Switch';
import Input from '../../components/Input';
import FormControl from '../../components/FormControl';
import { Translation } from '../../components/Text';
import Reveal from '../../components/Reveal';
import { addWebURL } from '../../components/ProofSetup/utils/addFile';
import requestProofSetup from '../../components/ProofSetup/utils/requestProofSetup';

class UrlImportContainer extends Component {
  state = {
    url: '',
    checkingUrl: false,
    urlErrors: [],
    showAdvanced: false,
    basicAuth: false,
    username: '',
    password: '',
  };

  validateUrlDebounced = window.debounce(() => {
    this.validateUrl();
  }, 500);

  bridge = window.__pageproof_bridge__;

  componentDidMount() {
    const parameters = this.getQueryParams('parameters');
    if (parameters && parameters.defaults && parameters.defaults.previousUrl) {
      this.setState({ url: parameters.defaults.previousUrl });
    }
  }

  onChangeInput(key) {
    return (value) => {
      this.setState({
        [key]: value,
      }, () => {
        this.validateUrlDebounced();
      });
    };
  }

  getFullUrl() {
    if (this.state.basicAuth) {
      return window.generalfunctions_setUriCredentials(
        this.state.url,
        this.state.username,
        this.state.password
      );
    } else {
      return this.state.url;
    }
  }

  validateUrl = () => {
    if (!this.state.url) {
      return;
    }
    const { backendService } = this.bridge;
    this.setState({
      checkingUrl: true,
    });
    backendService
      .fetch('import.url.validate', {
        Url: this.getFullUrl(),
      })
      .data()
      .then((response) => {
        const isAuthRequired = response.ValidationCodes.indexOf('VALIDATION_AUTHORIZATION_NEEDED') !== -1;
        this.setState({
          checkingUrl: false,
          urlErrors: response.ValidationCodes,
          showAdvanced: this.state.showAdvanced || isAuthRequired,
          basicAuth: this.state.basicAuth || isAuthRequired,
        });
      });
  };

  back = () => {
    const {
      $location,
      $rootScope,
      NavigationService,
    } = this.bridge;
    if ($location.search().back) {
      $location.backward().url($location.search().back);
      $rootScope.$apply();
    } else {
      NavigationService.back(() => {
        $location.url('dashboard');
      });
    }
  }

  next = () => {
    const { $rootScope, $location } = this.bridge;

    const parameters = this.getQueryParams('parameters');
    const initialize = this.getQueryParams('initialize');

    requestProofSetup({ parameters, initialize }, () => {
      addWebURL(
        this.state.url,
        this.state.basicAuth
          ? { username: this.state.username, password: this.state.password }
          : null,
        initialize,
        parameters
      );

      $location.url('/create');
      $rootScope.$apply();
    });
  }

  // Helper function to get query parameters
  getQueryParams = (key) => {
    const { $location } = this.bridge;
    const queryParams = $location.search();
    return queryParams[key] ? JSON.parse(queryParams[key]) : null;
  };

  toggleStateBoolean(key) {
    return () => {
      this.setState(state => ({
        [key]: !state[key],
      }), () => {
        this.validateUrlDebounced();
      });
    };
  }

  render() {
    const {
      url,
      checkingUrl,
      urlErrors,
      showAdvanced,
      basicAuth,
      username,
      password,
    } = this.state;
    const { proofType } = this.props;

    const complete = (url && !checkingUrl && !urlErrors.length);
    return (
      <Form>
        <div className="UrlImport">
          <Heading title={<Translation value={`import.url.heading.${proofType}`} />} />
          <div className="UrlImport__input">
            <FormControl
              label={<Translation value="import.url.label" />}
              message={checkingUrl ? <Translation value="import.url.checking" /> : null}
            >
              <Button
                variant="icon"
                autoWidth
                label={
                  <InlineSVG
                    src="/img/content/proof/icons/metadata/more.svg"
                    className="UrlImport__more-icon"
                  />
                }
                className="UrlImport__more-button"
                onClick={this.toggleStateBoolean('showAdvanced')}
              />
              <Input
                onChange={this.onChangeInput('url')}
                value={url}
                placeholder="https://example.com"
                autoFocus
              />
            </FormControl>
            <Reveal
              align="bottom"
              visible={showAdvanced}
            >
              <div className="UrlImport__basic-auth">
                <div style={{ float: 'right' }}>
                  <Switch
                    value={basicAuth}
                    onChange={this.toggleStateBoolean('basicAuth')}
                  />
                </div>
                <Subheading
                  title={<Translation value="import.url.basic-auth.title" />}
                  description={<Translation value="import.url.basic-auth.description" />}
                />
                <Reveal
                  align="bottom"
                  visible={basicAuth}
                >
                  <div style={{ overflow: 'hidden' }}>
                    <FormControl
                      compact
                      label={<Translation value="import.url.username.label" />}
                    >
                      <Input
                        onChange={this.onChangeInput('username')}
                        value={username}
                      />
                    </FormControl>
                    <FormControl
                      compact
                      label={<Translation value="import.url.password.label" />}
                    >
                      <Input
                        type="password"
                        onChange={this.onChangeInput('password')}
                        value={password}
                      />
                    </FormControl>
                  </div>
                </Reveal>
              </div>
            </Reveal>
          </div>
          {urlErrors.length > 0 && (
            <div className="UrlImport__validation">
              <Subheading title={<Translation value="import.url.subheading" />} />
              {urlErrors.map((error, index) => (
                <div
                  className="UrlImport__validation__error"
                  key={index}
                >
                  <div className="UrlImport__validation__icon">
                    <div className="UrlImport__validation__icon__circle">
                      <InlineSVG src="/img/interface/close-icon.svg" />
                    </div>
                  </div>
                  <div className="UrlImport__validation__text">
                    <Translation value={`import.url.error.${error}`} />
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="form-actions">
            <Button
              variant="text"
              label={<Translation value="button.back" />}
              onClick={this.back}
            />
            <Button
              variant="primary"
              disabled={!complete}
              label={<Translation value="button.next" />}
              onClick={this.next}
            />
          </div>
        </div>
      </Form>
    );
  }
}

export default UrlImportContainer;
