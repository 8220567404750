/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { InlineSVG } from '../../InlineSVG';
import randomId from '../utils/randomId';
import css from './Toaster.scss';

function Toaster({ render, toasts, onDismiss }) {
  let currentToast = null;

  if (toasts && toasts.length) {
    [currentToast] = toasts.filter(toast => (toast.type in render));
  }

  return (
    <div className={css.Toaster}>
      <CSSTransitionGroup
        component="div"
        transitionName={{
          enter: css['Toast--enter'],
          enterActive: css['Toast--enter-active'],
          leave: css['Toast--leave'],
          leaveActive: css['Toast--leave-active'],
        }}
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}
      >
        {currentToast
          ? (
            <button
              key={currentToast.id}
              className={css.Toast}
              type="button"
              onClick={() => {
                if (currentToast.onDismiss) {
                  currentToast.onDismiss(currentToast);
                }
                if (onDismiss) {
                  const remainingToasts = [...toasts];
                  remainingToasts.splice(remainingToasts.indexOf(currentToast), 1);
                  onDismiss(remainingToasts, currentToast);
                }
              }}
            >
              <InlineSVG
                src="/img/interface/menu-close-icon.svg"
                className={css.Toast__closeIcon}
              />
              {render[currentToast.type](currentToast.props)}
            </button>
          )
          : <span />
        }
      </CSSTransitionGroup>
    </div>
  );
}

Toaster.addToast = (toasts, type, props, onDismiss) => {
  const id = randomId();
  return [
    ...(toasts || []),
    {
      id,
      type,
      props: typeof props === 'function' ? props() : (props || {}),
      onDismiss,
    },
  ];
};

Toaster.removeToast = (toasts, type) => {
  if (!toasts) {
    return [];
  }
  return toasts.filter(toast => toast.type !== type);
};

Toaster.addOrUpdateToast = (toasts, type, props, onDismiss) => {
  toasts = toasts || []; // eslint-disable-line no-param-reassign
  const [existingToast] = toasts.filter(toast => toast.type === type);
  if (!existingToast) {
    return Toaster.addToast(toasts, type, props, onDismiss);
  }
  const replacementIndex = toasts.indexOf(existingToast);
  return [
    ...toasts.slice(0, replacementIndex),
    {
      id: existingToast.id,
      type,
      props: typeof props === 'function' ? props(existingToast.props) : (props || {}),
      onDismiss: (existingToast.onDismiss || onDismiss)
        ? (...args) => {
          if (existingToast.onDismiss) {
            existingToast.onDismiss(...args);
          }
          if (onDismiss) {
            onDismiss(...args);
          }
        }
        : undefined,
    },
    ...toasts.slice(replacementIndex + 1),
  ];
};

export default Toaster;

/*

<Toasts
  render={{
    'hello-world': ({ motd }) => (
      <div>Hello World: {motd}</div>
    ),
  }}
  toasts={[
    {
      id: 'abc1',
      type: 'hello-world',
      props: {
        motd: 'You are cool',
      },
      onDismiss() {
        alert('Dismissed!');
      },
    }
  ]}
  onDismiss={(toasts, dismissedToast) => {
    // Called after toast.onDismiss()
  }}
/>

*/
