/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import createIcon from '../../Icon/createIcon';

export default createIcon({
  displayName: 'Thumbs up orange icon',
  src: 'img/icons/thumbs-up.svg',
  defaultSize: 20,
  activeColor: '#f7a970',
});
