/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import { InlineSVG } from '../InlineSVG';
import css from './Button.scss';

function Button({
  arrow,
  offset,
  onClick,
  variant,
  ...props
}) {
  return (
    <button
      className={classname(css.Button, {
        [css['Button--' + variant]]: variant,
      })}
      onClick={onClick}
      aria-label={props.ariaLabel}
    >
      <InlineSVG
        className={css.Icon}
        src="img/content/proof/icons/metadata/more.svg"
      />
      <div
        className={classname(css.Arrow, {
          [css['Arrow--show']]: arrow,
          [css['Arrow--' + variant]]: variant,
        })}
        style={{
          marginTop: offset,
        }}
      />
    </button>
  );
}

Button.defaultProps = {
  variant: 'default',
};

if (process.env.NODE_ENV !== 'production') {
  Button.propTypes = {
    variant: PropTypes.string,
  };
}

export default Button;
