/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InlineSVG } from '../../InlineSVG';
import css from './LinkCheckURLTooltip.scss';
import Tooltip from '../../Tooltip';
import Translation from '../../Text/Translation';

const LinkCheckURLTooltip = ({ url }) => {
  const [copied, setCopied] = useState(false);
  const shorterURL = url.slice(url.indexOf('://') + 3); // https://example.com -> example.com
  return (
    <Tooltip
      right
      center
      up
      arrow
      offset={15}
      popover
      variant="light"
      title={
        <div className={css.LinkCheckURLTooltip__container}>
          <span>
            {copied ? [url, Translation.text('proof.page.info.link-check.copied')].join(' ') : url }
          </span>
          <div
            className={css.LinkCheckURLTooltip__copy}
            onClick={() => {
              window.generalfunction_copyToClipboard(url);
              setCopied(true);
            }}
          >
            <InlineSVG
              src="img/interface/copy.svg"
              width={20}
              height={20}
              fill="#000"
            />
          </div>
        </div>
      }
    >
      <div className={css.LinkCheckURLTooltip__url}>
        {shorterURL}
      </div>
    </Tooltip>
  );
};

if (process.env.NODE_ENV === 'development') {
  LinkCheckURLTooltip.propTypes = {
    url: PropTypes.string,
  };
}

export default LinkCheckURLTooltip;
