import React, { Fragment } from 'react';
import { KeepVirtualListItemMounted } from './KeepVirtualListItemMounted';
import Wrapper from '../../../components/Comment/Wrapper';
import CreateCommentContainer from '../../../components/Comment/CreateComment/Container';
import { getMediaTimeAndDuration } from '../utils/getMediaTimeAndDuration';

/* eslint-disable no-param-reassign */
export function CreateComment({
  pageNumber,
  proofCtrl,
  proofHelper,
}) {
  const {
    proof,
    permissions,
    isVideo,
    getMentionSuggestions,
    validateAttachmentType,
    validateAttachmentSize,
    getTimecodes,
    getMediaPin,
    safeApply,
  } = proofHelper;

  const firstMediaPin = getMediaPin(proofCtrl.temporaryComment);
  const mediaTime = getMediaTimeAndDuration(proofCtrl.temporaryComment, firstMediaPin);

  return (
    <Fragment>
      <KeepVirtualListItemMounted />
      <Wrapper>
        <CreateCommentContainer
          ref={(ref) => {
            if (ref) {
              proofCtrl._reactCreateCommentContainerRef = ref;
            }
          }}
          id={`create-comment-${proof.id}-${pageNumber}`}
          initialValue={proofCtrl.temporaryComment.comment || ''}
          mentionSuggestions={getMentionSuggestions}
          user={{
            id: proofCtrl.user.id,
            email: proofCtrl.user.email,
          }}
          mediaTime={mediaTime ? mediaTime.time : undefined}
          mediaDuration={mediaTime ? mediaTime.duration : undefined}
          mediaDetailed={isVideo}
          canAttach
          onValidateAttachmentType={validateAttachmentType}
          onValidateAttachmentSize={validateAttachmentSize}
          canLabel={proofCtrl.temporaryComment.pins.some(pin => !pin.fill && pin.x2 !== null && pin.y2 !== null)}
          pinCount={proofCtrl.temporaryComment.pins.length}
          canSave={proofCtrl.temporaryComment.pins.length > 0 || proofCtrl.interactionMode === 'general'}
          canTogglePrivate={proofCtrl.$getCanCreatePrivateComment(proof, permissions)}
          timecodes={getTimecodes(proofCtrl.temporaryComment)}
          isSelected
          onCreate={({ value, attachments, isPrivate, mentionedIds }) => {
            const comment = proofCtrl.temporaryComment;
            let newAttachments = [];
            if (attachments && attachments.length) {
              attachments.forEach((attachment) => {
                const newAttachment = new (proofCtrl.$$.PPProofCommentAttachment)();
                newAttachment.updateFromFile(attachment);
                newAttachments = [...newAttachments, newAttachment];
              });
              comment.attachments = newAttachments;
            }
            comment.isPrivate = isPrivate;
            comment.encryptedComment = value;
            comment.comment = value;
            comment.mentionedIds = mentionedIds;
            proofCtrl.finishCreateComment(comment);
            safeApply();
          }}
          onCancel={() => {
            proofCtrl.cancelCreateComment();
            safeApply();
          }}
          framesPerSecond={proof.framesPerSecond}
        />
      </Wrapper>
    </Fragment>
  );
}
