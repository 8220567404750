/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Enum } from '@pageproof/sdk';
import getSDKInstance from '../../util/sdk';
import { openModal } from '../../util/modal';
import NavigationMenu from './NavigationMenu';
import CollectionManageBox from './CollectionManageBox';
import CollectionManageBoxWithEmail from './CollectionManageBoxWithEmail';
import BulkNudgeProofsModal from '../BulkNudgeProofsModal';
import { Separator } from '../../components/PopupMenu';
import Switch from '../../components/Switch';
import styles from './CollectionManageStyles.scss';
import { hasUsersThatCanBeNudged } from '../../features';
import InputOptionsSearchSelector from '../../components/InputOptionsSearchSelector';
import NextAvatar from '../NextAvatar';
import { MAX_COLLECTION_NAME_LENGTH } from '../../util/constants';

class CollectionManageContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeBoxId: null,
      activeEmailBox: {},
      newEmailsStr: '',
      group: props.group,
      invalidEmails: [],
      validEmails: [],
      initInputField: false,
      textMessageValue: '',
      folders: [],
    };

    this.client = getSDKInstance();
    this.bridge = window.__pageproof_bridge__;
    this.user = this.bridge.userService.getUser();
  }

  componentWillMount() {
    this.getFolderNames();
  }

  getFolderNames() {
    this.client.dashboard.groups.current()
      .then(((groups) => {
        const folderNames = [
          ...new Set(
            groups
              .filter(group => !!group.folder)
              .map(group => group.folder.name)
              .sort((a, b) => {
                const lowerCaseA = a.toLowerCase();
                const lowerCaseB = b.toLowerCase();

                if (lowerCaseA < lowerCaseB) {
                  return -1;
                }
                if (lowerCaseA > lowerCaseB) {
                  return 1;
                }
                return 0;
              })
          ),
        ];
        this.setState({
          folders: folderNames.map(name => ({
            id: name,
            name,
          })),
        });
      }));
  }

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.group !== this.state.group) {
      this.refreshProps(nextProps);
    }
  }

  setInput = (name, value) => {
    const {
      group,
    } = this.state;

    if (name === 'newOwnersOrReviewersOrApprover') {
      this.state.newEmailsStr = value;
    } else {
      group[name] = value;
    }

    this.setState({
      ...this.state,
      invalidEmails: [],
      validEmails: [],
      group,
      initInputField: true,
    });
  };

  toggleConfirmBox = (name) => {
    const { ownedInProofingProofsCount, ownedProofsCount } = this.props.group;
    const isUserAdminOfGroupTeam = this.props.group.isUserAdminOfGroupTeam(this.client.session.user);
    const activeEmailBox = {
      id: name || null,
      name: isUserAdminOfGroupTeam ? `${name}-by-admin` : name,
      isSingle: name === 'replace-approver',
      proofCount: name === 'replace-approver' ? ownedInProofingProofsCount : ownedProofsCount,
      isActive: ['add-owner', 'add-reviewer', 'replace-approver'].includes(name),
    };
    const activeBoxId = ['name', 'folder', 'archive', 'reopen', 'nudge-all'].includes(name) ? name : null;
    this.setState({
      activeBoxId,
      activeEmailBox,
      initInputField: true,
      validEmails: [],
      invalidEmails: [],
    });
  };

  addOwnerOrReviewersOrApprover = (id) => {
    const {
      group,
      newEmailsStr,
    } = this.state;

    if (!newEmailsStr) {
      return;
    }

    const newEmailsArr = id === 'replace-approver'
      ? [newEmailsStr.toLowerCase()]
      : window.getEmailArr(newEmailsStr.toLowerCase());

    const data = [];
    const invalidEmails = [];
    const validEmails = [];
    newEmailsArr.forEach((email) => {
      if (window.validateEmail(email)) {
        data.push({ email });
        validEmails.push(email);
      } else {
        invalidEmails.push(email);
      }
    });

    this.setState({
      invalidEmails,
      validEmails,
      initInputField: false,
    });

    switch (id) {
    case 'add-owner':
      this.client.proofs.groups.owners.add(group.id, data)
        .then(() => {
          this.setState({
            newEmailsStr: '',
          });
        });
      break;
    case 'add-reviewer':
      this.client.proofs.groups.workflows.addReviewers(group.id, data)
        .then(() => {
          this.setState({
            newEmailsStr: '',
          });
        });
      break;
    case 'replace-approver':
      this.client.proofs.groups.workflows.replaceApprover(group.id, data[0])
        .then(() => {
          this.setState({
            newEmailsStr: '',
          });
        });
      break;
    default:
      break;
    }
  }

  updateMeta(key, newValue) {
    const {
      group,
    } = this.state;
    const value = newValue || group[key];
    const data = {
      [key]: value,
    };

    return this.client.proofs.groups.update(group.id, data)
      .then(() => this.emitEvent(key, data));
  }

  refreshProps(props) {
    this.setState({
      group: props.group,
      activeEmailBox: {},
      activeBoxId: null,
      newEmailsStr: '',
      validEmails: [],
      invalidEmails: [],
    });
  }

  emitEvent(key, data) {
    // emit events for dashboard to update collection tab display data without requesting backend
    data.groupId = this.props.group.id; // eslint-disable-line
    this.bridge.$rootScope.$broadcast(`collection.${key}`, data);
  }

  archive(archiveProofs = false) {
    this.client.proofs.groups.archive(this.props.group.id, { archiveProofs })
      .then(() => {
        this.setInput('isArchived', true);
        this.emitEvent('archived', { isArchived: true });
        this.toggleConfirmBox();
      });
  }

  reopen() {
    this.client.proofs.groups.reopen(this.props.group.id)
      .then(() => {
        this.setInput('isArchived', false);
        this.emitEvent('reopened', { isArchived: false });
        this.toggleConfirmBox();
      });
  }

  nudgeAll(proofs, message) {
    if (proofs.length === 0) {
      this.toggleConfirmBox();
      return;
    }

    const bulkNudgeProofsModal = openModal(
      <BulkNudgeProofsModal
        proofs={proofs}
        nudgeMessage={message}
        onClose={() => {
          bulkNudgeProofsModal.destroy();
          this.emitEvent('nudged', {});
          this.toggleConfirmBox();
        }}
      />,
      null,
      false,
    );
  }

  render() {
    const {
      group,
      activeBoxId,
      activeEmailBox,
      newEmailsStr,
      validEmails,
      invalidEmails,
      initInputField,
      textMessageValue,
      folders,
    } = this.state;

    const { isOwnedByTeam: isOwnedByTeamOn, ownedProofsCount } = this.props.group;
    const { isArchived, isOwnedByTeam } = group;
    const isUserAdminOfGroupTeam = this.props.group.isUserAdminOfGroupTeam(this.client.session.user);
    const canShareWithTeam = (
      !!this.user.teamId &&
      (this.user.teamId === group.teamId) &&
      (isOwnedByTeamOn || group.createdBy === this.user.id)
    );
    const isUserAnOwnerInCollection = group.proofs.some(proof => proof.ownerUserIds.includes(this.user.id));
    const canDoBulkActions = ownedProofsCount && (isUserAdminOfGroupTeam || isUserAnOwnerInCollection);
    const proofsThatCanBeNudged = group.proofs
      .filter(({ ownerUserIds, status, decisionSummary }) => (
        (isUserAdminOfGroupTeam || ownerUserIds.includes(this.user.id)) &&
        [Enum.ProofStatus.PROOFING, Enum.ProofStatus.FINAL_APPROVING].includes(status) &&
        hasUsersThatCanBeNudged(decisionSummary, this.user.id)
      ));

    const menuItems = [
      {
        id: 'heading-setup',
        name: 'setup',
        isHeading: true,
      },
      {
        id: 'created-by',
        name: 'created-by',
        isLabel: true,
        showTooltip: false,
        sideIcon: (
          <NextAvatar
            id={group.createdBy}
            size={35}
            showNameAndEmail
          />
        ),
      },
      {
        id: 'name',
        heading: 'collection.manage.name.heading',
        message: 'collection.manage.name.message',
        placeholder: 'collection.manage.name.heading',
        name: 'title',
        value: group.name,
        onClick: () => this.updateMeta('name'),
        buttonLabel: 'button.update',
        onChange: val => this.setInput('name', val),
        maxLength: MAX_COLLECTION_NAME_LENGTH,
      },
      {
        id: 'folder',
        name: 'folder',
        heading: 'collection.manage.folder.heading',
        message: 'collection.manage.folder.message',
        placeholder: 'collection.manage.folder.placeholder',
        value: group.folder && group.folder.name,
        onClick: () => {
          this.updateMeta('folder')
            .then(() => this.getFolderNames());
        },
        onChange: val => this.setInput('folder', { name: val }),
        textAreaTools: (
          <InputOptionsSearchSelector
            options={folders}
            onSelect={(folder) => {
              this.setInput('folder', { name: folder.name });
              this.updateMeta('folder', { name: folder.name });
            }}
          />
        ),
        onRemove: () => {
          this.setInput('folder', null);
          this.updateMeta('folder', null)
            .then(() => this.getFolderNames());
        },
        buttonLabel: 'button.add-to-folder',
        ...(group.folder && { removeButtonLabel: 'button.remove-from-folder' }),
      },
      {
        id: 'share-with-team',
        name: 'share-with-team',
        type: 'toggle',
        canShowToggle: isUserAdminOfGroupTeam || canShareWithTeam,
        switchValue: isOwnedByTeam,
        onChange: (value) => {
          this.setState({
            group: {
              ...group,
              isOwnedByTeam: value,
            },
          }, () => this.updateMeta('isOwnedByTeam'));
        },
      },
      {
        id: 'heading-bulk-action',
        name: 'bulk-actions',
        isHeading: true,
        separator: true,
        hide: !canDoBulkActions,
      },
      {
        id: 'nudge-all',
        name: 'nudge-all',
        heading: 'collection.manage.nudge-all.heading',
        message: isUserAdminOfGroupTeam ? 'collection.manage.nudge-all-by-admin.message' : 'collection.manage.nudge-all.message',
        placeholder: 'collection.manage.nudge-all.placeholder',
        value: textMessageValue,
        buttonLabel: 'button.nudge-all',
        onChange: value => this.setState({ textMessageValue: value }),
        onClick: () => this.nudgeAll(proofsThatCanBeNudged, textMessageValue),
        alwaysShowButton: true,
        hide: !proofsThatCanBeNudged.length,
      },
      {
        id: 'add-owner',
        name: 'add-owner',
        hide: !canDoBulkActions,
      },
      {
        id: 'add-reviewer',
        name: 'add-reviewer',
        hide: !canDoBulkActions,
      },
      {
        id: 'replace-approver',
        name: 'replace-approver',
        hide: (!canDoBulkActions || !group.ownedInProofingProofsCount),
      },
      {
        id: 'heading-close-down',
        name: 'close-down',
        isHeading: true,
        separator: true,
      },
      {
        id: 'archive',
        name: 'archive',
        hide: isArchived,
        heading: 'collection.manage.archive.heading',
        message: 'collection.manage.archive.message',
        buttonLabel: 'button.archive',
        alwaysShowButton: true,
        confirmButtons: [
          {
            id: 'proofs-too',
            type: 'primary',
            label: 'collection.manage.confirm.button.archive.proofs-too',
            activeLabel: 'collection.manage.confirm.button.archive.proofs-too.active',
            onClick: () => this.archive(true),
          },
          {
            id: 'collections-only',
            type: 'primary',
            label: 'collection.manage.confirm.button.archive.collections-only',
            activeLabel: 'collection.manage.confirm.button.archive.collections-only.active',
            onClick: () => this.archive(false),
          },
        ],
      },
      {
        id: 'reopen',
        name: 'reopen',
        hide: !isArchived,
        heading: 'collection.manage.reopen.heading',
        message: 'collection.manage.reopen.message',
        buttonLabel: 'button.reopen',
        onClick: () => this.reopen(),
        alwaysShowButton: true,
      },
    ];

    return (
      <div className={styles.CollectionManage}>
        {menuItems.map(item => (
          !item.hide &&
          <Fragment key={item.id}>
            {item.separator &&
              <Separator styles={{ margin: '20px 0' }} />
            }
            {item.type !== 'toggle' &&
              <NavigationMenu
                id={item.id}
                name={item.name}
                showTooltip={item.showTooltip}
                isHeading={item.isHeading}
                isLabel={item.isLabel}
                onClickToggle={!item.isLabel && this.toggleConfirmBox}
                sideIcon={item.sideIcon}
              />
            }
            {item.type === 'toggle' && item.canShowToggle &&
              <div className={styles.CollectionManage__switch}>
                <Switch
                  value={item.switchValue}
                  onChange={value => item.onChange(value)}
                  onColor="dark-green"
                  offColor="dark-grey"
                  message={`collection.manage.${item.name}`}
                  messageColor="white"
                />
              </div>
            }
          </Fragment>
        ))}
        <CollectionManageBoxWithEmail
          id={activeEmailBox.id}
          name={activeEmailBox.name}
          show={activeEmailBox.isActive}
          ownedCount={activeEmailBox.proofCount}
          validEmails={validEmails}
          invalidEmails={invalidEmails}
          initValue={initInputField}
          isSingle={activeEmailBox.isSingle}
          fieldValue={newEmailsStr}
          onAdd={() => this.addOwnerOrReviewersOrApprover(activeEmailBox.id)}
          onCancel={() => this.toggleConfirmBox()}
          onChange={val => this.setInput('newOwnersOrReviewersOrApprover', val)}
        />

        {menuItems.map(box => (
          <CollectionManageBox
            key={box.id}
            id={box.id}
            show={box.id === activeBoxId}
            value={box.id === activeBoxId ? box.value : ''}
            heading={box.heading}
            message={box.message}
            placeholder={box.placeholder}
            buttonLabel={box.buttonLabel}
            confirmButtons={box.confirmButtons}
            onClick={box.onClick}
            onUpdate={box.onUpdate}
            onChange={box.onChange}
            onCancel={() => this.toggleConfirmBox()}
            textAreaTools={box.textAreaTools}
            removeButtonLabel={box.removeButtonLabel}
            onRemove={box.onRemove}
            alwaysShowButton={box.alwaysShowButton}
            maxLength={box.maxLength}
          />
        ))}
      </div>
    );
  }
}

if (process.env.NODE_ENV !== 'production') {
  CollectionManageContainer.propTypes = {
    group: PropTypes.objectOf(PropTypes.any).isRequired,
  };
}

export default CollectionManageContainer;
