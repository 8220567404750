/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import { InlineSVG } from '../../InlineSVG';
import css from './Scroll.scss';

function ScrollLeft() {
  return (
    <span className={css.Scroll}>
      <InlineSVG src="img/content/proof/icons/metadata/left.svg" />
    </span>
  );
}

export default ScrollLeft;
