/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import { InlineSVG } from '../InlineSVG';
import DateTimePicker from '../DateTimePicker/DateTimePicker';
import ScopedStylesheet from '../ScopedStylesheet';
import styles from './DateTimePickerIconButton.scss';

const DateTimePickerIconButton = ({ size, className, onChange }) => {
  const dateTimePickerRef = useRef();

  return (
    <ScopedStylesheet
      className={classname(styles.DateTimePickerIconButton, className)}
      styles={{
        svg: {
          width: size,
          height: size,
        },
      }}
      onClick={() => dateTimePickerRef.current.datePicker.show()}
    >
      <DateTimePicker
        onChange={onChange}
        ref={dateTimePickerRef}
      >
        <InlineSVG
          className={styles.DateTimePickerIconButton__calendarIcon}
          src="img/interface/calendar.svg"
        />
      </DateTimePicker>
    </ScopedStylesheet>
  );
};

DateTimePickerIconButton.defaultProps = {
  size: 20,
  className: '',
};

DateTimePickerIconButton.propTypes = {
  onChange: PropTypes.func,
  size: PropTypes.number,
  className: PropTypes.string,
};

export default DateTimePickerIconButton;
