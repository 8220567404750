/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { InlineSVG } from '../../components/InlineSVG';
import Dropdown, {
  Option as DropdownOption,
  Separator as DropdownSeparator,
  Label as DropdownLabel,
  OptionLabel as DropdownOptionLabel,
} from '../../components/Dropdown';
import Switch from '../../components/Switch/Switch';
import devices from '../../resources/devices';
import {Translation} from '../../components/Text';
import {
  match as matchCustomDevice,
} from '../../util/custom-device';
import useKeyboardShortcut from '../../hooks/useKeyboardShortcut';

const defaultOptions = [
  {
    icon: 'devices',
    id: null,
    name: <Translation value="device.responsive" />,
    description: <Translation value="device.responsive.description" />,
    rotate: false,
  },
  null,
  ...devices,
];

const devicesById = {};
devices.forEach((device) => {
  devicesById[device.id] = device;
});

function DeviceDropdown({
  device: selectedDevice,
  customDevices,
  orientation,
  isFitViewport,
  onToggleFitViewport,
  onDeviceChange,
  onOrientationChange,
  ...props,
}) {
  const orientations = {
    vertical: {
      rotated: 'horizontal',
      rotatedDescription: <Translation value="device.orientation.horizontal" />,
    },
    horizontal: {
      rotated: 'vertical',
      rotatedDescription: <Translation value="device.orientation.vertical" />,
    },
  };
  const rotateStyles = {
    transform: 'rotate(-90deg) translateX(1px)',
  };
  const rotateOrientation = orientations[orientation].rotated;
  const canRotate = (
    onOrientationChange && // As long as there's an onOrientationChange callback
    devicesById[selectedDevice] &&
    devicesById[selectedDevice].rotate // If the currently selected device is allowed to rotate
  );
  const options = [
    ...defaultOptions,
  ];

  options.splice(1, 0, ...customDevices.map(custom => ({
    icon: 'custom',
    ...custom,
    id: custom.width + 'x' + custom.height,
  })));

  const customDevice = matchCustomDevice(selectedDevice);
  if (customDevice) {
    const isNamed = customDevices.some(custom => (
      custom.width === customDevice.width &&
      custom.height === customDevice.height
    ));
    if (!isNamed) {
      options.splice(customDevices.length + 1, 0, {
        icon: 'custom',
        id: selectedDevice,
        name: <Translation value="device.custom" />,
        width: customDevice.width,
        height: customDevice.height,
        rotate: false,
      });
    }
  }

  const cycleDevice = (isReverse) => {
    const currentDeviceIndex = devices.findIndex(option => option.id === selectedDevice);

    if (currentDeviceIndex === -1) {
      onDeviceChange(devices[0].id);
      return;
    }

    const nextDeviceIndex = (currentDeviceIndex + (isReverse ? -1 : 1) + devices.length) % devices.length;

    onDeviceChange(devices[nextDeviceIndex].id);
  };

  useKeyboardShortcut('v', () => {
    cycleDevice();
  });

  useKeyboardShortcut('V', () => {
    cycleDevice(true);
  });

  return (
    <Dropdown
      {...props}
      selected={selectedDevice}
      placeholder={
        <DropdownOption
          icon="/img/interface/devices/devices.svg"
          label={
            <DropdownOptionLabel
              title={<Translation value="device.select-device" />}
            />
          }
        />
      }
    >
      {canRotate &&
        <DropdownOption
          icon={`/img/interface/devices/rotate-${rotateOrientation}.svg`}
          label={
            <DropdownOptionLabel
              title={<Translation value="device.rotate-orientation" />}
              description={orientations[orientation].rotatedDescription}
            />
          }
          onClick={() => onOrientationChange(rotateOrientation)}
        />}
      <DropdownOption
        icon="/img/interface/fit-viewport.svg"
        label={
          <DropdownOptionLabel
            title={<Translation value="device.fit-to-screen.title" />}
            description={<Translation value="device.fit-to-screen.description" />}
          />
        }
        button={
          <div onClick={event => event.stopPropagation()}>
            <Switch
              value={isFitViewport}
              size="tiny"
              minimal
              onChange={onToggleFitViewport}
            />
          </div>
        }
        onClick={onToggleFitViewport}
      />
      <DropdownSeparator />
      {options.map((device, index) => (
        // eslint-disable-next-line no-nested-ternary
        typeof device === 'string'
          ? <DropdownLabel
            key={index}
            label={device}
          />
          : device === null
            ? <DropdownSeparator
              key={index}
            />
            : <DropdownOption
              key={index}
              value={device.id}
              icon={
                <div
                  style={{
                    transition: 'transform .25s ease-in-out',
                    ...(orientation === 'horizontal' && device.rotate ? rotateStyles : {}),
                  }}
                >
                  <InlineSVG src={`/img/interface/devices/${device.icon}.svg`} />
                </div>
              }
              label={
                <DropdownOptionLabel
                  title={device.name}
                  description={device.description || `${device.width} x ${device.height}`}
                />
              }
              onClick={() => onDeviceChange(device.id)}
            />
      ))}
    </Dropdown>
  );
}

DeviceDropdown.propTypes = {
  device: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.any,
  ]),
  orientation: PropTypes.oneOf([
    'vertical',
    'horizontal',
  ]),
  customDevices: PropTypes.arrayOf(PropTypes.object),
  onDeviceChange: PropTypes.func.isRequired,
  onOrientationChange: PropTypes.func,
};

DeviceDropdown.defaultProps = {
  device: null,
  orientation: 'vertical',
  customDevices: [],
};

export default DeviceDropdown;
