/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

/* eslint-disable */

import React, { Component } from 'react';
import Wrapper from '../../components/Comment/Wrapper';
import CreateComment from '../../components/Comment/CreateComment/Container';
import Comment from '../../components/Comment/Comment/Container';
import Reply from '../../components/Comment/Reply/Container';
import CreateReply from '../../components/Comment/CreateReply/Container';
import Replies from '../../components/Comment/Replies';
import getMetadata from '../../components/Comment/Metadata/getMetadata';
import NoComments from '../../components/NoComments';
import getTimecodeWithHyphen from '../../util/get-timecode-with-hyphen';
import { getAnnotationName } from '../CommentsPane/utils/pdfCommentImportUtils';
import { getMediaTimeAndDuration } from '../CommentsPane/utils/getMediaTimeAndDuration';

const UNSUPPORTED_VIEW_ATTACHMENT_EXTENSIONS = [
  'zip',
  'eml',
  'gif',
  'mp4',
  'mov',
  'm4v'
];

class ProofPageComments extends Component {
  state = {
    replyToCommentId: null,
  };

  constructor(props) {
    super(props);

    this.setProofAndPermissions(props);
    this.$rootScope = window.__pageproof_bridge__.$rootScope;
  }

  safeApply = () => {
    if (!this.proofCtrl.$$.$scope.$$phase) {
      this.proofCtrl.$$.$scope.$apply();
    }
  };

  setProofAndPermissions(props) {
    this.proofCtrl = props.getController();
    this.compareType = props.type;
    this.proof = this.compareType
      ? (
        this.compareType === 'left'
          ? this.proofCtrl.leftImageData.proofData
          : this.proofCtrl.rightImageData.proofData
      )
      : this.proofCtrl.proof;
    this.permissions = this.compareType
      ? (
        this.compareType === 'left'
          ? this.proofCtrl.leftImageData.permissions
          : this.proofCtrl.rightImageData.permissions
      )
      : this.proofCtrl.permissions;
    this.isVideo = this.proof.isVideo;
    this.isAudio = this.proof.isAudio;
    this.isMedia = this.isVideo || this.isAudio;
    this.isStatic = this.proof.isStatic;
    this.isWeb = this.proof.isWeb;
  }

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    const proofCtrl = nextProps.getController();
    const compareType = nextProps.type;
    const proof = compareType
      ? (
        compareType === 'left'
          ? proofCtrl.leftImageData.proofData
          : proofCtrl.rightImageData.proofData
      )
      : proofCtrl.proof;
    const permissions = compareType
      ? (
        compareType === 'left'
          ? proofCtrl.leftImageData.permissions
          : proofCtrl.rightImageData.permissions
      )
      : proofCtrl.permissions;
    if (proof.id !== this.proof.id || permissions !== this.permissions) {
      this.setProofAndPermissions(nextProps);
    }
  }

  validateAttachmentType = (attachment) => {
    try {
      return this.proofCtrl.validateAttachmentType(attachment);
    } finally {
      this.safeApply();
    }
  }

  validateAttachmentSize = (attachment) => {
    try {
      return this.proofCtrl.validateAttachmentSize(attachment);
    } finally {
      this.safeApply();
    }
  }

  hasCommentError(comment) {
    return (
      !comment.isSaved ||
      (!this.$rootScope.online &&
      (!comment.id ||
      (comment.snapshot && !comment.snapshot.id) ||
      (comment.attachment && !comment.attachment.id)))
    );
  }

  canEditComment(comment) {
    const {proofCtrl, proof, permissions} = this;
    return permissions.proofer.commentLevel.canEdit &&
      (comment.ownerId == proofCtrl.user.id ||
        (proof.canOthersEditComments &&
        (proof.isOwnerOrCoOwner || permissions.proofer.proofLevel.isFinalApprover)));
  }

  getAttachmentsProps(comment, attachments) {
    return attachments.map(attachment => this.getAttachmentProps(comment, attachment));
  }

  isAttachmentViewable(attachment) {
    if (!attachment) {
      return false;
    }
    return attachment.status === 'OK' &&
      !UNSUPPORTED_VIEW_ATTACHMENT_EXTENSIONS.includes(attachment.extension);
  }

  getAttachmentProps(comment, attachment) {
    const { proofCtrl, permissions } = this;
    const isUploading = !!attachment.$progress;
    const isProcessing = attachment.status === 'Queued' || attachment.status === 'Ripping';
    const hasChunks = attachment.chunks !== 0;
    return {
      id: attachment.id,
      name: attachment.name,
      isProcessing,
      isUploading,
      canDownload: hasChunks && permissions.proofer.proofLevel.canDownloadAttachments,
      canView: this.isAttachmentViewable(attachment),
      canDelete: attachment.id &&
                  this.canEditComment(comment),
      onView: () => {
        proofCtrl.previewAttachment(attachment, comment);
        this.safeApply();
      },
      onDownload: (onProgress) => {
        const promise = proofCtrl.downloadAttachment(attachment);
        promise.then(null, null, (progress) => {
          onProgress(progress);
        });
        this.safeApply();
        return promise;
      },
      onDelete: () => {
        proofCtrl.deleteAttachment(attachment, comment);
        this.safeApply();
      },
    };
  }

  updateAttachments(comment, attachments) {
    const proofCtrl = this.proofCtrl;
    let newAttachments = [];
    attachments.forEach(attachment => {
      const newAttachment = new (proofCtrl.$$.PPProofCommentAttachment)();
      newAttachment.updateFromFile(attachment);
      newAttachments = [...newAttachments, newAttachment];
    });
    comment.attachments = [...comment.attachments, ...newAttachments];
    proofCtrl.updateComment(comment, newAttachments);
  }

  getTimecodes(comment, isVideo, isMedia) {
    const { pins, isReply } = comment;
  
    if (!isMedia || isReply) {
      return null;
    }
  
    return [...pins].sort((pin1, pin2) => pin1.time - pin2.time).map(pin => getTimecodeWithHyphen(pin, isVideo, this.proof.framesPerSecond));
  };

  render() {
    const { proofCtrl, proof, permissions, isVideo, isMedia } = this;
    const { page, comments, create, filter, noCommentsOnPages, canAddCommentReplies } = this.props;
    const allowedUsersForMention = proof.allowedUsersForMention;
    const mentionSuggestions = allowedUsersForMention.map((user) => ({
      id: user.id,
      label: user.name || user.email || user.display, // "display" is for the @all/@everyone mentions
    }));
    const getMentionSuggestions = () => mentionSuggestions;

    const pageNumber = (page ? page.pageNumber : null) || 1;

    const canReply = (
      permissions.proofer.commentLevel.canReply &&
      canAddCommentReplies
    );

    const canDeleteComment = (commentOwnerId) =>
      permissions.proofer.commentLevel.canCreate &&
      (
        commentOwnerId === proofCtrl.user.id ||
        (proof.ownerCanDeleteComments && proof.isOwnerOrCoOwner)
      );
    
    const firstTemporaryMediaPin = (create && isMedia) ? proofCtrl.temporaryComment.pins.find(pin => typeof pin.time !== 'undefined' && typeof pin.duration !== 'undefined') : null;
    const mediaTime = getMediaTimeAndDuration(proofCtrl.temporaryComment, firstTemporaryMediaPin);

    return (
      <div className="app__comments__page">
        {create &&
          <Wrapper>
            <CreateComment
              ref={(ref) => {
                if (ref) {
                  this.proofCtrl._reactCreateCommentContainerRef = ref;
                }
              }}
              id={`create-comment-${proof.id}-${pageNumber}`}
              initialValue={proofCtrl.temporaryComment.comment || ''}
              mentionSuggestions={getMentionSuggestions}
              user={{
                id: proofCtrl.user.id,
                email: proofCtrl.user.email,
              }}
              mediaTime={mediaTime ? mediaTime.time : undefined}
              mediaDuration={mediaTime ? mediaTime.duration : undefined}
              mediaDetailed={isVideo}
              canAttach
              onValidateAttachmentType={this.validateAttachmentType}
              onValidateAttachmentSize={this.validateAttachmentSize}
              canLabel={proofCtrl.temporaryComment.pins.some(pin => !pin.fill && pin.x2 !== null && pin.y2 !== null)}
              pinCount={proofCtrl.temporaryComment.pins.length}
              canSave={proofCtrl.temporaryComment.pins.length > 0 || proofCtrl.interactionMode === 'general'}
              canTogglePrivate={proofCtrl.$getCanCreatePrivateComment(proof, permissions)}
              timecodes={this.getTimecodes(proofCtrl.temporaryComment, isVideo, isMedia)}
              isSelected
              onCreate={({value, attachments, isPrivate, mentionedIds}) => {
                const comment = proofCtrl.temporaryComment;
                let newAttachments = [];
                if (attachments && attachments.length) {
                  attachments.forEach(attachment => {
                    const newAttachment = new (proofCtrl.$$.PPProofCommentAttachment)();
                    newAttachment.updateFromFile(attachment);
                    newAttachments = [...newAttachments, newAttachment];
                  });
                  comment.attachments = newAttachments;
                }
                comment.isPrivate = isPrivate;
                comment.encryptedComment = comment.comment = value;
                comment.mentionedIds = mentionedIds;
                proofCtrl.finishCreateComment(comment);
                this.safeApply();
              }}
              onCancel={() => {
                proofCtrl.cancelCreateComment();
                this.safeApply();
              }}
              framesPerSecond={proof.framesPerSecond}
            />
          </Wrapper>
        }
        {comments && comments.map((comment, index) => {
          const sourceMetadata = comment.getSourceMetadata();
          const metadata = {
            ...comment.metadata,
            importSource: sourceMetadata && sourceMetadata.pdfImport && {
              importDate: comment.createdAt.toDate(),
              createdDate: sourceMetadata.pdfImport.createdDate && new Date(sourceMetadata.pdfImport.createdDate),
              lastModifiedDate: sourceMetadata.pdfImport.lastModifiedDate && new Date(sourceMetadata.pdfImport.lastModifiedDate),
              fileName: sourceMetadata.pdfImport.fileName,
              importer: {
                id: comment.ownerId,
                email: comment.ownerEmail,
              }
            }
          };

          const importMetadata = sourceMetadata && sourceMetadata.pdfImport && {
            type: 'pdf',
            author: sourceMetadata.pdfImport.author,
            // PDF comment import sets the comment text to a single space for empty annotations.
            annotationDisplayName: getAnnotationName(sourceMetadata.pdfImport.annotation),
          };
          const nextMarkOptions = proofCtrl.$getNextCommentStatusMarkOptions(permissions, comment)
            .map(mark => ({
              ...mark,
              icon: mark.state ? mark.type : null
            }));
          const firstMediaPin = isMedia ? comment.pins.find(pin => typeof pin.time !== 'undefined' && typeof pin.duration !== 'undefined') : null;
          const mediaTime = getMediaTimeAndDuration(comment, firstMediaPin);
          const parsedMetadata = getMetadata(metadata);

          return (
            <Wrapper key={comment.id || index}>
              <Comment
                id={`comment-${comment.id || 'creating'}`}
                user={{
                  id: comment.ownerId,
                  email: comment.ownerEmail,
                }}
                importMetadata={importMetadata}
                hasError={this.hasCommentError(comment)}
                pinCount={comment.pins.length}
                timecodes={this.getTimecodes(comment, isVideo, isMedia)}
                isLoading={!comment.decryptedComment && !comment.comment}
                mentionSuggestions={getMentionSuggestions}
                value={comment.comment || ''}
                date={comment.createdAt.toDate()}
                mediaTime={mediaTime ? mediaTime.mediaTime : undefined}
                mediaDuration={mediaTime ? mediaTime.mediaDuration : undefined}
                mediaDetailed={isVideo}
                editedDate={comment.editedDate}
                number={comment.number}
                editedUserId={comment.editedByUserId}
                showMark={
                  !comment.isPrivate &&
                  (
                    (
                      permissions.proofer.commentLevel.canViewChanged ||
                      (permissions.proofer.commentLevel.canViewDone && comment.isDone)
                    ) ||
                    (
                      permissions.proofer.commentLevel.canMarkAsChanged ||
                      permissions.proofer.commentLevel.canMarkAsDone
                    )
                  )
                }
                canMark={nextMarkOptions.length > 0}
                isPrivate={comment.isPrivate}
                mark={proofCtrl.$getCommentStatusMark(comment)}
                nextMarkOptions={nextMarkOptions}
                hasSnapshot={!!comment.snapshot}
                metadata={parsedMetadata.length > 0 && parsedMetadata}
                agrees={comment.agrees}
                canAgree={!!(
                  permissions.proofer.commentLevel.canAgree &&
                  comment.ownerId !== proofCtrl.user.id
                )}
                canReply={canReply}
                showAgrees
                doesAgree={!!(
                  comment.agrees.indexOf(proofCtrl.user.id) !== -1
                )}
                agreeCount={comment.agrees.length}
                attachments={comment.attachments && this.getAttachmentsProps(comment, comment.attachments)}
                canDelete={canDeleteComment(comment.ownerId)}
                canEdit={this.canEditComment(comment)}
                canLabel={comment.pins.some(pin => !pin.fill && pin.x2 !== null && pin.y2 !== null)}
                isSelected={comment.$selected}
                canAttach={this.canEditComment(comment)}
                canSelect
                canFlash
                onSelect={() => {
                  proofCtrl.selectComment(comment, false, this.compareType, true);
                  this.safeApply();
                }}
                onFlash={() => {
                  proofCtrl.flashComment(comment, this.compareType);
                  this.safeApply();
                }}
                onAgree={() => {
                  proofCtrl.agreeComment(comment);
                  this.safeApply();
                }}
                onMark={(type, state) => {
                  proofCtrl.markComment(comment, type, state);
                  this.safeApply();
                }}
                onOpenProfile={() => {
                  this.$rootScope.$broadcast('openUserDetails', {
                    proofId: proof.id,
                    userId: comment.ownerId,
                  });
                }}
                onValidateAttachmentType={this.validateAttachmentType}
                onValidateAttachmentSize={this.validateAttachmentSize}
                onAttach={(attachments) => this.updateAttachments(comment, attachments)}
                onSave={(value) => {
                  comment.decryptedComment = comment.comment = value;
                  proofCtrl.updateComment(comment, null);
                }}
                onDelete={() => {
                  proofCtrl.deleteComment(comment);
                  this.safeApply();
                }}
                onRestoreMetadata={proofCtrl.restoreMetadata && (() => {
                  proofCtrl.restoreMetadata(comment.getMetadata());
                  this.safeApply();
                })}
                onHashtagClick={(number) => proofCtrl.selectCommentByNumber(number, proof)}
                onHashtagHover={(number) => proofCtrl.getCommentDataByNumber(number, proof)}
                onReply={() => this.setState({ replyToCommentId: comment.id })}
                framesPerSecond={proof.framesPerSecond}
              />
              {comment.replies.length > 0 &&
                <Replies
                  replyCount={comment.replies.length}
                  onRemoveNoParentComment={() => {
                    proofCtrl.removeComment(comment);
                    proofCtrl.handleChangesAfterCommentRemoval();
                    this.safeApply();
                  }}
                  haveNoParentComment={comment.replies[0].haveNoParentComment}
                >
                  {(index) => {
                    const reply = comment.replies[index];

                    const replySourceMetadata = reply.getSourceMetadata();
                    const replyMetadata = {
                      importSource: replySourceMetadata && replySourceMetadata.pdfImport && {
                        importDate: reply.createdAt.toDate(),
                        createdDate: replySourceMetadata.pdfImport.createdDate && new Date(replySourceMetadata.pdfImport.createdDate),
                        lastModifiedDate: replySourceMetadata.pdfImport.lastModifiedDate && new Date(replySourceMetadata.pdfImport.lastModifiedDate),
                        fileName: replySourceMetadata.pdfImport.fileName,
                        importer: {
                          id: reply.ownerId,
                          email: reply.ownerEmail,
                        }
                      }
                    }
                    const replyHasMetadata = Object.values(replyMetadata).some(Boolean);
                    const replyImportMetadata = replySourceMetadata && replySourceMetadata.pdfImport && {
                      type: 'pdf',
                      author: replySourceMetadata.pdfImport.author,
                      // PDF comment import sets the comment text to a single space for empty annotations.
                      annotationDisplayName: getAnnotationName(replySourceMetadata.pdfImport.annotation),
                    };

                    return (
                      <Reply
                        id={`reply-${reply.id || 'creating'}`}
                        key={reply.id || index}
                        user={{
                          id: reply.ownerId,
                          email: reply.ownerEmail,
                        }}
                        canDelete={canDeleteComment(reply.ownerId)}
                        onDelete={() => {
                          proofCtrl.deleteComment(reply);
                          this.safeApply();
                        }}
                        date={reply.createdAt.toDate()}
                        importMetadata={replyImportMetadata}
                        metadata={replyHasMetadata && getMetadata(replyMetadata)}
                        editedDate={reply.editedDate}
                        editedUserId={reply.editedByUserId}
                        hasError={this.hasCommentError(reply)}
                        pinCount={comment.pins.length}
                        isLoading={!reply.decryptedComment && !reply.comment}
                        mentionSuggestions={getMentionSuggestions}
                        value={reply.comment || ''}
                        canEdit={this.canEditComment(reply)}
                        agrees={reply.agrees}
                        canAgree={!!(
                          reply.ownerId !== proofCtrl.user.id
                        )}
                        showAgrees
                        doesAgree={!!(
                          permissions.proofer.commentLevel.canAgree &&
                          reply.agrees.indexOf(proofCtrl.user.id) !== -1
                        )}
                        canSelect
                        agreeCount={reply.agrees.length}
                        canAttach={this.canEditComment(reply)}
                        attachments={reply.attachments && this.getAttachmentsProps(reply, reply.attachments)}
                        onOpenProfile={() => {
                          this.$rootScope.$broadcast('openUserDetails', {
                            proofId: proof.id,
                            userId: reply.ownerId,
                          });
                        }}
                        onSelect={() => {
                          if (comment.$selected) {
                            proofCtrl.flashComment(comment, this.compareType);
                          } else {
                            proofCtrl.selectComment(comment, false, this.compareType, true);
                          }
                          this.safeApply();
                        }}
                        onAgree={() => {
                          proofCtrl.agreeComment(reply);
                          this.safeApply();
                        }}
                        onValidateAttachmentType={this.validateAttachmentType}
                        onValidateAttachmentSize={this.validateAttachmentSize}
                        onAttach={(attachments) => this.updateAttachments(reply, attachments)}
                        onSave={(value) => {
                          reply.decryptedComment = reply.comment = value;
                          proofCtrl.updateComment(reply, null);
                        }}
                        onHashtagClick={(number) => proofCtrl.selectCommentByNumber(number, proof)}
                        onHashtagHover={(number) => proofCtrl.getCommentDataByNumber(number, proof)}
                        isPrivate={reply.isPrivate}
                      />
                    );
                  }}
                </Replies>
              }
              {(
                canReply &&
                (this.props.spacing !== 'condensed' || this.state.replyToCommentId === comment.id)
              ) &&
                <CreateReply
                  id={`create-reply-${comment.id || 'creating'}`}
                  mentionSuggestions={getMentionSuggestions}
                  initialValue=""
                  canAttach
                  initialVisibility={this.props.spacing === 'condensed'}
                  onValidateAttachmentType={this.validateAttachmentType}
                  onValidateAttachmentSize={this.validateAttachmentSize}
                  canTogglePrivate={!comment.isPrivate && proofCtrl.$getCanCreatePrivateComment(proof, permissions, comment)}
                  defaultIsPrivate={comment.isPrivate}
                  onCreate={({value, attachments, isPrivate, mentionedIds}) => {
                    this.setState({ replyToCommentId: null });
                    const reply = new (proofCtrl.$$.PPProofComment)();
                    reply.comment = value;
                    let newAttachments = [];
                    if (attachments && attachments.length) {
                      attachments.forEach(attachment => {
                        const newAttachment = new (proofCtrl.$$.PPProofCommentAttachment)();
                        newAttachment.updateFromFile(attachment);
                        newAttachments = [...newAttachments, newAttachment];
                      });
                      reply.attachments = newAttachments;
                    }
                    reply.isPrivate = isPrivate;
                    reply.mentionedIds = mentionedIds;
                    proofCtrl.replyComment(comment, reply);
                    this.safeApply();
                    return true;
                  }}
                />
              }
            </Wrapper>
          );
        })}
        {(!comments.length && !create && (this.isStatic || this.compareType)) &&
          <NoComments
            filter={page.comments.length
              ? filter
              : 'comments'
            }
            noCommentsOnPages={noCommentsOnPages}
          />
        }
        {((comments && comments.length > 0) || create) &&
          <div
            style={{
              height: 20,
            }}
          />
        }
      </div>
    );
  }
}

export default ProofPageComments;
