/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import { InlineSVG } from '../InlineSVG';
import { useI18n } from '../../hooks/useI18n';
import { useUserPreferences } from '../../hooks/useUserPreferences';
import i18n from '../../util/i18n';
import { Translation } from '../Text';
import { sdk } from '../../util/sdk';
import MainMenuOptionPicker from '../MainMenuOptionPicker';
import css from './MainMenuLanguageSwitcher.scss';

const availableLanguages = {
  'de-DE': { name: 'language-switcher.option.de', emoji: '🇩🇪' },
  'en-GB': { name: 'language-switcher.option.en-gb', emoji: '🇬🇧' },
  'en-US': { name: 'language-switcher.option.en', emoji: '🇺🇸' },
  'es-ES': { name: 'language-switcher.option.es', emoji: '🇪🇸' },
  'fr-FR': { name: 'language-switcher.option.fr', emoji: '🇫🇷' },
  'ja-JP': { name: 'language-switcher.option.ja', emoji: '🇯🇵' },
  'nl-NL': { name: 'language-switcher.option.nl', emoji: '🇳🇱' },
  'pt-BR': { name: 'language-switcher.option.pt-br', emoji: '🇧🇷' },
  'pt-PT': { name: 'language-switcher.option.pt', emoji: '🇵🇹' },
  'ru-RU': { name: 'language-switcher.option.ru', emoji: '🇷🇺' },
  'zh-CN': { name: 'language-switcher.option.zh-cn', emoji: '🇨🇳' },
  'zh-TW': { name: 'language-switcher.option.zh-tw', icon: '/img/icons/traditional-chinese-icon.svg' },
};

const previewLanguages = {};

export default function MainMenuLanguageSwitcher() {
  const { locale } = useI18n();
  const [, , { updateUserPreference }] = useUserPreferences();

  const isPageProofEmployee = (
    sdk.session &&
    sdk.session.user &&
    sdk.session.user.email.indexOf('@pageproof.com') !== -1
  );

  const totalLanguages = isPageProofEmployee
    ? { ...availableLanguages, ...previewLanguages }
    : availableLanguages;

  return (
    <div className={css.MainMenuLanguageSwitcher}>
      <MainMenuOptionPicker
        options={totalLanguages}
        selectedOptionId={locale}
        optionsHeader={<Translation value="language-switcher.title" />}
        onChange={(localLanguage) => {
          i18n.setLocale(localLanguage);
          updateUserPreference('appLanguage', localLanguage);
        }}
        icon={<InlineSVG src="/img/icons/material/language-24px.svg" />}
      />
    </div>
  );
}
