/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { InlineSVG } from '../../components/InlineSVG';
import Dropdown, {
  Option as DropdownOption,
  OptionLabel as DropdownOptionLabel,
} from '../../components/Dropdown';
import speeds from '../../resources/player-speeds.json';
import Tooltip from '../../components/Tooltip';
import Translation from '../../components/Text/Translation';

function VideoSpeedDropdown({
  selected,
  onChange,
  ...props,
}) {
  const [selectedSpeed] = speeds.filter(speed => speed.id === (selected || 'normal'));
  return (
    <Dropdown
      variant="icon-based"
      {...props}
      selected={selected}
      target={
        <span>
          <Tooltip title={<Translation value="proof.utilities.speed" />} up center>
            <div>
              <InlineSVG
                src={`/img/interface/speeds/${selectedSpeed.icon}.svg`}
              />
            </div>
          </Tooltip>
        </span>
      }
    >
      {speeds.map((speed, index) => (
        <DropdownOption
          key={index}
          value={speed.id}
          icon={speed.icon ? `/img/interface/speeds/${speed.icon}.svg` : null}
          label={
            <DropdownOptionLabel
              title={speed.name}
              description={speed.rate}
            />
          }
          onClick={() => onChange(speed)}
        />
      ))}
    </Dropdown>
  );
}

if (process.env.NODE_ENV !== 'production') {
  VideoSpeedDropdown.propTypes = {
    selected: PropTypes.string,
    direction: PropTypes.oneOf([
      'down',
      'up',
    ]),
    speeds: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func.isRequired,
  };
}

VideoSpeedDropdown.defaultProps = {
  selected: 'normal',
  direction: 'up',
  speeds: [],
};

export default VideoSpeedDropdown;
