/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { InlineSVG } from '../InlineSVG';
import { Translation } from '../Text';
import { Option, PopupMenu } from '../PopupMenu';
import css from './MainMenuOptionPicker.scss';
import Emoji from '../Emoji';

const MainMenuOptionPicker = ({
  onChange,
  options,
  selectedOptionId,
  icon,
  optionsHeader,
}) => (
  <PopupMenu
    maxWidth={280}
    options={
      <Fragment>
        {!!optionsHeader && (
          <Option
            label={optionsHeader}
            disabled
          />
        )}
        {!!options && (
          Object.keys(options).map(option => (
            <Option
              key={option}
              label={
                <div className={css.MainMenuOptionPicker__options}>
                  {options[option].icon ? (
                    <InlineSVG
                      src={options[option].icon}
                      className={css.MainMenuOptionPicker__options__icon}
                    />
                  ) : (
                    <Emoji
                      text={options[option].emoji}
                      size={21}
                    />
                  )}
                  <span className={css.MainMenuOptionPicker__options__name}>
                    <Translation value={options[option].name} />
                  </span>
                </div>
              }
              checked={option === selectedOptionId}
              onClick={() => onChange(option)}
            />
          ))
        )}
      </Fragment>
    }
    down
    center
  >
    <button
      className={css.MainMenuOptionPicker}
      type="button"
    >
      {icon}
    </button>
  </PopupMenu>
);

if (process.env.NODE_ENV !== 'production') {
  MainMenuOptionPicker.propTypes = {
    options: PropTypes.shape({
      data: PropTypes.shape({
        name: PropTypes.string.isRequired,
        icon: PropTypes.string,
        emoji: PropTypes.string,
      }),
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    selectedOptionId: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    optionsHeader: PropTypes.node,
  };
}

export default MainMenuOptionPicker;
