/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment, useState } from 'react';
import { InlineSVG } from '../../components/InlineSVG';
import ProgressCircle from '../../components/ProgressCircle';
import Tooltip from '../../components/Tooltip';
import Separator from '../../components/PopupMenu/Separator';
import { Translation } from '../../components/Text';

const PreviewFileButton = ({ fileId, downloadProps, previewFile }) => {
  // If the file is being downloaded from the 'download' button we do not want to show the spinner
  const [isDownloadPending, setIsDownloadPending] = useState(false);

  const isFileBeingDownloaded = Object.keys(downloadProps.downloadProgress).includes(fileId);
  const downloadProgress = downloadProps.downloadProgress[fileId];

  const showDownloadProgress = isDownloadPending && isFileBeingDownloaded;
  const disabled = !showDownloadProgress && !!downloadProps.status;

  return (
    <Tooltip
      up
      center
      maxWidth={270}
      disabled={isDownloadPending && downloadProgress === 100} // This will hide the tooltip just before the preview opens
      title={(
        <Fragment>
          <Translation value="proof.preview-file.tooltip" />
          {disabled && (
            <Fragment>
              <Separator />
              <Translation value="proof.preview-file.tooltip.wait-for-existing" />
            </Fragment>
          )}
        </Fragment>
      )}
    >
      <button
        type="button"
        className="app__tools__tools-button app__tools__tools-button--preview-file"
        onClick={() => {
          if (disabled) {
            return; // Not setting the element as disabled because the tooltip does not play nicely
          }
          const previewFileResult = previewFile(fileId);
          if (previewFileResult && typeof previewFileResult.then === 'function') {
            setIsDownloadPending(true);
            previewFileResult.then(() => setIsDownloadPending(false));
          }
        }}
        aria-label="Preview file"
      >
        {showDownloadProgress
          ? <ProgressCircle
            size={20}
            width={3}
            value={downloadProgress || 1} // Show a little bit of progress at the start so it's clear something is happening
          />
          : <InlineSVG src="/img/icons/material/symbols/preview-24px.svg" />
        }
      </button>
    </Tooltip>
  );
};

export default PreviewFileButton;
