/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import { InlineSVG } from '../../InlineSVG';
import Translation from '../../Text/Translation';
import MarkTodo from '../../Comment/Icon/MarkTodo';
import css from './LockMessage.scss';

const LockMessage = () => (
  <div className={css.LockMessage}>
    <Translation
      value="proof.dialog.message.lock"
      params={{
        todoIcon: <MarkTodo active readOnly />,
        unlockIcon: <InlineSVG className={css.LockMessage__iconUnlock} src="img/interface/unlock.svg" aria-label="Lock icon" tabIndex="0" />
      }}
    />
  </div>
);

export default LockMessage;
