/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { isValidElement } from 'react';
import classname from 'classname';
import { InlineSVG } from '../InlineSVG';

function Option({
  classNames,
  icon,
  label,
  selected,
  onClick,
  onMouseEnter,
  onMouseLeave,
  variant,
  name,
  button,
  wrap,
}) {
  return (
    <div
      className={classname(classNames, 'DropdownOption', {
        'DropdownOption--selected': selected,
        [`DropdownOption--${variant}`]: variant,
        'DropdownOption--has-icon': !!icon,
        [`DropdownOption--${name}`]: name,
        'DropdownOption--has-button': !!button,
        'DropdownOption--nowrap': !wrap,
      })}
      tabIndex="0"
      onClick={selected ? null : onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {icon &&
        <div className="DropdownOption__icon">
          {isValidElement(icon)
            ? icon
            : <InlineSVG src={icon} />}
        </div>}
      <div className="DropdownOption__label">
        {label}
      </div>
      {button &&
        <div className="DropdownOption__button">
          {button}
        </div>
      }
    </div>
  );
}

export default Option;
