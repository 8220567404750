/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import css from './ProgressCircle.scss';

function ProgressCircle({
  size,
  width,
  color,
  trackColor,
  value,
  className,
}) {
  const radius = (size / 2) - (width / 2);
  const circumference = 2 * Math.PI * radius;
  const progress = value / 100;
  const dashoffset = circumference * (1 - progress);
  return (
    <svg
      className={classname(css.ProgressCircle, className)}
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      aria-label="Progress circle icon"
    >
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        stroke={trackColor}
        strokeWidth={width}
      />
      <circle
        className={css.Bar}
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={width}
        stroke={color}
        strokeLinecap="round"
        strokeDashoffset={dashoffset}
        strokeDasharray={circumference}
      />
    </svg>
  );
}

ProgressCircle.defaultProps = {
  size: 60,
  width: 12,
  color: '#138b3b',
  trackColor: '#e6e6e6',
  value: 0,
};

if (process.env.NODE_ENV === 'development') {
  ProgressCircle.propTypes = {
    size: PropTypes.number,
    width: PropTypes.number,
    color: PropTypes.string,
    trackColor: PropTypes.string,
    value: PropTypes.number,
  };
}

export default ProgressCircle;
