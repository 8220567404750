/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import { InlineSVG } from '../InlineSVG';
import Translation from '../Text/Translation';
import css from './ModalMessage.scss';

const UploadNewVersionModalMessage = () => (
  <Fragment>
    <h1>
      <Translation
        value="proof.dialog.new-version-required.title"
      />
    </h1>
    <br className={css.ModalMessage__betweenComments} />
    <p>
      <Translation
        value="proof.dialog.new-version-required.click-icon"
        params={{
          icon: (
            <div className={css.ModalMessage__uploadIcon}>
              <InlineSVG
                aria-label="File dropper icon"
                src="/img/icons/file-dropper.svg"
              />
            </div>
          ),
        }}
      />
    </p>
  </Fragment>
);

export default UploadNewVersionModalMessage;
