/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import createIcon from '../../Icon/createIcon';

export default createIcon({
  displayName: 'Unmarked icon',
  src: 'img/icons/mark-unmarked.svg',
  activeColor: '#aaa',
  activeHoverColor: '#919191',
  userPreferenceColor: 'unmarked',
  defaultSize: 20,
});
