/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import { InlineSVG } from '../../components/InlineSVG';
import { ScaledDownAppearing } from '../../components/Transitions';
import IndeterminateLoaderOverlay from '../../components/IndeterminateLoaderOverlay';
import { EncryptedSpriteThumbnail } from '../../components/Encrypted/EncryptedSpriteThumbnail';
import CommentStatusCountBar from '../../components/ModalMessages/CommentStatusCountBar';
import ZoomSlider from '../../components/ZoomSlider';
import { DEFAULT_SPRITE_HEIGHT, DEFAULT_SPRITE_WIDTH } from '../../util/constants';
import TruncateStringWithMiddleEllipsis from '../../components/TruncateStringWithMiddleEllipsis';
import { usePageGridSettings } from '../../hooks/usePageGridSettings';
import css from './PageGridView.scss';
import { useLocalStorage } from '../../components/LocalStorage';


const PageGridView = ({ title, subtitle, loadingPage, currentPage, pages, onPageSelect, fileId, show, showPageGridIcon, pagesMetadata, proofId }) => {
  const commentsExistOnPage = page => page.todoCount > 0 || page.unmarkedCount > 0 || page.doneCount > 0;
  const isSafari = window.__pageproof_bridge__.browserService.is('safari');
  const [bookMarkPage] = useLocalStorage('pageproof.app.bookmark', null);

  const selectedPageRef = useRef(null);
  const selectedPageRect = selectedPageRef.current ? selectedPageRef.current.getBoundingClientRect() : null;

  const scrollToCurrentPage = () => {
    if (selectedPageRect && (selectedPageRect.bottom < 0 || selectedPageRect.top > window.innerHeight)) {
      return;
    }
    selectedPageRef.current.scrollIntoView({
      block: 'center',
      inline: 'center',
    });
  };

  const [{ zoomLevel }, setSettings] = usePageGridSettings();

  useEffect(() => {
    if (selectedPageRect) {
      scrollToCurrentPage(selectedPageRect);
    }
  }, [zoomLevel]);

  const memoizedPagesMetadataGrouped = (() => {
    const pagesMetadataGrouped = {};

    if (pagesMetadata) {
      const pagesIndex = {};
      pages.forEach((page) => {
        pagesIndex[page.pageNumber] = page;
      });

      pagesMetadata.forEach((pageMetadata) => {
        const { fileName, ...rest } = pageMetadata;
        const page = pagesIndex[rest.pageNumber];

        if (typeof fileName === 'undefined' || !page) {
          return;
        }

        pagesMetadataGrouped[fileName] = pagesMetadataGrouped[fileName] || [];

        pagesMetadataGrouped[fileName].push({
          ...rest,
          fileName,
          todoCount: page.todoCount,
          unmarkedCount: page.unmarkedCount,
          doneCount: page.doneCount,
          pageNumber: page.pageNumber,
        });
      });
    } else {
      const group = [];
      pagesMetadataGrouped[''] = group;
      group.push(...pages);
    }

    return pagesMetadataGrouped;
  })();

  return (
    <ScaledDownAppearing>
      {show && (
        <div
          className={classname(css.PageGridView, {
            [css['PageGridView--hasPageGridIcon']]: showPageGridIcon,
          })}
        >
          {(title || subtitle) && (
            <div className={css.PageGridView__header}>
              <p>{title}</p>
              <p className={css.PageGridView__header__sub}>{subtitle}</p>
            </div>
          )}
          <div className={css.PageGridView__body}>
            {Object.entries(memoizedPagesMetadataGrouped).map(([file, pagesArr]) => (
              pagesArr.map((page, pageIndex) => (
                <div
                  key={page.pageNumber}
                  className={classname(css.PageGridView__body__proofPage, {
                    'js-page-grid-current-page': currentPage === page.pageNumber,
                    [css['PageGridView__body__proofPage--currentPage']]: currentPage === page.pageNumber,
                    [css['PageGridView__body__proofPage--sameFileName']]: page.fileName === file,
                    [css['PageGridView__body__proofPage--isFirstInPageGroup']]: pageIndex === 0,
                    [css['PageGridView__body__proofPage--isLastInPageGroup']]: pageIndex === pagesArr.length - 1,
                    [css['PageGridView__body__proofPage--isSinglePage']]: pagesArr.length < 2,
                  })}
                  ref={currentPage === page.pageNumber ? selectedPageRef : null}
                  style={{
                    width: (DEFAULT_SPRITE_WIDTH * zoomLevel) + 40,
                  }}
                >
                  <div className={classname(css.PageGridView__body__proofPage__header)}>
                    <div className={css.bookmarIcon}>
                      {bookMarkPage && bookMarkPage[proofId] === page.pageNumber && (
                        <InlineSVG
                          src="img/icons/material/bookmark_add.svg"
                        />
                      )}
                    </div>
                    <div className={classname(css.PageGridView__body__proofPage__number)}>
                      {page.pageNumber}
                    </div>
                  </div>
                  <IndeterminateLoaderOverlay
                    loading={loadingPage === page.pageNumber}
                    className={css.PageGridView__body__proofPage__thumbnail}
                    onClick={() => onPageSelect(page)}
                    width={DEFAULT_SPRITE_WIDTH * zoomLevel}
                    height={DEFAULT_SPRITE_HEIGHT * zoomLevel}
                  >
                    <EncryptedSpriteThumbnail
                      fileId={fileId}
                      pageNumber={page.pageNumber}
                      scale={zoomLevel}
                    />
                  </IndeterminateLoaderOverlay>
                  {pageIndex === 0 && (
                    <TruncateStringWithMiddleEllipsis>
                      {file}
                    </TruncateStringWithMiddleEllipsis>
                  )}
                  {commentsExistOnPage(page) && (
                    <div
                      className={classname(css.PageGridView__body__proofPage__commentPill, {
                        [css['PageGridView__body__proofPage__commentPill--currentPage']]: currentPage === page.pageNumber,
                      })}
                    >
                      <CommentStatusCountBar
                        todos={page.todoCount}
                        dones={page.doneCount}
                        notMarked={page.unmarkedCount}
                        size="small"
                        showTooltip
                      />
                    </div>
                  )}
                </div>
              ))
            ))}
          </div>
          {showPageGridIcon && (
            <div className={css.PageGridView__footer}>
              {!isSafari &&
                <div className={css.PageGridView__footer__zoomSlider}>
                  <ZoomSlider
                    zoomLevel={zoomLevel}
                    minZoomLevel={1}
                    maxZoomLevel={4}
                    onZoomChange={value => setSettings({ zoomLevel: value })}
                    onReset={() => setSettings({ zoomLevel: 1 })}
                  />
                </div>
              }
              <span
                className={css.PageGridView__footer__icon}
                onClick={() => onPageSelect(currentPage)}
              />
              <span>{`${currentPage} / ${pages.length}`}</span>
            </div>
          )}
        </div>
      )}
    </ScaledDownAppearing>
  );
};

PageGridView.defaultProps = {
  showPageGridIcon: false,
};

if (process.env.NODE_ENV !== 'production') {
  PageGridView.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    loadingPage: PropTypes.number,
    currentPage: PropTypes.number.isRequired,
    pages: PropTypes.arrayOf(PropTypes.object).isRequired,
    onPageSelect: PropTypes.func.isRequired,
    fileId: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    showPageGridIcon: PropTypes.bool,
    pagesMetadata: PropTypes.arrayOf(PropTypes.object),
  };
}

export default PageGridView;
