/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState, useEffect } from 'react';
import ProfileNotifications from '../../components/ProfileNotifications';

const generalPreferences = [
  {
    type: 'ReviewerFinishedToApprover',
    labelKey: 'profile.notify-me.reviewer-finished',
    onMessageKey: 'user.notifyme.reviewer-finished.on',
    offMessageKey: 'user.notifyme.reviewer-finished.off',
  },
  {
    type: 'ApprovedToReviewer',
    labelKey: 'profile.notify-me.approved-to-reviewer',
    onMessageKey: 'user.notifyme.approved-to-reviewer.on',
    offMessageKey: 'user.notifyme.approved-to-reviewer.off',
  },
  {
    type: 'CommentNewOrReplyToReviewer',
    labelKey: 'profile.notify-me.comment-new-or-reply-to-reviewer',
    onMessageKey: 'user.notifyme.comment-new-or-reply-to-reviewer.on',
    offMessageKey: 'user.notifyme.comment-new-or-reply-to-reviewer.off',
  },
  {
    type: 'CommentReply',
    labelKey: 'profile.notify-me.replies',
    onMessageKey: 'user.notifyme.replies.on',
    offMessageKey: 'user.notifyme.replies.off',
  },
  {
    type: 'CommentReplyToLocker',
    labelKey: 'profile.notify-me.replies-to-todos',
    onMessageKey: 'user.notifyme.replies-to-todos.on',
    offMessageKey: 'user.notifyme.replies-to-todos.off',
  },
  {
    type: 'UnlockRequest',
    labelKey: 'profile.notify-me.unlock-request',
    onMessageKey: 'user.notifyme.unlock-request.on',
    offMessageKey: 'user.notifyme.unlock-request.off',
  },
  {
    type: 'Unlocked',
    labelKey: 'profile.notify-me.unlocked',
    onMessageKey: 'user.notifyme.unlocked.on',
    offMessageKey: 'user.notifyme.unlocked.off',
  },
];

const ownerPreferences = [
  {
    type: 'ProofStarted',
    labelKey: 'profile.notify-me.proof-started',
    onMessageKey: 'user.notifyme.proof-started.on',
    offMessageKey: 'user.notifyme.proof-started.off',
  },
  {
    type: 'OutOfOffice',
    labelKey: 'profile.notify-me.out-of-office',
    onMessageKey: 'user.notifyme.proof-out-of-office.on',
    offMessageKey: 'user.notifyme.proof-out-of-office.off',
  },
  {
    type: 'CommentNew',
    labelKey: 'profile.notify-me.comments',
    onMessageKey: 'user.notifyme.new-comment.on',
    offMessageKey: 'user.notifyme.new-comment.off',
  },
  {
    type: 'Finished',
    labelKey: 'profile.notify-me.finished',
    onMessageKey: 'user.notifyme.finished.on',
    offMessageKey: 'user.notifyme.finished.off',
  },
  {
    type: 'MandatoryFinished',
    labelKey: 'profile.notify-me.mandatory-finished',
    onMessageKey: 'user.notifyme.mandatory-finished.on',
    offMessageKey: 'user.notifyme.mandatory-finished.off',
  },
  {
    type: 'GatekeeperApproved',
    labelKey: 'profile.notify-me.gatekeeper-approves',
    onMessageKey: 'user.notifyme.gatekeeper-approves.on',
    offMessageKey: 'user.notifyme.gatekeeper-approves.off',
  },
  {
    type: 'Overdue',
    labelKey: 'profile.notify-me.overdue-proofs',
    onMessageKey: 'user.notifyme.overdue-proofs.on',
    offMessageKey: 'user.notifyme.overdue-proofs.off',
  },
  {
    type: 'Coowner',
    labelKey: 'profile.notify-me.proof-owner',
    onMessageKey: 'user.notifyme.proof-owner.on',
    offMessageKey: 'user.notifyme.proof-owner.off',
  },
];

const criticalPreferences = [
  {
    type: 'InvitedToProof',
    labelKey: 'profile.notify-me.invitation',
    isCritical: true,
    onMessageKey: 'user.notifyme.invitation.on',
    offMessageKey: 'user.notifyme.invitation.off',
    batchType: 'BatchInvitedToProof',
  },
  {
    type: 'ToDoReceived',
    labelKey: 'profile.notify-me.to-dos-received',
    isCritical: true,
    onMessageKey: 'user.notifyme.to-dos-received.on',
    offMessageKey: 'user.notifyme.to-dos-received.off',
    batchType: 'BatchTodoReceived',
  },
  {
    type: 'Approved',
    labelKey: 'profile.notify-me.approved',
    isCritical: true,
    onMessageKey: 'user.notifyme.approved.on',
    offMessageKey: 'user.notifyme.approved.off',
    batchType: 'BatchApproved',
  },
];

const ProfileNotificationsContainer = () => {
  const { userService, backendService, sdk } = window.__pageproof_bridge__;
  const { userId } = sdk.session;
  const [emailPreferences, setEmailPreferences] = useState(null);

  useEffect(() => {
    userService.populateUser()
      .then(user => setEmailPreferences(user.emailPreferences));
  }, []);

  const updateNotifyMe = (type) => {
    const updateNotificationObject = {
      UserId: userId,
      [type]: !emailPreferences[type] ? 1 : 0,
    };

    setEmailPreferences({
      ...emailPreferences,
      [type]: !emailPreferences[type],
    });

    backendService.fetch('user.update.notifyme', updateNotificationObject);
  };

  if (!emailPreferences) {
    return null;
  }

  return (
    <ProfileNotifications
      emailPreferences={emailPreferences}
      onUpdateEmailPreference={updateNotifyMe}
      generalPreferences={generalPreferences}
      ownerPreferences={ownerPreferences}
      criticalPreferences={criticalPreferences}
    />
  );
};

export default ProfileNotificationsContainer;
