/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment, Component, Children, cloneElement, createRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { createPortal, findDOMNode } from 'react-dom';
import classname from 'classname';
import Isolate from '../Isolate';
import { withTimers } from '../../hoc/Timers';
// import {withEventListeners} from '../../hoc/EventListeners';
import optionalChildrenFunc from '../../util/optional-children-func';
import { TooltipContext } from './TooltipContext';
import style from './Tooltip.scss';

const TOOLTIP_DURATION = 300;

const tooltipRoot = document.createElement('div');
document.body.appendChild(tooltipRoot);

function getDirectionProps(props, oppositeDirection = false) {
  const directions = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const key in props) {
    if (Object.prototype.hasOwnProperty.call(props, key)) {
      if (typeof props[key] === 'boolean') {
        directions[key] = props[key];
      }
    }
  }
  if (!props.up && !props.down && !props.middle) {
    directions.down = true;
  }
  if (!props.left && !props.right && !props.center) {
    directions.right = true;
  }

  if (oppositeDirection) {
    return getOppositeDirection(directions);
  }
  return directions;
}

function getOppositeDirection(direction) {
  return {
    ...direction,
    down: !direction.down,
    up: !direction.up,
  };
}

function combineFuncs(...funcs) {
  const normalizedFuncs = funcs.filter(func => (typeof func === 'function'));
  return (...args) => {
    normalizedFuncs.forEach((func) => {
      func(...args);
    });
  };
}

class Tooltip extends Component {
  state = {
    visible: false,
    _isDisappearing: false,
  };

  _tooltipRef = createRef();

  currentRoot = null;

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillMount() {
    this.portal = document.createElement('div');
  }

  componentDidMount() {
    try {
      this.isMobile = window.__pageproof_bridge__.browserService.is('mobile');
    } catch (error) {
      this.isMobile = false;
    }
    this.children = this.getChildrenElement();
    if (this.isControlled && this.props.visible) {
      this.show();
    }
  }

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.isControlled &&
      nextProps.visible !== this.props.visible) {
      if (nextProps.visible) {
        this.show();
      } else {
        this.hide();
      }
    }
    if (!this.props.disabled && nextProps.disabled) {
      this.hide();
    }
    if (this.currentRoot && (nextProps.mountElement || tooltipRoot) !== this.currentRoot) {
      this.currentRoot.removeChild(this.portal);
      this.currentRoot = nextProps.mountElement || tooltipRoot;
      this.currentRoot.appendChild(this.portal);
    }
  }

  componentDidUpdate() {
    this.children = this.getChildrenElement();
  }

  componentWillUnmount() {
    try {
      this.currentRoot.removeChild(this.portal);
      this.currentRoot = null;
    } catch (err) {
      //
    }
  }

  get isControlled() {
    return 'visible' in this.props;
  }

  get isVisible() {
    return this.isControlled
      ? this.props.visible
      : this.state.visible;
  }

  getChildrenElement() {
    return findDOMNode(this);
  }

  getPosition() {
    const { offset } = this.props;
    const direction = getDirectionProps(this.props);
    const tooltipB = this.tooltip.getBoundingClientRect();
    const targetB = this.children.getBoundingClientRect();
    let position = this.getTooltipPosition(direction);
    const beforePushing = position.left;
    const maxLeft = window.innerWidth - tooltipB.width - Math.min(0, offset);
    const maxTop = window.innerHeight - tooltipB.height - Math.min(0, offset);

    // if tooltip child leaves screen, hide tooltip
    if (
      targetB.top + targetB.height < 0 ||
      targetB.left + targetB.width < 0 ||
      targetB.top >= window.innerHeight ||
      targetB.left >= window.innerWidth
    ) {
      this.hide();
    }

    // Only if not enough space in bottom or in top
    if (position.top > maxTop || position.top < 0) {
      const oppositeDirection = getOppositeDirection(direction);
      const newPosition = this.getTooltipPosition(oppositeDirection);

      // Set it only if new position not making pill out of screen as on small screen tooltip can be bigger to fit in any direction, so keep original positions
      if (newPosition.top > 0) {
        position = newPosition;
        position.notEnoughSpace = true;
      } else {
        // If tooltip is bigger than screen height, just push it edge rather in opposite direction
        if (position.top > maxTop) {
          position.top = maxTop;
          position.pushed = true;
        }
        if (position.top < 0) {
          position.top = 0;
          position.pushed = true;
        }
      }
    }

    position.left = Math.max(0, Math.min(maxLeft, position.left));
    if (beforePushing !== position.left) {
      position.pushed = true;
    }
    return position;
  }

  getTooltipPosition(direction) {
    const ARROW_WIDTH = 9;

    const { offset } = this.props;
    const targetB = this.children.getBoundingClientRect();
    const tooltipB = this.tooltip.getBoundingClientRect();

    const position = {};
    if (direction.down) {
      position.top = targetB.top + targetB.height + offset;
    }
    if (direction.right) {
      position.left = direction.middle ? targetB.left : targetB.left - ARROW_WIDTH;
    }
    if (direction.up) {
      position.top = targetB.top - (tooltipB.height + offset);
    }
    if (direction.left) {
      const calculatedLeft = (targetB.left + targetB.width) - tooltipB.width;
      position.left = direction.middle ? calculatedLeft : calculatedLeft + ARROW_WIDTH;
    }
    if (direction.center) {
      position.left = targetB.left + ((targetB.width / 2) - (tooltipB.width / 2));
    }
    if (direction.middle) {
      if ((direction.down || direction.up) && (direction.right || direction.left)) {
        if (direction.down) {
          position.top -= targetB.height;
        }
        if (direction.up) {
          position.top += targetB.height;
        }
        if (direction.right) {
          position.left += targetB.width;
        }
        if (direction.left) {
          position.left -= targetB.width;
        }
      } else {
        position.top = targetB.top + ((targetB.height / 2) - (tooltipB.height / 2));
        if (direction.right) {
          position.left += targetB.width + offset;
        }
        if (direction.left) {
          position.left -= targetB.width + offset;
        }
      }
    }
    return position;
  }

  getClasses() {
    const direction = getDirectionProps(this.props, this.notEnoughSpace);
    return classname({
      [style['Tooltip--up']]: direction.up,
      [style['Tooltip--down']]: direction.down,
      [style['Tooltip--left']]: direction.left,
      [style['Tooltip--right']]: direction.right,
      [style['Tooltip--center']]: direction.center,
      [style['Tooltip--middle']]: direction.middle,
    });
  }

  getArrowClass() {
    const direction = getDirectionProps(this.props, this.notEnoughSpace);
    return classname({
      [style['Arrow--up']]: direction.down,
      [style['Arrow--down']]: direction.up,
      [style['Arrow--left']]: direction.left && direction.middle,
      [style['Arrow--right']]: direction.right && direction.middle,
      [style[`Arrow--${this.props.variant}`]]: true,
    });
  }

  getTransformOrigin(notEnoughSpace = false) {
    const direction = getDirectionProps(this.props, notEnoughSpace);
    if (this.props.arrow && direction.center) {
      const arrowPosition = this.getArrowPosition();
      if (direction.up || direction.down) {
        return `${arrowPosition.left}px ${direction.up ? '100%' : '0'}`;
      }
      if (direction.left || direction.right) {
        return `${direction.left ? '100%' : '0'} ${arrowPosition.top}px`;
      }
    }
    const origin = [
      direction.up && 'bottom',
      direction.down && 'top',
      direction.left && 'right',
      direction.right && 'left',
      direction.center && 'center',
      (direction.middle && !(direction.down || direction.up)) && 'center',
    ].filter(Boolean).join(' ');
    return origin;
  }

  getBaseTooltipClasses() {
    const { variant, padding, disablePointerEvents } = this.props;
    return classname(style.Tooltip, style['Tooltip--' + variant], {
      [style['Tooltip--no-padding']]: !padding,
      [style['Tooltip--disable-pointer-events']]: disablePointerEvents,
    });
  }

  getArrowPosition() {
    const box = this.children ? this.children.getBoundingClientRect() : null;
    const tooltipBox = this.tooltip ? this.tooltip.getBoundingClientRect() : null;
    const position = this.getPosition();
    let arrowLeft = tooltipBox.width / 2;
    let arrowTop = tooltipBox.height / 2;
    {
      let diff = 0;
      if (tooltipBox.width > box.width) {
        diff = (position.left + (tooltipBox.width / 2)) - (box.left + (box.width / 2));
        if (Math.abs(diff) > 5) {
          arrowLeft = (tooltipBox.width / 2) - diff;
        }
      }
      if (tooltipBox) {
        arrowLeft = Math.max(16, Math.min(tooltipBox.width - 16, arrowLeft));
      }
    }
    {
      let diff = 0;
      if (tooltipBox.height > box.height) {
        diff = (position.top + (tooltipBox.height / 2)) - (box.top + (box.height / 2));
        if (Math.abs(diff) > 5) {
          arrowTop = (tooltipBox.height / 2) - diff;
        }
      }
      if (tooltipBox) {
        arrowTop = Math.max(16, Math.min(tooltipBox.height - 16, arrowTop));
      }
    }
    return {
      left: arrowLeft,
      top: arrowTop,
      notEnoughSpace: position.notEnoughSpace,
    };
  }

  show = () => {
    if (this.isVisible && !this.isControlled) {
      return;
    }
    this.lastPosition = null;
    if (this.props.onBeforeOpen) {
      const result = this.props.onBeforeOpen({
        instance: this,
        element: this.children,
      });
      if (result === false) {
        return;
      }
    }
    if (this.props.onShow) {
      this.props.onShow(this);
    }
    this.currentRoot = this.props.mountElement || tooltipRoot;
    this.currentRoot.appendChild(this.portal);
    this.appearing = this.props.setTimeout(() => {
      this.setState({
        visible: true,
        _isDisappearing: false,
      });
      this.appearing = this.props.setTimeout(() => {
        this.assignPosition();
        this.appearing = this.props.setTimeout(() => {
          this.startRAF();
        }, TOOLTIP_DURATION);
      });
    }, this.props.delay);
    if (this.disappearing) {
      this.stopRAF();
      this.disappearing();
    }
  };

  hide = () => {
    if (this.appearing) {
      this.stopRAF();
      this.appearing();
    }
    if (!this.isVisible) {
      return;
    }
    if (this.tooltip) {
      this.tooltip.className += ' ' + style['Tooltip--hide'];
    }
    // this.stopRAF();
    this.setState({
      visible: false,
      _isDisappearing: true,
    });
    if (this.props.onBeforeHide) {
      this.props.onBeforeHide(this);
    }
    this.disappearing = this.props.setTimeout(() => {
      if (this.props.onHide) {
        this.props.onHide(this);
      }
      try {
        this.stopRAF();
        this.currentRoot.removeChild(this.portal);
        this.currentRoot = null;
      } catch (err) {
        //
      }
      this.setState({
        _isDisappearing: false,
      });
    }, this.props.animateDisappear ? TOOLTIP_DURATION : 0);
  };

  toggle = () => {
    if (this.isVisible) {
      this.hide();
    } else {
      this.show();
    }
  };

  tooltipRef = (tooltip) => {
    this.tooltip = tooltip;
    this._tooltipRef.current = tooltip;
  };

  arrowRef = (arrow) => {
    this.arrow = arrow;
  };

  assignPosition() {
    const baseClasses = this.getBaseTooltipClasses();
    this.tooltip.className = baseClasses;
    this.tooltip.clientHeight; // eslint-disable-line no-unused-expressions
    this.reassignPosition();
    const ready = classname(baseClasses, this.getClasses(), style['Tooltip--ready']);
    this.tooltip.className = ready;
    this.tooltip.clientHeight; // eslint-disable-line no-unused-expressions
    this.tooltip.className = classname(ready, style['Tooltip--show']);
  }

  reassignPosition() {
    const position = this.getPosition();
    const hasChangedPosition = !this.lastPosition || (this.lastPosition.top !== position.top || this.lastPosition.left !== position.left);
    if (hasChangedPosition) {
      if (this.props.onUpdatePosition) {
        this.props.onUpdatePosition(position);
      }
      Object.assign(this.tooltip.style, {
        top: position.top + 'px',
        left: position.left + 'px',
        transformOrigin: this.getTransformOrigin(position.notEnoughSpace),
        zIndex: this.props.zIndex,
      });
      if (this.arrow) {
        const arrowPosition = this.getArrowPosition();
        const direction = getDirectionProps(this.props, position.notEnoughSpace);
        if (direction.up || direction.down) {
          Object.assign(this.arrow.style, {
            left: arrowPosition.left + 'px',
          });
        } else {
          Object.assign(this.arrow.style, {
            top: arrowPosition.top + 'px',
          });
        }
      }
      if (this.notEnoughSpace !== position.notEnoughSpace) {
        this.notEnoughSpace = position.notEnoughSpace;
        this.forceUpdate();
      }
      if (this.pushed !== position.pushed) {
        this.pushed = position.pushed;
        this.forceUpdate();
      }
    }
    this.lastPosition = position;
    return position;
  }

  startRAF() {
    this.stopRAF();
    const next = () => {
      if (this.isVisible) {
        this.reassignPosition();
      }
      this.raf = this.props.requestAnimationFrame(next);
    };
    this.raf = this.props.requestAnimationFrame(next);
  }

  stopRAF() {
    if (this.raf) {
      this.raf();
    }
  }

  // eslint-disable-next-line consistent-return
  getHoverCardSides(tooltip = false) {
    const directions = getDirectionProps(this.props);

    if (directions.up || directions.down) {
      return tooltip
        ? ['left', 'right', (directions.up ? 'top' : 'bottom')]
        : ['left', 'right', (directions.up ? 'bottom' : 'top')];
    } else if (directions.left || directions.right) {
      return tooltip
        ? ['top', 'bottom', (directions.left ? 'left' : 'right')]
        : ['top', 'bottom', (directions.left ? 'right' : 'left')];
    }
  }

  renderTooltip() {
    const {
      backdrop,
      title,
      arrow,
      zIndex,
      hoverCard,
    } = this.props;
    const zIndexStyleObject = zIndex ? { zIndex } : {};
    const hideTooltip = this.isControlled ? (this.props.onBeforeHide || null) : this.hide;
    const hovercardProps = hoverCard
      ? {
        onMouseEnter: () => {
          if (this.hoverCardHideTimeout) {
            this.hoverCardHideTimeout();
          }
        },
        onMouseLeave: () => {
          if (this.hoverCardHideTimeout) {
            this.hoverCardHideTimeout();
          }
          this.hoverCardHideTimeout = this.props.setTimeout(() => {
            this.hide();
          }, 250);
        },
      }
      : {};

    return createPortal(
      this.isVisible || (this.state._isDisappearing && this.props.animateDisappear)
        ? (
          <Fragment>
            <div
              className={this.getBaseTooltipClasses()}
              ref={this.tooltipRef}
              {...hovercardProps}
              style={isFinite(this.props.maxWidth) // eslint-disable-line
                ? { maxWidth: this.props.maxWidth, ...zIndexStyleObject }
                : zIndexStyleObject
              }
            >
              {this.state._isDisappearing
                ? this._previousContent
                : (this._previousContent = optionalChildrenFunc(title, this))}
              {arrow &&
                <div
                  ref={this.arrowRef}
                  className={classname(style.Arrow, this.getArrowClass())}
                />
              }
            </div>
            {backdrop &&
              <Isolate
                style={{
                  zIndex: 999, // one less then the tooltip itself
                }}
                target={{ current: this.children }}
                onClick={hideTooltip}
                mountElement={this.props.mountElement}
              />
            }
          </Fragment>
        )
        : null,
      this.portal
    );
  }

  render() {
    let props = {};
    const clone = Children.only(
      optionalChildrenFunc(this.props.children, this),
    );
    const defaultEvents = this.isMobile
      ? {
        onTouchStart: combineFuncs(clone.props.onTouchStart, this.show),
        onTouchEnd: combineFuncs(clone.props.onTouchEnd, this.hide),
      } : {
        // Normal tooltips are triggered by hover events
        onMouseEnter: combineFuncs(clone.props.onMouseEnter, this.show),
        onMouseLeave: combineFuncs(clone.props.onMouseLeave, this.hide),
      };

    if (this.isControlled || this.props.disabled) {
      props = {};
    } else if (this.props.popover) {
      props = {
        // Popover is triggered by a click
        onClick: combineFuncs(clone.props.onClick, this.toggle),
      };
    } else if (this.props.hoverCard && this.isVisible) {
      props = {
        onMouseEnter: (event) => {
          this.hoverCardHideTimeout();
          combineFuncs(clone.props.onMouseEnter, this.show)(event);
        },
        onMouseLeave: (event) => {
          this.hoverCardHideTimeout = this.props.setTimeout(() => {
            combineFuncs(clone.props.onMouseLeave, this.hide)(event);
          }, 250);
        },
      };
    } else {
      props = defaultEvents;
    }

    if (this.isVisible) {
      props.className = classname(clone.props.className, 'js-active tooltip-target-active', {
        'tooltip-target-trigger-clicked': this.props.popover,
        'tooltip-target-trigger-hovered': !this.props.popover,
      });
    }
    return (
      <Fragment>
        {cloneElement(clone, props)}
        {this.renderTooltip()}
      </Fragment>
    );
  }
}

Tooltip.defaultProps = {
  offset: 5,
  delay: 200,
  backdrop: false,
  popover: false,
  hoverCard: false,
  variant: 'dark',
  maxWidth: 'max-content',
  arrow: false,
  disabled: false,
  padding: true,
  animateDisappear: true,
  disablePointerEvents: false,
};

Tooltip.propTypes = {
  offset: PropTypes.number,
  delay: PropTypes.number,
  backdrop: PropTypes.bool,
  popover: PropTypes.bool,
  hoverCard: PropTypes.bool,
  animateDisappear: PropTypes.bool,
  variant: PropTypes.oneOf([
    'dark',
    'light',
    'yellow',
    'no-background',
  ]),
  maxWidth: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([false]),
  ]),
  visible: PropTypes.bool, // only if controlled
  /* eslint-disable react/no-unused-prop-types */
  up: PropTypes.bool,
  down: PropTypes.bool,
  left: PropTypes.bool,
  right: PropTypes.bool,
  center: PropTypes.bool,
  middle: PropTypes.bool,
  /* eslint-enable */
  onBeforeOpen: PropTypes.func,
  arrow: PropTypes.bool,
  disabled: PropTypes.bool,
  padding: PropTypes.bool,
  onUpdatePosition: PropTypes.func,
  zIndex: PropTypes.number,
  disablePointerEvents: PropTypes.bool,
  mountElement: PropTypes.any, // eslint-disable-line
};

function onlyWhenTruncated(selector) {
  return ({ element }) => {
    const target = selector ? element.querySelector(selector) : element;
    return !target || target.clientWidth < target.scrollWidth;
  };
}

function withContext(Comp) {
  const hoc = (props) => {
    const context = useContext(TooltipContext);
    return <Comp {...props} {...context} />;
  };
  hoc.displayName = `withContext(${Comp.displayName || Comp.name})`;
  return hoc;
}

export default withTimers(withContext(Tooltip));

export {
  onlyWhenTruncated,
};
