/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-disable */

import React, { useState, useRef, useEffect, Fragment } from 'react';
import { useDrag, useDrop, useDragDropManager } from 'react-dnd';
import classname from 'classname';
import { InlineSVG } from '../../InlineSVG';
import Media from 'react-media';
import { Enum } from '@pageproof/sdk';
import css from './Workflow.scss';
import MetadataButton from '../../Metadata/Button';
import Metadata from '../../Metadata/Metadata';
import Reveal from '../../Reveal';
import Translation from '../../Text/Translation';
import Tooltip from '../../Tooltip';
import Avatar from '../../Avatar/Avatar';
import RevealTextHorizontal from './RevealTextHorizontal';
import Suggestions from '../../Suggestions';
import randomId from '../utils/randomId';
import commaSeparatedEmails,{ generateAvatarOptions } from '../../../util/commaSeparatedEmails';
import env from '../../../../../shared/env';
import { TranslatedProps, useText } from '../../Text';
import WorkflowStepDueDate from '../../WorkflowStepDueDate';
import { PopupMenu, Option } from '../../PopupMenu';
import { Flex } from '../../Flex';
import ValidationToast from '../../ValidationToast';
import WorkflowUserStatus from '../../WorkflowUserStatus';
import WrapConditionally from '../../WrapConditionally';
import { sdk } from '../../../util/sdk';
import { isDateWithinNudgeCooldownPeriod } from '../../../features';
import moment from 'moment';
import UnstyledButton from '../../Button/UnstyledButton';
import { useI18n } from '../../../hooks/useI18n';
import WhenSticky from './WhenSticky';
import Input from '../../Input';
import { remove } from 'lodash';

const newEmptyStep = () => ({
  _id: randomId(),
  name: '',
  users: [],
  mandatoryDecisionThreshold: null,
});

const newEmptyUser = () => ({
  _id: randomId(),
  email: '',
  role: 'reviewer',
  permissions: {
    inviter: false,
  },
});

const copyUser = (user) => ({
  _id: randomId(),
  email: user.email,
  role: user.role,
  permissions: {
    inviter: user.permissions.inviter,
  },
});

const moveStep = (workflow, step, direction, events) => {
  const currentIndex = workflow.steps.indexOf(step);
  workflow.steps.splice(currentIndex, 1);
  const stepIndex = currentIndex + direction;
  workflow.steps.splice(stepIndex, 0, step);

  const nextStep = workflow.steps[stepIndex + 1];
  events.onMoveStep && events.onMoveStep(workflow, step, nextStep);
};

const doesUserAlreadyExistInStep = (step, email) => {
  return step.users.some(user => user.email === email);
};

const addExistingEmailsValidation = (setValidation, emails) => {
  setValidation((validation) => ({
    existingEmails: [
      ...(validation.existingEmails || []),
      ...emails,
    ],
    ...validation,
  }));
};

const addInvalidEmailValidation = (setValidation, email) => {
  setValidation((validation) => ({
    invalidEmail: email,
    ...validation,
  }));
};

const addInvalidGroupValidation = (setValidation, groupName) => {
  setValidation((validation) => ({
    invalidGroup: groupName,
    ...validation,
  }));
};

const removePropertiesForReplacedUser = (user) => {
  Object.keys(user).forEach((key) => {
    if (!['email', 'role', 'permissions'].includes(key)) {
      delete user[key];
    }
  });
};

const moveUserIntoStep = (workflow, user, currentStep, newStep, events) => {
  currentStep.users.splice(currentStep.users.indexOf(user), 1);
  newStep.users.push(user);
  if (events.onRemoveUser) {
    events.onRemoveUser(workflow, currentStep, user);
  }
  if (events.onAddUsers) {
    removePropertiesForReplacedUser(user);
    if (newStep.position === 1000) {
      user.role = Enum.WorkflowUserRole.APPROVER;
    } else if (user.role === Enum.WorkflowUserRole.APPROVER) {
      user.role = Enum.WorkflowUserRole.GATEKEEPER;
    }

    events.onAddUsers(workflow, newStep, [user]);
  }
};

const canMoveUserIntoStep = (user, currentStep, newStep) => {
  return (
    currentStep !== newStep &&
    !newStep.users.some(newStepUser => user.email === newStepUser.email)
  );
};

const calculateWorkflowAttributes = (workflow, owners, type, proof) => {
  workflow._id = workflow._id || workflow.id || randomId();
  const ownersIndex = {};
  owners.forEach((owner) => {
    ownersIndex[owner] = true;
  });
  if (workflow.steps) {
    let startIndex = -1;
    workflow.steps.forEach((step, index) => {
      if (step.start) {
        if (index === 0) {
          // If the start step is the first step, unset the start state.
          step.start = false;
        }
        startIndex = index;
      }
    });
    workflow._hasStartStep = startIndex !== -1;
    workflow._hasMultipleSteps = workflow.steps.length > 1;

    if (type === 'proof') {
      workflow._isFinished = proof.status > Enum.ProofStatus.FINAL_APPROVING || proof.approvedDate;
      workflow._proof = proof;
    }

    workflow.steps.forEach((step, index) => {
      step._isRightBeforeLastStep = index === workflow.steps.length - 2;
      if (step.mandatoryDecisionThreshold === undefined) {
        step.mandatoryDecisionThreshold = null;
      }
      if (!step._id) {
        step._id = randomId();
      }
      step._index = index;
      step._position = index + 1;
      step._isFirst = index === 0;
      step._isLast = index === workflow.steps.length - 1;
      step._isSkipped = index < startIndex;

      if (type === 'proof') {
        step._isCurrentStep = step.state === Enum.WorkflowStepState.VISIBLE;
        step._isPastStep = step.state === Enum.WorkflowStepState.COMPLETE;
      }

      if (step.users) {
        const userEmails = step.users.map(user => user.email);
        step.users = step.users.filter((user, index) => {
          if (step._isLast) {
            user.role = 'approver';
          } else if (user.role === 'approver') {
            user.role = 'gatekeeper';
          }
          user._isOwner = !!ownersIndex[user.email];
          
          user._canBeRemoved = true;

          if (type === 'proof') {
            user._canBeRemoved = user._canBeRemoved && (user.decisionId !== Enum.Decision.SEND_CHANGES || proof.lockerUserId !== user.id);

            user._isLocker = step._isCurrentStep && proof && proof.lockerUserId === user.id;

            user._proofComments = proof.commentCounts[user.id] || { count: 0 }

            user._canBeSkipped = (
              user.id !== sdk.session.userId &&
              step.state === Enum.WorkflowStepState.VISIBLE &&
              [Enum.ProofStatus.PROOFING, Enum.ProofStatus.FINAL_APPROVING].includes(proof.status) &&
              ![Enum.WorkflowUserState.FINISHED, Enum.WorkflowUserState.SKIPPED].includes(user.state) &&
              [Enum.WorkflowUserRole.MANDATORY, Enum.WorkflowUserRole.GATEKEEPER].includes(user.role)
            )

            const canBeNudged = (
              user.id !== sdk.session.userId &&
              user.role !== Enum.WorkflowUserRole.VIEW_ONLY &&
              [Enum.ProofStatus.PROOFING, Enum.ProofStatus.FINAL_APPROVING].includes(proof.status) &&
              user.state !== Enum.WorkflowUserState.FINISHED &&
              [Enum.WorkflowStepState.COMPLETE, Enum.WorkflowStepState.VISIBLE].includes(step.state)
            );
            const isWithinNudgeCooldownPeriod = !!user.nudgedDate && isDateWithinNudgeCooldownPeriod(new Date(user.nudgedDate));

            user._canBeNudgedAfterCooldown = canBeNudged && isWithinNudgeCooldownPeriod;
            user._canBeNudged = canBeNudged && !isWithinNudgeCooldownPeriod;

            if (!proof.hasDecisionsEnabled) {
              user._isFinishedWithoutDecisionsEnabled = user.state === Enum.WorkflowUserState.FINISHED;
            }
          }

          return userEmails.indexOf(user.email) === index;
        });
      }
      step._doesPauseWorkflow = step._isLast || step.users.some((user) => (
        user.role === 'gatekeeper' ||
        user.role === 'mandatory'
      ));
      step._hasMultipleUsers = step.users.length > 1;
      step._canMoveUp = !step._isFirst;
      step._canMoveDown = !step._isLast;
      step._canBeDeleted = workflow._hasMultipleSteps && !(type === 'proof' && step.users.length);

      if (type === 'proof') {
        const nextStep = workflow.steps[index + 1];
        step._nextStepState = nextStep && nextStep.state;

        if (step.state === Enum.WorkflowStepState.COMPLETE) {
          if (!step._doesPauseWorkflow) {
            step._progressState = 'flowedThrough';
          } else if ((!nextStep && proof.approvedDate) || (nextStep && nextStep.state !== Enum.WorkflowStepState.NOT_VISIBLE)) {
            step._progressState = 'approved';
          } else if (proof.approvedDate && nextStep && nextStep.state === Enum.WorkflowStepState.NOT_VISIBLE) {
            step._progressState = 'manuallyApproved';
          } else {
            step._progressState = 'todos';
          }
        } else if (step.state === Enum.WorkflowStepState.VISIBLE) {
          if (proof.approvedDate) { 
            step._progressState = 'manuallyApproved';
          } else if ([Enum.ProofStatus.AWAITING_NEW_VERSION, Enum.ProofStatus.TODOS_REQUESTED].includes(proof.status)) {
            step._progressState = 'returnedByOwner';
          } else if (proof.isProofArchived === true && [Enum.ProofStatus.AWAITING_NEW_VERSION, Enum.ProofStatus.TODOS_REQUESTED].includes(proof.actualProofStatus) ) {
            step._progressState = 'returnedByOwner';
          } else if (proof.locker) {
            step._progressState = 'locked';
          } else if (step.position === 1000 && !step.users.length) {
            step._progressState = 'finalWithoutApprover';
          } else if (step.position === 1000 && !step.users.some(user => user.state !== Enum.WorkflowUserState.FINISHED)) {
            step._progressState = 'finalWithOnlyfinishedApprovers';
          } else {
            step._progressState = 'current';
          }
        } else {
          step._progressState = 'future';
        }
      }
    });
    const lastStep = workflow.steps[workflow.steps.length - 1];
    const secondLastStep = workflow.steps[workflow.steps.length - 2];
    if (lastStep && secondLastStep && type !== 'proofSetup') {
      secondLastStep._canMoveDown = lastStep._canMoveUp = lastStep._canBeDeleted = false;
    }
  }
};

const Workflow = ({ owners, workflow: sourceWorkflow, isReadOnly, type, events = {}, onChange, proof }) => {
  if (sourceWorkflow == null) {
    return null;
  }

  // make a copy, to allow nested components to modify the workflow without mutating the original object we're passed in...
  const workflow = JSON.parse(JSON.stringify(sourceWorkflow));
  calculateWorkflowAttributes(workflow, owners, type, proof);

  const handleOnChange = (isEdited = true) => {
    if (isReadOnly) {
      return;
    }
    calculateWorkflowAttributes(workflow, owners, type, proof); // some calculations
    workflow._isEdited = workflow._isEdited || isEdited;
    onChange(workflow);
  };

  return (
    <div className={css.Workflow}>
      {!isReadOnly && (['proofSetup', 'template'].includes(type) || workflow.steps.length === 1) && (
        <AddWorkflowStep
          workflow={workflow}
          events={events}
          type={type}
          onAddStep={(newStep) => {
            workflow._lastCreatedStepId = newStep._id;
            workflow.steps.unshift(newStep);
            events.onAddStep && events.onAddStep(workflow, newStep, 0);
            handleOnChange();
          }}
        />
      )}
      {workflow.steps.map((step, index) => (
        <WorkflowStep
          key={step.id || step._id || index}
          workflow={workflow}
          step={step}
          isReadOnly={isReadOnly}
          type={type}
          events={events}
          onChange={handleOnChange}
        />
      ))}
    </div>
  );
};

const AddWorkflowStep = ({ workflow, events, onAddStep, type }) => {
  const [isActive, setIsActive] = useState(false);

  const [collectedProps, dropRef] = useDrop({
    accept: workflow._id,
    drop(item) {
      const emptyStep = newEmptyStep();
      const currentStep = workflow.steps.find((step) => (step.id && step.id === item.step.id) || step._id === item.step._id) || item.step;
      moveUserIntoStep(workflow, item.user, currentStep, emptyStep, {
        onRemoveUser: events.onRemoveUser,
      });
      onAddStep(emptyStep);
    },
    collect(monitor) {
      return {
        isHovered: monitor.isOver(),
      };
    },
  });

  const forcedActive = (['template', 'proofSetup'].includes(type) && workflow.steps.length === 1) || collectedProps.isHovered;

  return (
    <button
      ref={dropRef}
      className={classname(css.AddWorkflowStep, {
        [css['AddWorkflowStep--active']]: isActive || forcedActive,
        [css['AddWorkflowStep--forcedActive']]: forcedActive,
      })}
      onClick={() => onAddStep(newEmptyStep())}
      onMouseEnter={() => !forcedActive && setIsActive(true)}
      onMouseLeave={() => !forcedActive && setIsActive(false)}
      onFocus={() => !forcedActive && setIsActive(true)}
      onBlur={() => !forcedActive && setIsActive(false)}
    >
      {type === 'proof' && workflow.steps.length > 1 && (
        <div className={classname(css.AddWorkflowStep__progressBar, {[css['AddWorkflowStep__progressBar--active']]: workflow.steps[workflow.steps.length - 1].state !== Enum.WorkflowStepState.NOT_VISIBLE })} />
      )}
      <div className={classname(css.AddWorkflowStep__button, { [css['AddWorkflowStep__button--onBackground']]: type === 'proof' })}>
        <InlineSVG
          src="/img/icons/plus-3.svg"
          className={css.AddWorkflowStep__button__plus}
        />
        <RevealTextHorizontal
          show={isActive || forcedActive}
          duration={200}
          children={
            <div className={css.AddWorkflowStep__button__text}>
              <Translation value="workflow.step.add-step" />
            </div>
          }
        />
      </div>
    </button>
  );
};

const StartWorkflowFromPosition = ({ skipped, start, onClick }) => {
  return (
    <div
      className={classname(css.StartWorkflowFromPosition, {
        [css['StartWorkflowFromPosition--visible']]: skipped || start,
        [css['StartWorkflowFromPosition--skipped']]: skipped,
        [css['StartWorkflowFromPosition--start']]: start,
      })}
    >
      <Tooltip
        up
        center
        disablePointerEvents
        title={
          skipped
            ? <Translation value="workflow.start-position.tooltip.step-skipped" />
            : start
              ? <Translation value="workflow.start-position.tooltip.will-start-here" />
              : <Translation value="workflow.start-position.tooltip.start-here" />
        }
      >
        <button
          className={css.StartWorkflowFromPosition__button}
          onClick={onClick}
        >
          <InlineSVG
            src="/img/icons/skip-over.svg"
            className={css.StartWorkflowFromPosition__icon}
          />
        </button>
      </Tooltip>
    </div>
  );
};

const WorkflowStepHeading = ({ workflow, step, isReadOnly, type, events, onChange }) => {
  const [isConfirming, setIsConfirming] = useState(false);

  const isLastStep = step._isLast;

  const placeholderI18n = isLastStep
    ? { value: 'workflow.final-step', params: {} }
    : { value: 'workflow.step-position', params: { position: step._position } };

  const doesRequireConfirmationToDelete = step.users.length > 0 || !!step.name;

  const onDelete = () => {
    workflow.steps.splice(workflow.steps.indexOf(step), 1);
    events.onDeleteStep && events.onDeleteStep(workflow, step);
    onChange();
  };

  const isDefaultStartStep = !workflow._hasStartStep && step._isFirst && type === 'proofSetup';
  const isStartStep = (step.start || isDefaultStartStep) && workflow._hasMultipleSteps;

  const stateTooltipTitle = (() => {
    switch (step._progressState) {
      case 'approved':
        return step.completeDate
          ? <Translation value="workflow.step.progress.approved.with-date" params={{ date: moment(step.completeDate).format('LLLL') }} />
          : <Translation value="workflow.step.progress.approved" />;
      case 'flowedThrough':
        return step.completeDate
          ? <Translation value="workflow.step.progress.flowed-through.with-date" params={{ date: moment(step.completeDate).format('LLLL') }} />
          : <Translation value="workflow.step.progress.flowed-through" />;
      case 'current':
        return <Translation value="workflow.step.progress.current" />;
      case 'returnedByOwner':
        return <Translation value="workflow.step.progress.returned-by-owner" />;
      case 'manuallyApproved':
        return <Translation value="workflow.step.progress.manually-approved" params={{ email: workflow._proof.locker.email, date: moment(workflow._proof.approvedDate).format('LLLL') }} />;
      case 'locked':
      return <Translation value="workflow.step.progress.locked" params={{ email: workflow._proof.locker.email }} />;
      case 'todos':
        return workflow._proof.locker
          ? <Translation value="workflow.step.progress.todos.by-user" params={{ email: workflow._proof.locker.email }} />
          : <Translation value="workflow.step.progress.todos" />;
      case 'future':
        return <Translation value="workflow.step.progress.future" />;
      case 'finalWithoutApprover':
        return <Translation value="workflow.step.progress.final-without-approver" />;
      case 'finalWithOnlyfinishedApprovers':
        return <Translation value="workflow.step.progress.final-with-only-finished-approvers" />;
      }
  })() 

  return (
    <WhenSticky
      // This key forces WhenSticky to re-render when steps are reordered. Removing it results in false positives.
      key={`${step._id}:${step._position}`}
      className={classname(css.WorkflowStepHeading, css['WorkflowStepHeading--sticky'])}
    >
      <div className={css.WorkflowStepHeading}>
        {type !== 'template' && (
          <div className={classname(css.WorkflowStepHeading__asideTools, {
            [css['WorkflowStepHeading__asideTools--proofSetup']]: type === 'proofSetup',
          })}>
            <WorkflowStepDueDate
              completeDate={step.completeDate && new Date(step.completeDate)}
              onChange={(date) => {
                step.dueDate = date;
                events.onUpdateStepDueDate && events.onUpdateStepDueDate(workflow, step);
                onChange();
              }}
              dueDate={step.dueDate && new Date(step.dueDate)}
              readOnly={isReadOnly}
            />
            {workflow._hasMultipleSteps && type === 'proofSetup' && (
              <StartWorkflowFromPosition
                skipped={step._isSkipped}
                start={isStartStep}
                onClick={() => {
                  if (isDefaultStartStep) {
                    // The state won't change, so to avoid confusion, we don't toggle the start step
                    // when the default start step is defined, as it'll enable/disable internally, however
                    // the view won't change to the user, and can be confusing if they then add a step.
                  } else {
                    const enable = !step.start;
                    workflow.steps.forEach((step) => {
                      delete step.start;
                    });
                    if (enable) {
                      step.start = true;
                    }
                    onChange(false);
                  }
                }}
              />
            )}
          </div>
        )}
        {type === 'proof' && (
          <Tooltip
            up
            center
            title={stateTooltipTitle}
            disabled={!stateTooltipTitle}
          >
            <div
              className={classname(css.WorkflowStepHeading__progressState, {
                [css['WorkflowStepHeading__progressState--current']]: step._isCurrentStep,
                [css['WorkflowStepHeading__progressState--approved']]: ['approved', 'flowedThrough', 'manuallyApproved'].includes(step._progressState),
                [css['WorkflowStepHeading__progressState--todos']]: ['todos', 'returnedByOwner'].includes(step._progressState),
                [css['WorkflowStepHeading__progressState--first']]: step._isFirst,
                [css['WorkflowStepHeading__progressState--locked']]: step._progressState === 'locked',
              })}
            >
              {['approved', 'flowedThrough', 'manuallyApproved'].includes(step._progressState) && <InlineSVG src="/img/icons/mark-done.svg" />}
              {['todos', 'returnedByOwner'].includes(step._progressState) && <div className={css.WorkflowStepHeading__progressState__dot} />}
              {step._progressState === 'locked' && <InlineSVG src="/img/interface/padlock.svg" />}
            </div>
          </Tooltip>
        )}
        {isReadOnly
          ? (
            <div className={css.WorkflowStepHeading__name}>
              {step.name || <Translation {...placeholderI18n} />}
            </div>
          )
          : (
            <TranslatedProps placeholder={placeholderI18n}>
              <input
                className={css.WorkflowStepHeading__name}
                defaultValue={step.name}
                onBlur={(event) => {
                  if (step.name !== event.target.value) {
                    step.name = event.target.value;
                    events.onUpdateStepName && events.onUpdateStepName(workflow, step);
                    onChange();
                  }
                }}
                maxLength={64}
              />
            </TranslatedProps>
          )
        }
        {!isReadOnly && (!isLastStep || type === 'proofSetup') && (
          <div className={css.WorkflowStepHeading__options}>
            {type !== 'proof' && (
              <Fragment>
                <Tooltip up center disablePointerEvents title={<Translation value="workflow.step.move-step-up" />} disabled={!step._canMoveUp}>
                  <button
                    className={css.WorkflowStepHeading__option}
                    disabled={!step._canMoveUp}
                    onClick={() => {
                      moveStep(workflow, step, -1, events);
                      onChange();
                    }}
                  >
                    <div
                      className={classname(css.WorkflowStepHeading__option__icon, css['WorkflowStepHeading__option__icon--upsideDown'])}
                    >
                      <InlineSVG
                        src="/img/icons/arrow-down.svg"
                      />
                    </div>
                  </button>
                </Tooltip>
                <Tooltip up center disablePointerEvents title={<Translation value="workflow.step.move-step-down" />} disabled={!step._canMoveDown}>
                  <button
                    className={css.WorkflowStepHeading__option}
                    disabled={!step._canMoveDown}
                    onClick={() => {
                      moveStep(workflow, step, +1, events);
                      onChange();
                    }}
                  >
                    <div
                      className={css.WorkflowStepHeading__option__icon}
                    >
                      <InlineSVG
                        src="/img/icons/arrow-down.svg"

                      />
                    </div>
                  </button>
                </Tooltip>
              </Fragment>
            )}
            {(type !== 'proof' || step._canBeDeleted) && (
              <PopupMenu
                options={
                  <Option
                    label={(
                      <Fragment>
                        <Translation value="option.edit.confirm" />
                        <InlineSVG
                          src={'/img/content/proof/icons/delete.svg'}
                          className={css.WorkflowStepHeading__option__confirmIcon}
                        />
                      </Fragment>)}
                    onClick={onDelete}
                  />
                }
                disabled={!step._canBeDeleted || !doesRequireConfirmationToDelete}
                onShow={() => setIsConfirming(true)}
                onHide={() => setIsConfirming(false)}
              >
                <div className={css.WorkflowStepHeading__option__confirmTooltip}>
                  <Tooltip
                    up
                    center
                    disablePointerEvents
                    title={<Translation value="option.delete" />}
                    disabled={isConfirming}
                  >
                    <button
                      className={css.WorkflowStepHeading__option}
                      disabled={!step._canBeDeleted}
                      onClick={() => {
                        if (!doesRequireConfirmationToDelete) {
                          onDelete();
                        }
                      }}
                    >
                      <InlineSVG
                        src="/img/content/proof/icons/delete.svg"
                        className={css.WorkflowStepHeading__option__icon}
                      />
                    </button>
                  </Tooltip>
                </div>
              </PopupMenu>
            )}
          </div>
        )}
      </div>
    </WhenSticky>
  );
};

const getWorkflowValidationMessage = ({ type, value }) => {
  switch (type) {
    case 'existingEmails': {
      return value.length > 1
        ? (
          <Translation
            value="workflow.error.existing-email.multi"
            params={{
              emails: value.slice(0, value.length - 1).join(', '),
              lastEmail: value[value.length - 1],
            }}
          />
        )
        : (
          <Translation
            value="workflow.error.existing-email"
            params={{ email: value[0] }}
          />
        );
    }
    case 'invalidEmail': {
      return (
        <Translation
          value="workflow.error.email-is-not-valid"
          params={{ email: value }}
        />
      );
    }
    case 'invalidGroup': {
      return (
        <Translation
          value="workflow.error.group-is-not-valid"
          params={{ groupName: value }}
        />
      );
    }
    default: {
      return null;
    }
  }
};

const WorkflowStep = ({ workflow, step, isReadOnly, type, events, onChange }) => {
  const isFirstStep = step._isFirst;
  const isLastStep = step._isLast;
  const isDefaultStartStep = !workflow._hasStartStep && isFirstStep && type === 'proofSetup';
  const isStartStep = (step.start || isDefaultStartStep) && workflow._hasMultipleSteps;

  const [validation, setValidation] = useState({});
  
  const [collectedProps, dropRef] = useDrop({
    accept: workflow._id,
    drop(item) {
      const currentStep = workflow.steps.find((step) => (step.id && step.id === item.step.id) || step._id === item.step._id) || item.step;
      moveUserIntoStep(workflow, item.user, currentStep, step, events);
      onChange();
    },
    collect(monitor) {
      return {
        isHovered: monitor.isOver() && monitor.canDrop(),
      };
    },
    canDrop(item) {
      return canMoveUserIntoStep(item.user, item.step, step);
    },
  });

  const validationErrorType = Object.keys(validation)[0];

  return (
    <React.Fragment>
      <div
        className={classname(css.WorkflowStep, {
          [css['WorkflowStep--highlighted']]: type !== 'proof' && isStartStep,
          [css['WorkflowStep--dropping']]: collectedProps.isHovered,
        })}
        ref={dropRef}
      >
        {type === 'proof' && (
          <div className={classname(css.WorkflowStep__progressBar, {
            [css['WorkflowStep__progressBar--incomplete']]: step._isCurrentStep || !step._nextStepState || step._nextStepState === Enum.WorkflowStepState.NOT_VISIBLE,
            [css['WorkflowStep__progressBar--last']]: step._isLast,
          })} />
        )}
        {!!validationErrorType && (
          <ValidationToast
            message={getWorkflowValidationMessage({
              type: validationErrorType,
              value: validation[validationErrorType]
            })}
            onDismiss={() => {
              setValidation((validation) => {
                const updatedValidation = { ...validation };
                delete updatedValidation[validationErrorType];
                return updatedValidation;
              })
              delete validation[validationErrorType];
            }}
            shadow="large"
          />
        )}
        <WorkflowStepHeading
          workflow={workflow}
          step={step}
          isReadOnly={isReadOnly}
          type={type}
          events={events}
          onChange={onChange}
        />
        {step.users.length === 0 && isReadOnly && (
          <div className={css.WorkflowStep__emptyMessage}>
            No reviewers
          </div>
        )}
        {step.users.map(user => (
          <WorkflowUser
            setValidation={setValidation}
            key={user.email}
            workflow={workflow}
            step={step}
            user={user}
            type={type}
            isReadOnly={isReadOnly}
            events={events}
            onChange={onChange}
          />
        ))}
        {!isReadOnly && (
          <AddWorkflowUser
            setValidation={setValidation}
            workflow={workflow}
            step={step}
            events={events}
            onChange={onChange}
            type={type}
          />
        )}
        <WorkflowStepFooter
          setValidation={setValidation}
          workflow={workflow}
          step={step}
          isReadOnly={isReadOnly}
          events={events}
          onChange={onChange}
          type={type}
        />
        {type === 'proof' && step.state === Enum.WorkflowStepState.NOT_VISIBLE && (
          <div className={css.WorkflowStep__notVisible} />
        )}
      </div>
      {type === 'proof' && ['returnedByOwner', 'manuallyApproved'].includes(step._progressState) && (
        <div className={classname(css.WorkflowStep__completedManually, {
          [css['WorkflowStep__completedManually--beforeLastStep']]: !isLastStep,
        })}>
          <div
            className={classname(css.WorkflowStep__completedManually__message, {
              [css['WorkflowStep__completedManually__message--approved']]: step._progressState === 'manuallyApproved',
            })}
          >
            {step._progressState === 'manuallyApproved' && `Approved by ${workflow._proof.locker.email}`}
            {step._progressState === 'returnedByOwner' && 'Returned to owner'}
          </div>
        </div>
      )}
      {!isLastStep && !isReadOnly && !(type === 'proof' && (!step._isRightBeforeLastStep || workflow._isFinished || workflow.steps.some(step => step.position === 900))) && (
        <AddWorkflowStep
          workflow={workflow}
          events={events}
          type={type}
          onAddStep={(newStep) => {
            workflow._lastCreatedStepId = newStep._id;
            const stepIndex = workflow.steps.indexOf(step) + 1;
            workflow.steps.splice(stepIndex, 0, newStep);
            events.onAddStep && events.onAddStep(workflow, newStep, stepIndex);
            onChange();
          }}
        />
      )}
    </React.Fragment>
  );
};

const getFinalStepFooterTranslationKey = (type, step) => {
  if (type !== 'proof') {
    if (step.users.length > 1 || step.mandatoryDecisionThreshold > 1) {
      return step.mandatoryDecisionThreshold === 1
        ? 'workflow.step.footer.final-step.threshold.one'
        : 'workflow.step.footer.final-step.threshold.many';
    }

    return 'workflow.step.footer.final-step';
  }

  if (!step.users.length) {
    if (step.mandatoryDecisionThreshold > 1) {
      return 'workflow.step.footer.final-step.no-users.threshold.many';
    }

    if (step.mandatoryDecisionThreshold === 1) {
      return 'workflow.step.footer.final-step.no-users.threshold.one';
    }

    return 'workflow.step.footer.final-step.no-users';
  }

  if (step._progressState === 'finalWithOnlyfinishedApprovers') {
    if (step.mandatoryDecisionThreshold === 1) {
      return 'workflow.step.footer.final-step.many-users.only-finished-approvers.threshold.one';
    }

    return 'workflow.step.footer.final-step.many-users.only-finished-approvers.threshold.many';
  }

  if (step.users.length === 1 && step.mandatoryDecisionThreshold < 2) {
    return 'workflow.step.footer.final-step';
  }

  if (step.mandatoryDecisionThreshold === 1) {
    return 'workflow.step.footer.final-step.threshold.one';
  }

  return 'workflow.step.footer.final-step.threshold.many';
};

const WorkflowStepFooter = ({ type, workflow, step, isReadOnly, events, onChange }) => {
  const roles = {};
  step.users.forEach((user) => {
    roles[user.role] = (roles[user.role] || 0) + 1;
  });

  let translationKey = '';
  let supportsThreshold = false;

  const totalMandatoriesAndGatekeepersAndApprover = (roles.mandatory || 0) + (roles.gatekeeper || 0) + (roles.approver || 0);

  if (step._isLast) {
    translationKey = getFinalStepFooterTranslationKey(type, step);
    supportsThreshold = roles.approver > 1 || step.mandatoryDecisionThreshold > 1;
  } 
  else if (roles.mandatory && roles.gatekeeper) {
    translationKey = step.mandatoryDecisionThreshold === 1
      ? 'workflow.step.footer.mandatory-and-gatekeeper.threshold.one'
      : step.mandatoryDecisionThreshold === null
        ? 'workflow.step.footer.mandatory-and-gatekeeper.threshold.many'
          : 'workflow.step.footer.mandatory-and-gatekeeper.threshold.many.custom-threshold';
    supportsThreshold = true;
  }
  else if (roles.gatekeeper) {
    if (roles.gatekeeper > 1 || step.mandatoryDecisionThreshold > 1) {
      translationKey = step.mandatoryDecisionThreshold === 1
        ? 'workflow.step.footer.gatekeeper.threshold.one'
        : 'workflow.step.footer.gatekeeper.threshold.many';
      supportsThreshold = true;
    } else {
      translationKey = 'workflow.step.footer.gatekeeper';
    }
  }
  else if (roles.mandatory) {
    if (roles.mandatory > 1 || step.mandatoryDecisionThreshold > 1) {
      translationKey = step.mandatoryDecisionThreshold === 1
        ? 'workflow.step.footer.mandatory.threshold.one'
        : 'workflow.step.footer.mandatory.threshold.many';
      supportsThreshold = true;
    } else {
      translationKey = 'workflow.step.footer.mandatory';
    }
  }
  else {
    translationKey = 'workflow.step.footer.only-reviewers';
  }

  const defaultThresholdInputValue = (!step.mandatoryDecisionThreshold || step.mandatoryDecisionThreshold < 2) ? '2' : step.mandatoryDecisionThreshold.toString();

  const [mandatoryDecisionThresholdInputValue, setMandatoryDecisionThresholdInputValue] = useState(defaultThresholdInputValue);

  useEffect(() => {
    if (!step.mandatoryDecisionThreshold || step.mandatoryDecisionThreshold < 2) {
      return;
    }
    setMandatoryDecisionThresholdInputValue(step.mandatoryDecisionThreshold.toString());
  }, [step.mandatoryDecisionThreshold]);

  const onThresholdInputBlur = () => {
    const parsedInput = Number(mandatoryDecisionThresholdInputValue);

    if (!parsedInput) {
      setMandatoryDecisionThresholdInputValue(step.mandatoryDecisionThreshold > 1 ? step.mandatoryDecisionThreshold.toString() : defaultThresholdInputValue);
      return;
    }

    if (parsedInput > 1000) {     
      setMandatoryDecisionThresholdInputValue('1000');
      setMandatoryDecisionThreshold(1000);
      // We've changed the value, so return false to keep the popover open making it more obvious to the user
      return;
    }

    setMandatoryDecisionThresholdInputValue(parsedInput.toString());
    setMandatoryDecisionThreshold(parsedInput);

    return true;
  }


  const setMandatoryDecisionThreshold = (threshold) => {
    step.mandatoryDecisionThreshold = threshold;
    events.onUpdateStepMandatoryDecisionThreshold && events.onUpdateStepMandatoryDecisionThreshold(workflow, step);
    onChange();
    if (threshold > 1) {
      setMandatoryDecisionThresholdInputValue(threshold.toString());
    }
  };

  const canEditThreshold = !isReadOnly && !step._isPastStep && !step._isCurrentStep;


  const thresholdText = (
    <span className={css.WorkflowStepFooter__count}>
      <span
        className={classname(css.WorkflowStepFooter__count__value, {
          [css['WorkflowStepFooter__count__value--editable']]: canEditThreshold,
        })}
      >
        {(step.mandatoryDecisionThreshold === null || step.mandatoryDecisionThreshold === undefined)
          ? 'all'
          : step.mandatoryDecisionThreshold === 1
            ? 'just one'
            : (step.mandatoryDecisionThreshold > totalMandatoriesAndGatekeepersAndApprover
              ? (
                <Fragment>
                  all
                  <span className={css.WorkflowStepFooter__count__value__warning}>
                    &nbsp;(up to {step.mandatoryDecisionThreshold})
                  </span>
                </Fragment>
              )
              : step.mandatoryDecisionThreshold.toString()
            )
        }
      </span>
    </span>
  );

  const showWarning = step._progressState === 'finalWithOnlyfinishedApprovers';

  return (
    <div
      className={classname(css.WorkflowStepFooter, {
        [css['WorkflowStepFooter--light']]: !step._doesPauseWorkflow,
        [css['WorkflowStepFooter--dark']]: step._doesPauseWorkflow && !showWarning,
        [css['WorkflowStepFooter--warning']]: showWarning,
      })}
    >
      <div
        className={css.WorkflowStepFooter__stopIcon}
      >
        <InlineSVG
          src={step._doesPauseWorkflow
            ? (
              !supportsThreshold || step.mandatoryDecisionThreshold === 1
                ? 'img/icons/person-no-background.svg'
                : 'img/icons/group.svg'
            )
            : 'img/icons/material/symbols/keyboard_double_arrow_down_FILL0_wght400_GRAD0_opsz24.svg'
          }
        />
      </div>
      <Translation
        value={translationKey}
        params={{
          manuallyApproved: (
            <span
              className={classname({[css.WorkflowStepFooter__button]: events.onManuallyApprove})}
              onClick={events.onManuallyApprove}
            >
              manually approved
            </span>
          ),
          threshold: (
            !canEditThreshold
              ? thresholdText
              : (
                <PopupMenu
                  options={(popover) => (
                    <Fragment>
                      <Option
                        label={
                          <Flex container>
                            <InlineSVG
                              src="img/icons/group.svg"
                              className={css.WorkflowStepFooter__thresholdIcon}
                            />
                            All
                          </Flex>
                        }
                        checked={step.mandatoryDecisionThreshold === null}
                        onClick={() => setMandatoryDecisionThreshold(null)}
                      />
                      <Option
                        label={
                          <Flex container>
                            <InlineSVG
                              src="img/icons/group.svg"
                              className={css.WorkflowStepFooter__thresholdIcon}
                            />
                            <Input
                              onClick={(event) => event.stopPropagation()}
                              type="number"
                              min={1}
                              className={css.WorkflowStepFooter__thresholdInput}
                              style={{ width: `${(mandatoryDecisionThresholdInputValue.toString().length || 1) + 1}ch` }}
                              value={mandatoryDecisionThresholdInputValue}
                              onChange={setMandatoryDecisionThresholdInputValue}
                              onBlur={onThresholdInputBlur}
                              onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                  if (onThresholdInputBlur()) {
                                    popover.hide();
                                  }
                                }
                              }}
                            />
                          </Flex>
                        }
                        checked={step.mandatoryDecisionThreshold > 1}
                        onClick={() => setMandatoryDecisionThreshold(Number(mandatoryDecisionThresholdInputValue))}
                      />
                      <Option
                        label={
                          <Flex container>
                            <InlineSVG
                              src="img/icons/person-no-background.svg"
                              className={css.WorkflowStepFooter__thresholdIcon}
                            />
                            Just one
                          </Flex>
                        }
                        checked={step.mandatoryDecisionThreshold === 1}
                        onClick={() => setMandatoryDecisionThreshold(1)}
                      />
                    </Fragment>
                  )}
                >
                  {thresholdText}
                </PopupMenu>
              )
          ),
        }}
      />
    </div>
  );
};

const WorkflowUserSwitchRole = ({ workflow, step, user, events, onChange }) => {
  let availableRoles = [...(workflow.availableRoles || ['reviewer', 'mandatory', 'gatekeeper', 'approver'])];

  if (user.role === Enum.WorkflowUserRole.VIEW_ONLY && !availableRoles.includes(Enum.WorkflowUserRole.VIEW_ONLY)) {
    availableRoles.unshift(Enum.WorkflowUserRole.VIEW_ONLY);
  }
  

  if (step._isLast) {
    availableRoles = ['approver'];
  } else {
    availableRoles = availableRoles.filter(role => role !== 'approver');
  } 

  if (step._isPastStep) {
    availableRoles = availableRoles.filter(role => role === user.role || (['view-only', 'reviewer'].includes(role) && ['view-only', 'reviewer'].includes(user.role)));
  }

  return (
    <div className={css.WorkflowUserSwitchRole}>
      {(step._isPastStep) && (
        <div className={css.WorkflowUserSwitchRole__cantLowerMessage}>
          {availableRoles.length > 1
            ? <Translation value="proof-info.proofer-role.past-step.unraisable" />
            : <Translation value="proof-info.proofer-role.past-step" />
          }
        </div>
      )}
      {availableRoles.map(role => (
        <button
          key={role}
          disabled={user.role === role}
          className={css.WorkflowUserSwitchRole__option}
          onClick={() => {
            user.role = role;
            events.onUpdateUserRole && events.onUpdateUserRole(workflow, step, user);
            onChange();
          }}
        >
          <div className={css.WorkflowUserSwitchRole__option__tick}>
            {user.role === role && (
              <span>✓</span>
            )}
          </div>
          <div className={css.WorkflowUserSwitchRole__option__description}>
            <div
              className={classname(css.WorkflowUserSwitchRole__option__name, {
                [css['WorkflowUserSwitchRole__option__name--important']]: !['view-only', 'reviewer'].includes(role),
              })}
            >
              <Translation value={`workflow.role.${role}`} />
            </div>
            <Translation value={`workflow.role.${role}.description`} />
          </div>
        </button>
      ))}
    </div>
  );
};

const AddWorkflowUser = ({ workflow, step, events, onChange, setValidation, type }) => {
  const text = useText();
  const [_, setUpdate] = useState({});
  const forceUpdate = () => setUpdate({});

  const [showMore, setShowMore] = useState(false);
  const emailRef = useRef();

  const [user, setUser] = useState(() => {
    const user = newEmptyUser();
    if (step._isLast) {
      user.role = 'approver';
    }
    return user;
  });

  useEffect(() => {
    if (step._isLast) {
      if (user.role !== 'approver') {
        user.role = 'approver';
        onChange();
        forceUpdate();
      }
    } else if (user.role === 'approver') {
      user.role = 'gatekeeper';
      onChange();
      forceUpdate();
    }
  }, [
    step._isLast,
  ]);

  const commonProps = {
    workflow,
    step,
    user,
    // onChange,
    onChange: forceUpdate,
  };

  const submit = () => {
    const addedUsers = [];
    const emails = commaSeparatedEmails(user.email);

    if (emails.length) {
      emailRef.current.focus();

      const existingEmails = new Set();

      emails.forEach((email) => {
        if (doesUserAlreadyExistInStep(step, email)) {
          existingEmails.add(email);
        } else {
          const emailUser = copyUser(user);
          emailUser.email = email;
          emailUser._isNewlyAdded = true;
          if (!emailUser.permissions.inviter) {
            emailUser.permissions.inviter =  workflow.steps.some((step) => (
              step.users.some((user) => user.email === email && user.permissions.inviter)
            ));
          } else {
            workflow.steps.forEach((step) => {
              step.users.forEach((user) => {
                if (user.email === email) {
                  user.permissions.inviter = true;
                }
              });
            });
          }
          step.users.push(emailUser);
          addedUsers.push(emailUser);
        }

        Suggestions.addEmail(email);
      });

      if (existingEmails.size) {
        addExistingEmailsValidation(setValidation, existingEmails);
      }
    } else {
      if (user.email.trim().length) {
        addInvalidEmailValidation(setValidation, user.email);
      }
    }

    // Create a new empty user shell, and copy across the role and permissions
    const newUser = newEmptyUser();
    newUser.role = user.role;
    newUser.permissions = user.permissions;
    setUser(newUser);

    if (addedUsers.length && events.onAddUsers) {
      events.onAddUsers(workflow, step, addedUsers);
    }

    // Have to duplicate this check because the proofSetup screen doesn't use the new events.
    if (addedUsers.length) {
      onChange();
    }
  };

  const getEditEmailPlaceholderTranslationKey = () => {
    if (step._isLast) {
      return 'workflow.user.placeholder.last-step';
    }
    if (step.users.length >=1) {
      return 'workflow.user.placeholder.step-has-user';
    }
    return 'workflow.user.placeholder';
  };

  const rolesRestrictedInPreviousStep = [Enum.WorkflowUserRole.MANDATORY, Enum.WorkflowUserRole.GATEKEEPER];
  function validateRole (requestedRoleName) {
    const requestedRole = toWorkflowUserRole(requestedRoleName);
    let role = Enum.WorkflowUserRole.REVIEWER

    if (step._isLast) {
      return Enum.WorkflowUserRole.APPROVER;
    }
    
    if (workflow.availableRoles.includes(requestedRole)) {
      role = requestedRole;
    }

    if (step._isPastStep && rolesRestrictedInPreviousStep.includes(requestedRole)) {    
      role = Enum.WorkflowUserRole.REVIEWER;
    }

    return role;
  }

  /** Accepts an array of { email: string, role?: string }. The role is optional. */
  const addUsersToWorkflowStep = users => {
    const addedUsers = [];
    const existingEmails = new Set();

    users.forEach(userToAdd => {
      const { email, role } = userToAdd;
      const validatedRole = validateRole(role);

      if (doesUserAlreadyExistInStep(step, email)) {
        existingEmails.add(email);
      } else {
        const newStepUser = { ...newEmptyUser(),
          _isNewlyAdded: true,
          email: email,
          role: validatedRole,
          permissions : { inviter: isUserAnInviterInAnotherStep(email, workflow) },
        }
        step.users.push(newStepUser);
        addedUsers.push(newStepUser);
      }
    });

    if (existingEmails.size) {
      addExistingEmailsValidation(setValidation, existingEmails);
    }

    if (addedUsers.length && events.onAddUsers) {
      events.onAddUsers(workflow, step, addedUsers);
    }

    // Have to duplicate this check because the proofSetup screen doesn't use the new events.
    if (addedUsers.length) {
      onChange();
    }
  }

  /** Resets the user input with any provided settings, ensuring correct role in final step */
  const resetUserInput = (userTemplate) => {
    const newUser = { ...newEmptyUser(), ...userTemplate };
    if (step.position === 1000) {
      user.role = Enum.WorkflowUserRole.APPROVER;
    }
    setUser({ ...newUser });
  }

  return (
    <div className={classname(css.WorkflowUser, css['WorkflowUser--add'])}>
      <div className={css.WorkflowUser__details}>
        <div className={css.WorkflowUser__email}>
          <Suggestions
            includeUserGroups
            inputRef={emailRef}
            exclusions={step.users.map(user => user.email)}
            onSelect={(groupOrEmail) => {
              if (isUserGroupObject(groupOrEmail)) {
                addUsersToWorkflowStep(groupOrEmail.members);
                resetUserInput({ role: user.role, permissions: user.permissions });
                emailRef.current.focus();
                return;
              }

              user.email = groupOrEmail;
              submit();
            }}
          >
            <input
              autoFocus={workflow.steps.length === 1 || workflow._lastCreatedStepId === step._id}
              placeholder={text(getEditEmailPlaceholderTranslationKey())} // The Suggestions component requires <input /> as a direct child for attaching events, so we can't use TranslatedProps component here as it will break Suggestions.
              className={css.WorkflowUser__email__edit}
              value={user.email}
              onChange={(event) => {
                user.email = event.target.value;
                setUser({ ...user });
              }}
              onBlur={(event) => {
                if (isUserGroupInput(user.email)) {
                  return;
                }
                submit(event);
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  // A completely typed out group name will be 'selected' by Suggestions component, this is an incomplete group name
                  if (isUserGroupInput(user.email)) {
                    addInvalidGroupValidation(setValidation, [user.email]);
                    return;
                  }
                  submit(event);
                }
              }}
            />
          </Suggestions>
        </div>
        <div className={css.WorkflowUser__options}>
          <WorkflowUser.Role
            {...commonProps}
            canChange
            onClick={() => {
              setShowMore(!showMore);
            }}
          />
          <WorkflowUser.Inviter
            {...commonProps}
            canChange
            events={{}}
          />
          <WorkflowUser.MoreButton
            state={showMore}
            setState={setShowMore}
          />
        </div>
      </div>
      <WorkflowUser.More
        show={showMore}
        onHide={() => {
          setShowMore(false);
          if (type !== 'proof') {
            emailRef.current.focus();
          }
        }}
        {...commonProps}
        type={type}
        events={{}}
      />
    </div>
  );
};

const WorkflowUser = ({ workflow, step, user, type, isReadOnly, events, onChange, setValidation }) => {
  const canDrag = !isReadOnly && user._canBeRemoved;

  const [showMore, setShowMore] = useState(false);
  const inputRef = useRef();
  const [_, dragRef, previewRef] = useDrag({
    type: workflow._id,
    item: {
      type: workflow._id,
      user,
      step,
    },
    canDrag,
  });

  const commonProps = {
    workflow,
    step,
    user,
    onChange,
  };

  return (
    <div className={css.WorkflowUser}>
      <div className={css.WorkflowUser__details} ref={previewRef}>
        {type === 'proof' && (
          <div className={css.WorkflowUser__details__status}>
            <WorkflowUserStatus
              isLocker={user._isLocker}
              isSkipped={user.state === Enum.WorkflowUserState.SKIPPED}
              hasSeen={user.state === Enum.WorkflowUserState.SEEN || user.firstViewedDate}
              decisionId={user.decisionId}
              isFinishedWithoutDecisionsEnabled={user._isFinishedWithoutDecisionsEnabled}
              decisionDate={user.decisionDate}
              firstViewedDate={user.firstViewedDate}
            />
          </div>
        )}
        <WorkflowUser.Avatar
          {...commonProps}
          dragRef={dragRef}
          canDrag={canDrag}
          type={type}
        />
        <WorkflowUser.Email
          key={`${user.email}-${user.role}${user._isReplaced ? '-replaced' : ''}`}
          inputRef={inputRef}
          {...commonProps}
          events={events}
          setValidation={setValidation}
          canChange={!isReadOnly}
        />
        <div className={css.WorkflowUser__options}>
          <WorkflowUser.Role
            {...commonProps}
            canChange={!isReadOnly}
            onClick={() => {
              setShowMore(!showMore);
            }}
          />
          <WorkflowUser.Inviter
            {...commonProps}
            canChange={!isReadOnly}
            events={events}
          />
          {!isReadOnly && (
            <WorkflowUser.MoreButton
              state={showMore}
              setState={setShowMore}
            />
          )}
        </div>
      </div>
      <WorkflowUser.More
        show={showMore && !isReadOnly}
        onHide={() => {
          setShowMore(false);
          if (type !== 'proof') {
            inputRef.current.focus();
          }
        }}
        type={type}
        {...commonProps}
        events={events}
      />
    </div>
  );
};

WorkflowUser.Avatar = ({ dragRef, workflow, step, user, canDrag, onChange, type }) => {
  const dragDropManager = useDragDropManager()
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    return dragDropManager.getMonitor().subscribeToStateChange(() => {
      const monitor = dragDropManager.getMonitor();
      const item = monitor.getItem();
      setIsDragging(monitor.isDragging() && item && item.user.id === user.id && item.step.id === step.id);
    })
  }, [])

  const tooltipProps = type === 'proof'
    ? {
      hoverCard: true,
      delay: 500,
      variant: 'light',
      title: (tooltip) =>  <WorkflowUser.HoverCard onHide={() => tooltip.hide()} user={user} workflow={workflow} />,
    }
    : {
      title: user.email,
      disablePointerEvents: true,
    }

  return (
    <Tooltip 
      disabled={isDragging}
      up
      center
      {...tooltipProps}
    >
      <div
        className={classname(css.WorkflowUser__avatar, {
          [css['WorkflowUser__avatar--canDrag']]: canDrag,
        })}
        ref={dragRef}
      >
        {user.email && (
          <Avatar
            active
            url={getAvatarUrl(user)}
            size={26}
            spinner
          />
        )}
      </div>
    </Tooltip>
  );
};

WorkflowUser.Email = ({ workflow, step, user, canChange, events, onChange, inputRef, setValidation }) => {
  return (
    <Tooltip title={user.email} up right disablePointerEvents>
      <div
        className={classname(css.WorkflowUser__email, {
          [css['WorkflowUser__email--slide']]: user.email && user._isNewlyAdded
        })}
      >
        <Suggestions
          inputRef={inputRef}
          exclusions={step.users.map(user => user.email)}
          onSelect={(email) => {
            const originalUser = JSON.parse(JSON.stringify(user));

            user.email = email;
            removePropertiesForReplacedUser(user);
            const addUserResult = events.onAddUsers && events.onAddUsers(workflow, step, [user], false);
            
            // We need to make sure that the new user is added first to avoid the workflow progressing when swapping a mandatory reviewer
            Promise.resolve(addUserResult).then(() => {
              events.onRemoveUser && events.onRemoveUser(workflow, step, originalUser); 
            });
            onChange();
          }}
        >
          <input
            disabled={!canChange}
            className={css.WorkflowUser__email__edit}
            defaultValue={user.email}
            onBlur={(event) => {
              const email = event.target.value;

              if (email.length === 0) {
                step.users.splice(step.users.indexOf(user), 1);
                events.onRemoveUser && events.onRemoveUser(workflow, step, user);
                onChange();
                return;
              }

              if (email === user.email) {
                return; // The user hasn't changed the user's email address, skip.
              }

              if (window.validateEmail(email)) {
                if (doesUserAlreadyExistInStep(step, email)) {
                  addExistingEmailsValidation(setValidation, [email]);
                  event.target.value = user.email;
                } else {
                  const originalUser = JSON.parse(JSON.stringify(user));

                  user.email = email;
                  removePropertiesForReplacedUser(user);
                  const addUserResult = events.onAddUsers && events.onAddUsers(workflow, step, [user], false);
                  
                  // We need to make sure that the new user is added first to avoid the workflow progressing when swapping a mandatory reviewer
                  Promise.resolve(addUserResult).then(() => {
                    events.onRemoveUser && events.onRemoveUser(workflow, step, originalUser); 
                  });
                }

                onChange();
                Suggestions.addEmail(user.email);
              } else {
                event.target.value = user.email;

                addInvalidEmailValidation(setValidation, email);
                onChange();
              }
            }}
            onFocus={(event) => {
              event.target.select();
            }}
          />
        </Suggestions>
      </div>
    </Tooltip>
  );
};

WorkflowUser.Role = ({ workflow, step, user, canChange, onClick }) => {
  return (
    <Tooltip
      title={
        <Translation
          value={`workflow.role.${user.role}.description`}
        />
      }
      up
      center
      disablePointerEvents
    >
      <button
        className={classname(css.WorkflowUser__role, {
          [css['WorkflowUser__role--important']]: !['view-only', 'reviewer'].includes(user.role),
        })}
        onClick={onClick}
        disabled={!canChange}
      >
        <Media query="(max-width: 600px)">
          {(matched) => (
            matched
              ? <Translation value={`workflow.role.${user.role}.abbreviation`} />
              : <Translation value={`workflow.role.${user.role}`} />
          )}
        </Media>
      </button>
    </Tooltip>
  );
};

WorkflowUser.Inviter = ({ workflow, step, user, canChange, events, onChange }) => {
  return (
    <div className={css.WorkflowUser__canInvite}>
      <Tooltip
        title={
          <Translation
            value={
              canChange
                ? (
                  user._isOwner
                    ? 'workflow.permissions.inviter.owner'
                    : user.permissions.inviter
                      ? 'workflow.permissions.inviter.enabled'
                      : 'workflow.permissions.inviter.disabled'
                )
                : (
                  user.permissions.inviter
                    ? 'workflow.structure.proofer-role.share-readonly.off'
                    : 'workflow.structure.proofer-role.share-readonly.on'
                )
            }
          />
        }
        up
        center
        disablePointerEvents
      >
        <button
          className={css.WorkflowUser__canInvite__button}
          onClick={() => {
            if (user._isOwner) {
              return;
            }
            user.permissions.inviter = !user.permissions.inviter;
            workflow.steps.forEach((step) => {
              step.users.forEach((stepUser) => {
                if (stepUser.email === user.email) {
                  stepUser.permissions.inviter = user.permissions.inviter;
                }
              });
            });
            events.onUpdateUserPermissions && events.onUpdateUserPermissions(workflow, step, user);
            onChange();
          }}
          disabled={!canChange}
        >
          <div
            className={classname(css.WorkflowUser__inviterIcon, {
              [css.WorkflowUser__inviterIconActive]: user.permissions.inviter || user._isOwner,
            })}
          >
            <InlineSVG
              src="/img/content/proof/icons/inviter.svg"
            />
          </div>
        </button>
      </Tooltip>
    </div>
  );
};

WorkflowUser.MoreButton = ({ state, setState }) => {
  return (
    <div className={css.WorkflowUser__moreButton}>
      <MetadataButton
        arrow={state}
        offset={12}
        onClick={() => setState(!state)}
        ariaLabel="More options"
      />
    </div>
  );
};

WorkflowUser.More = ({ show, workflow, step, user, events, onChange, onHide, type }) => {
  const { locale } = useI18n();

  const options = [
    {
      shouldConfirm: type === 'proof',
      disabled: !user._canBeRemoved,
      onClick: () => {
        step.users.splice(step.users.indexOf(user), 1);
        events.onRemoveUser && events.onRemoveUser(workflow, step, user);
        onChange();
      },
      tooltip: user._canBeRemoved && <Translation value="workflow.user.remove-user-from-step" />,
      icon: <InlineSVG className={css.WorkflowUser__more__options__option__deleteIcon} src="/img/content/proof/icons/delete.svg" />,
    },
  ]

  if (sdk.session.userId !== user.id && user._canBeNudged || user._canBeNudgedAfterCooldown) {
    options.push({
      onClick: () => {
        events.onNudgeUser(workflow.id, user.id)
          .then(() => {
            onHide();
          });
      },
      tooltip: user._canBeNudged 
        ? <Translation value="workflow.user.nudge-user-in-step" /> 
        : <Translation value="workflow.user.nudge-user-in-step.nudged" params={{ time: moment(user.nudgedDate).locale(locale).fromNow() }}  />,
      disabled: !user._canBeNudged, 
      icon: <InlineSVG src="/img/icons/nudge.svg" />,
    },)
  }

  if (user._canBeSkipped) {
    options.push(    {
      onClick: () => {
        events.onSkipUser(step.id, user.id)
          .then(() => {
            onHide();
          });
      },
      tooltip: <Translation value="workflow.user.skip-user-in-step" />,
      icon: <InlineSVG src="/img/icons/skip-user.svg" />,
    })
  }

  return (
    <Reveal
      align="top"
      visible={show}
      render
      immediate
    >
      <div
        className={css.WorkflowUser__more}
        onKeyDownCapture={(event) => {
          if (event.key === 'Escape') {
            onHide();
          }
        }}
      >
        <Metadata compact>
          <div className={css.WorkflowUser__more__columns}>
            <WorkflowUserSwitchRole
              workflow={workflow}
              step={step}
              user={user}
              events={events}
              onChange={() => {
                onChange();
                onHide();
              }}
            />
            <WorkflowUser.More.Options
              options={options}
            />
          </div>
        </Metadata>
      </div>
    </Reveal>
  );
};

WorkflowUser.More.Options = ({ options }) => {
  const [isConfirming, setIsConfirming] = useState(false);

  if (!options.length) {
    return null;
  }

  return (
    <div className={css.WorkflowUser__more__options}>
      {options.map(({ disabled, onClick, tooltip, icon, shouldConfirm }) => (
        <WrapConditionally
          condition={shouldConfirm}
          wrapper={(
            <PopupMenu
              options={
                <Option
                  label={(
                    <Fragment>
                      <Translation value="option.edit.confirm" />
                      <InlineSVG
                        src={'/img/content/proof/icons/delete.svg'}
                        className={css.WorkflowUser__more__options__option__confirmIcon}
                      />
                    </Fragment>)}
                  onClick={onClick}
                />
              }
              disabled={disabled}
              onShow={() => setIsConfirming(true)}
              onHide={() => setIsConfirming(false)}
            />
          )}
        >
          <div>
            <Tooltip
              up
              center
              disablePointerEvents
              title={tooltip}
              disabled={!tooltip || isConfirming}
            >
              <div>
                <button
                  disabled={disabled}
                  className={css.WorkflowUser__more__options__option}
                  onClick={() => {
                    if (disabled || shouldConfirm) {
                      return;
                    }

                    onClick()
                  }}
                >
                  {icon}
                </button>
              </div>
            </Tooltip>
          </div>
        </WrapConditionally>
      ))}
    </div>
  );
};

WorkflowUser.HoverCard = ({ user, workflow, onHide }) => {
  const { locale } = useI18n();

  return (
    <div className={css.WorkflowUserHoverCard}>
      <div className={css.WorkflowUserHoverCard__avatar}>
        <Avatar
          active
          url={getAvatarUrl(user)}
          size={60}
          spinner
        />
      </div>
      <div className={css.WorkflowUserHoverCard__details}>
        {user.name && (
          <div className={css.WorkflowUserHoverCard__details__heading}>
            {user.name}
          </div>
        )}
        <a href={`mailto:${user.email}`}>{user.email}</a>
        {!!user.nudgedDate && (
          <div>
            <Translation value="user-details.last-nudged" />{moment(user.nudgedDate).locale(locale).fromNow()}
          </div>
        )}
        <div className={css.WorkflowUserHoverCard__details__spacer} />
        <div className={css.WorkflowUserHoverCard__details__actions}>
          <Tooltip
            up
            center
            title={(user._proofComments.lastCommentDate && user._proofComments.count) ? `Last commented ${moment(user._proofComments.lastCommentDate).locale(locale).fromNow()}` : "No comments"}
          >
            <UnstyledButton
              ariaLabel="Proof comments count"
              disabled={!user._proofComments.count}
              className={css.WorkflowUserHoverCard__details__actions__comments}
              onClick={() => {
                const { proofInfoService, $location, $rootScope } = window.__pageproof_bridge__;

                onHide();
                proofInfoService.close();

                const userFilter = `user:${user.id}`
                if ($location.path().indexOf('/proof') !== -1) {
                  $rootScope.$broadcast('commentUserFilterSet', userFilter);
                } else {
                  $location.url(`proof/static/${workflow._proof.id}?filter=${userFilter}`);
                }
              }}
            >
              {user._proofComments.count}
              <InlineSVG
                src="/img/icons/comments.svg"
                className={css.WorkflowUserHoverCard__details__actions__comments__icon}
              />
            </UnstyledButton>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

function getAvatarUrl (user) {
  const { avatarData, avatarOption } = generateAvatarOptions(user);
  return `${env.avatar_url}/${avatarOption}/${avatarData}`
};

/** Converts a string to a WorkflowUserRole, defaulting to REVIEWER */
function toWorkflowUserRole (role) {
  switch (role) {
    // usergroups-service returns 'view-only' as 'view_only'
    case 'view_only':
      return Enum.WorkflowUserRole.VIEW_ONLY;
    case 'view-only':
      return Enum.WorkflowUserRole.VIEW_ONLY;
    case 'mandatory':
      return Enum.WorkflowUserRole.MANDATORY;
    case 'gatekeeper':
      return Enum.WorkflowUserRole.GATEKEEPER;
    case 'approver':
      return Enum.WorkflowUserRole.APPROVER;
    default:
      return Enum.WorkflowUserRole.REVIEWER;
  }
}

function isUserAnInviterInAnotherStep (email, workflow) {
  return workflow.steps.some(step => (
    step.users.some(stepUser => stepUser.email === email && stepUser.permissions.inviter)
  ))
};

/** Suggestions onSelect returns a usergroup object */
function isUserGroupObject (groupOrEmail) {
  return typeof groupOrEmail === 'object' && !!groupOrEmail.members;
}

/** Suggestions onBlur returns the entered string */
function isUserGroupInput (input) {
  return input.startsWith('#');
}

export default Workflow;
