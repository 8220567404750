/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, { Fragment } from 'react';
import { InlineSVG } from '../InlineSVG';
import Translation from '../Text/Translation';
import MarkDone from '../Comment/Icon/MarkDone';
import css from './ModalMessage.scss';

const RecipientUploadProof = () => (
  <Fragment>
    <h1><Translation value="proof.dialog.recipient-upload-proof.title" /></h1>
    <p className={css.ModalMessage__message}>
      <Translation
        value="proof.dialog.mark-todo-upload-proof.message"
        params={{
          doneIcon: <MarkDone active readOnly />,
          uploadIcon: (
            <div className={css.ModalMessage__uploadIcon}>
              <InlineSVG
                aria-label="File dropper icon"
                src="/img/icons/file-dropper.svg"
              />
            </div>
          ),
        }}
      />
    </p>
    <p>
      <Translation value="proof.dialog.recipient-add-editor.message" />
    </p>
  </Fragment>
);

export default RecipientUploadProof;
