/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-disable */

import React from 'react';
import { InlineSVG } from '../../InlineSVG';
import classname from 'classname';
import css from './ExpandingSection.scss';

import Reveal from '../../Reveal';

const ExpandingSection = ({
  compact,
  readOnly,
  preview,
  expand,
  canOpen,
  children,
  onPreviewClick,
  walkthroughHook,
}) => {
  return (
    <div
      data-walkthrough-hook={walkthroughHook}
      className={classname(css.ExpandingSection, {
        [css['ExpandingSection--expand']]: expand,
      })}
    >
      {readOnly &&
        <div 
          className={css.ExpandingSection__overlay} 
          onClick={onPreviewClick}
        />
      }
      <div
        className={css.ExpandingSection__preview}
        onClick={onPreviewClick}
      >
        {(children && canOpen) && (
          <InlineSVG
            src="/img/icons/arrow-down.svg"
            className={classname(css.ExpandingSection__collapse, {
              [css['ExpandingSection__collapse--reverse']]: expand,
            })}
          />
        )}
        {preview}
      </div>
      <Reveal
        visible={expand}
        align="top"
        fade
        postRender={false}
      >
        <div style={{ padding: compact ? 1 : '1px 40px' }}>
          {children}
        </div>
        <div className={css.ExpandingSection__spacer} />
      </Reveal>
    </div>
  );
};

ExpandingSection.Heading = ({ icon, title, count, description }) => (
  <div className={css.Heading}>
    {icon && (
      <InlineSVG
        src={icon}
        className={css.Heading__icon}
      />
    )}
    <div className={css.Heading__text}>
      <h2>
        {title}
        {typeof count !== 'undefined' && (
          <div className={css.Heading__count}>
            {count}
          </div>
        )}
      </h2>
      <div className={css.Heading__text__description}>{description}</div>
    </div>
  </div>
);

export default ExpandingSection;
