/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import { InlineSVG } from '../InlineSVG';
import useDarkMode from '../../hooks/useDarkMode';
import MainMenuOptionPicker from '../MainMenuOptionPicker';
import { Translation } from '../Text';
import css from './MainMenuThemeSwitcher.scss';

const themes = {
  dark: { name: 'menu.theme-switcher.dark', icon: '/img/icons/material/dark_mode-24px.svg' },
  light: { name: 'menu.theme-switcher.light', icon: '/img/icons/material/light_mode-24px.svg' },
};

const MainMenuThemeSwitcher = () => {
  const [isDarkMode] = useDarkMode();

  const onThemeChange = (theme) => {
    window.__pageproof_ctrl__.appCtrl.setIsDarkMode(theme === 'dark');
  };

  return (
    <div className={css.MainMenuThemeSwitcher}>
      <MainMenuOptionPicker
        options={themes}
        optionsHeader={<Translation value="menu.theme-switcher.title" />}
        selectedOptionId={isDarkMode ? 'dark' : 'light'}
        onChange={theme => onThemeChange(theme)}
        icon={<InlineSVG src={isDarkMode ? themes.dark.icon : themes.light.icon} />}
      />
    </div>
  );
};

export default MainMenuThemeSwitcher;
